import { useRef, useState, useEffect } from "react";
import { CloseModalSvg, SearchSvg } from "../../icons";
import { useTranslation } from "react-i18next";

const TableSearch2 = ({ formData, searchKey, setFormData, parcels }) => {
  const [searchTerm, setSearchTerm] = useState(formData[searchKey] || "");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parcels === "parcels") {
      setFormData((prevData) => ({
        ...prevData,
        [searchKey]: searchTerm,
        parcelsPage: 1,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [searchKey]: searchTerm,
        page: 1,
      }));
    }
    setShowDropdown(false);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFormData((prevData) => ({
      ...prevData,
      [searchKey]: "",
      page: 1,
    }));
    setShowDropdown(true);
  };

  const handleWrapperClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="" ref={dropdownRef}>
      <div
        className={`cursor-pointer mt-1 block w-full text-sm`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <SearchSvg />
      </div>

      {showDropdown && (
        <div className="absolute z-10 mt-1 left-4 min-w-40 w-[calc(100%-32px)] bg-white border border-gray-200 rounded-lg max-h-60 overflow-auto">
          <form onSubmit={handleSubmit} onClick={handleWrapperClick}>
            <div className="font-light w-full transition-all duration-300 px-4 py-2 flex justify-between items-center">
              <input
                className="bg-transparent outline-none w-[calc(100%-10px)] text-xs"
                type="text"
                placeholder={t("text.search")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm !== "" && showDropdown && (
                <span
                  className="cursor-pointer transition-all duration-300 h-5 w-5 flex justify-center items-center"
                  onClick={handleClearSearch}
                >
                  <CloseModalSvg />
                </span>
              )}
              <button type="submit" className="absolute"></button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TableSearch2;
