import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DeleteSvg2, EditSvg3 } from "../components/icons";
import { useSelector } from "react-redux";
import SelectToFilter from "../components/common/shortList/SelectToFilter";
import ProcessingTableImage from "../components/common/ProcessingTableImage";
import TableSearch2 from "../components/common/shortList/TableSearch2";

const useProcessingDetalsConfig = (
  formData,
  setFormData,
  setSelectedImage,
  handleShowEditParcel,
  handleDeleteConfirmation
) => {
  const { t } = useTranslation();
  const { statuses } = useSelector((state) => state.processingStatuses);

  const getStatusClassName = (statusSymbol, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? `${status.status.toLowerCase()}-processing` : "";
  };

  const actionConfig = [
    {
      type: "button",
      permission: "edit",
      action: (row) => ({
        onClick: () => handleShowEditParcel(row.id),
        icon: <EditSvg3 />,
        tooltip: t("text.edit"),
      }),
      condition: (row) => row.actions?.includes("edit"),
    },
    {
      type: "button",
      permission: "delete",
      action: (row) => ({
        onClick: () => handleDeleteConfirmation(row.id),
        icon: <DeleteSvg2 />,
        tooltip: t("text.delete"),
      }),
      condition: (row) => row.actions?.includes("delete"),
    },
  ];

  const getActions = (row) => {
    return actionConfig
      .filter((action) => action.condition(row))
      .map((action, index) => (
        <Tooltip title={action.action(row).tooltip} key={index}>
          {action.type === "link" ? (
            <Link
              to={action.action(row).to}
              onClick={action.action(row).onClick}
              target={action.action(row).target || undefined}
            >
              {action.action(row).icon}
            </Link>
          ) : (
            <button onClick={action.action(row).onClick}>
              {action.action(row).icon}
            </button>
          )}
        </Tooltip>
      ));
  };

  return {
    columns: [
      {
        key: "",
        header: t("text.image"),
        filterable: false,
        actions: getActions,
        label: true,
        condition: true,
        cell: (row) => (
          <ProcessingTableImage
            url={row.imageUrl}
            setSelectedImage={setSelectedImage}
          />
        ),
      },
      {
        key: "tdsCode",
        header: t("text.TDS_code"),
        filterable: true,
        actions: getActions,
        condition: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
      },
      {
        key: "roomNumber",
        header: t("text.room_number"),
        filterable: true,
        actions: getActions,
        condition: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
      },
      {
        key: "weight",
        header: t("text.weight"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "cbm",
        header: t("text.CBM_NEW"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: t("statusDesc"),
        translateable: true,
        header: t("text.status"),
        filterable: true,
        hasClassName: true,
        filterComponent: () => (
          <SelectToFilter
            options={statuses?.map((cat) => ({
              value: cat.status,
              label: t(cat.statusDictionaryKey),
            }))}
            name="status"
            value={formData.status}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        className: (row) => getStatusClassName(row.status, statuses),
        actions: getActions,
        condition: true,
      },
      {
        key: "errorMessage",
        header: t("text.processing_parcel_group_E"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useProcessingDetalsConfig;
