import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AiOutlineExport, AiOutlineFileExcel } from "react-icons/ai";
import { useAppContext } from "../libs/AppContext";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  DetailsPageSvg,
  ExportFileSvg,
  UploadDocumentSvg,
  BarcodeSvg,
  EditSvg,
} from "../components/icons";
import {
  exportParcels,
  exportUsaParcels,
  getBarcode,
} from "../features/groups/GroupsSlice";
import { BiTransfer } from "react-icons/bi";
import SelectToFilter from "../components/common/shortList/SelectToFilter";
import TableSearch2 from "../components/common/shortList/TableSearch2";
import Checkbox from "../components/common/Checkbox";
import CheckAll from "../components/common/CheckAll";
import { exportExcelForLand } from "../features/groups/groups/GroupsSlice";

const useParcelGroupsConfig = (
  parcelgroupsdata,
  setparcelgroupsdata,
  handleShowEditGroup,
  showModal,
  setShowModal,
  setBarcodeImageValue,
  groupsIds,
  groups,
  selectedRows,
  setSelectedRows,
  setGroupsIds,
  handleTransferExcel
) => {
  // const [transferExcelModal, setTransferExcelModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("₾");
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const { isPermission } = useAppContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { transportationTypes } = useSelector(
    (state) => state.transportationTypesForPrice
  );
  const { parcelGroupTypes } = useSelector((state) => state.parcelGroupsTypes);
  const { statuses } = useSelector((state) => state.parcelStatuses);
  const { departments } = useSelector((state) => state.parcelDepartments);

  const departmentMap = departments.reduce((acc, department) => {
    acc[department.id] = department.address;
    return acc;
  }, {});

  const CheckboxComponent =
    groupsIds?.length === groups?.parcelGroups?.length ? Checkbox : CheckAll;

  const getDepartmentName = (departmentId) => departmentMap[departmentId] || "";

  const getStatusName = (statusSymbol, t, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? t(status.statusDictionaryKey) : "...";
  };

  const getStatusClassName = (statusSymbol, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? status.status.toLowerCase() : "";
  };

  const handleExport = async (id) => {
    const language =
      JSON.parse(localStorage.getItem("language"))?.code?.toUpperCase() ||
      "DEFAULT_LANGUAGE_CODE";

    try {
      const action = await dispatch(
        exportParcels({ parcelGroupId: id, language })
      );
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData?.length);
      for (let i = 0; i < decodedData?.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const handleExportForLand = async (id) => {
    try {
      const action = await dispatch(exportExcelForLand({ parcelGroupId: id }));
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData?.length);
      for (let i = 0; i < decodedData?.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const handleExportUsa = async (id) => {
    const language =
      JSON.parse(localStorage.getItem("language"))?.code?.toUpperCase() ||
      "DEFAULT_LANGUAGE_CODE";

    try {
      const action = await dispatch(
        exportUsaParcels({ parcelGroupId: id, language })
      );
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData?.length);
      for (let i = 0; i < decodedData?.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const getAmountKey = (currency) => {
    return currency === "₾" ? "totalAmountGel" : "totalAmountUsd";
  };

  const handleBarcode = (barcodeValue) => {
    setBarcodeImageValue(barcodeValue);
    if (barcodeValue) {
      dispatch(getBarcode(barcodeValue.barcode));
    }
    setShowModal(!showModal);
  };

  const handleSaveParcelGroupId = (id) => {
    localStorage.setItem("parcelGroupId", id);
  };

  const calculateTotals = (selectedRows, groups) => {
    let totalWeight = 0;
    let totalQuantity = 0;
    let totalPrice = 0;

    selectedRows.forEach((id) => {
      const row = groups?.parcelGroups?.find(
        (group) => group.parcelGroupId === id
      );
      if (row) {
        totalWeight += row.totalWeight || 0;
        totalQuantity += row.totalCount || 0;
        totalPrice += row[getAmountKey(selectedCurrency)] || 0;
      }
    });

    setTotalWeight(totalWeight.toString());
    setTotalQuantity(totalQuantity.toString());
    setTotalPrice(totalPrice.toString());
  };

  const handleCheckAllRow = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
      setGroupsIds([]);
      setTotalWeight(0);
      setTotalQuantity(0);
    } else {
      const allParcelGroups = groups?.parcelGroups
        ? groups?.parcelGroups?.map((row) => row.parcelGroupId)
        : [];

      setSelectedRows(allParcelGroups);
      calculateTotals(allParcelGroups, groups);
    }
  };

  const handleSelectRows = (parcelGroupId) => {
    const isSelected = selectedRows.includes(parcelGroupId);
    const updatedSelectedRows = isSelected
      ? selectedRows.filter((id) => id !== parcelGroupId)
      : [...selectedRows, parcelGroupId];

    setSelectedRows(updatedSelectedRows);
    calculateTotals(updatedSelectedRows, groups);
  };

  const actionConfig = [
    {
      type: "link",
      permission: "parcelGroupShowParcels",
      action: (row) => ({
        to: `/ParcelGroupNew/${row?.parcelGroupId}/Parcels`,
        onClick: (e) => handleSaveParcelGroupId(row.parcelGroupId),
        icon: <DetailsPageSvg />,
        tooltip: t("text.parcelGroupShowParcels"),
      }),
      condition:
        isPermission.parcelGroupShowParcels?.action ===
        "parcelGroupShowParcels",
    },
    {
      type: "link",
      permission: "trackTrace",
      action: (row) => ({
        to: `https://www.track-trace.com/aircargo#${row.barcode}`,
        target: "_blank",
        icon: <ExportFileSvg />,
        tooltip: "TrackTrace",
      }),
      condition: true,
    },
    {
      type: "button",
      permission: "export",
      action: (row) => ({
        onClick: () => handleExport(row.parcelGroupId),
        icon: <UploadDocumentSvg />,
        tooltip: t("text.export"),
      }),
      condition: true,
    },
    {
      type: "button",
      permission: "exportUsa",
      action: (row) => ({
        onClick: () => handleExportUsa(row.parcelGroupId),
        icon: <AiOutlineExport />,
        tooltip: `${t("text.export")} USA`,
      }),
      condition: true,
    },
    {
      type: "button",
      permission: "barcode",
      action: (row) => ({
        onClick: () => handleBarcode(row),
        icon: <BarcodeSvg />,
        tooltip: t("text.barcode"),
      }),
      condition: true,
    },
    {
      type: "button",
      permission: "parcelGroupChange",
      action: (row) => ({
        onClick: () => handleShowEditGroup(row.parcelGroupId),
        icon: <EditSvg />,
        tooltip: t("text.edit"),
      }),
      condition: isPermission.parcelGroupChange?.action === "parcelGroupChange",
    },
    {
      type: "button",
      permission: "parcelChangeParcelGroup",
      action: (row) => ({
        onClick: () => handleTransferExcel(row.parcelGroupId),
        icon: <BiTransfer />,
        tooltip: `${t("text.parcel_transfer")} Excel`,
      }),
      condition: (row) =>
        row.status !== "R" &&
        isPermission.parcelChangeParcelGroup?.action ===
          "parcelChangeParcelGroup",
    },
    {
      type: "button",
      permission: "",
      action: (row) => ({
        onClick: () => handleExportForLand(row.parcelGroupId),
        icon: <AiOutlineFileExcel />,
        tooltip: `Export Excel for Land`,
      }),
      condition: (row) => row.transportationTypeId === 2,
    },
  ];

  const getActions = (row) => {
    return actionConfig
      .filter((action) =>
        typeof action.condition === "function"
          ? action.condition(row)
          : action.condition
      )
      .map((action, index) => (
        <Tooltip title={action.action(row).tooltip} key={index}>
          {action.type === "link" ? (
            <Link
              to={action.action(row).to}
              onClick={action.action(row).onClick}
              target={action.action(row).target || undefined}
            >
              {action.action(row).icon}
            </Link>
          ) : (
            <button onClick={action.action(row).onClick}>
              {action.action(row).icon}
            </button>
          )}
        </Tooltip>
      ));
  };

  // const getActions = (row) => {
  //   return actionConfig
  //     .filter((action) => action.condition || action.condition(row))
  //     .map((action, index) => (
  //       <Tooltip title={action.action(row).tooltip} key={index}>
  //         {action.type === "link" ? (
  //           <Link
  //             to={action.action(row).to}
  //             onClick={action.action(row).onClick}
  //             target={action.action(row).target || undefined}
  //           >
  //             {action.action(row).icon}
  //           </Link>
  //         ) : (
  //           <button onClick={action.action(row).onClick}>
  //             {action.action(row).icon}
  //           </button>
  //         )}
  //       </Tooltip>
  //     ));
  // };

  return {
    columns: [
      {
        key: "",
        header: (
          <CheckboxComponent
            checked={groupsIds?.length > 0}
            onChange={handleCheckAllRow}
          />
        ),
        checkbox: true,
        filterable: false,
        cell: (row) => (
          <Checkbox
            checked={
              groupsIds?.length > 0 && selectedRows.includes(row.parcelGroupId)
            }
            onChange={() => handleSelectRows(row.parcelGroupId)}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "flightNumber",
        header: t("text.flights"),
        filterable: true,
        filterComponent: (parcelgroupsdata, setparcelgroupsdata, searchKey) => (
          <TableSearch2
            formData={parcelgroupsdata}
            setFormData={setparcelgroupsdata}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "barcode",
        header: t("text.barcode"),
        important: true,
        filterable: true,
        filterComponent: (parcelgroupsdata, setparcelgroupsdata, searchKey) => (
          <TableSearch2
            formData={parcelgroupsdata}
            setFormData={setparcelgroupsdata}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "departmentId",
        header: t("text.department"),
        cell: (row) => getDepartmentName(row.departmentId),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "totalWeight",
        header: t("text.tw"),
        total: totalWeight,
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "totalVolumeWeight",
        header: t("text.tvw"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "transportationTypeDesc",
        translateable: true,
        header: t("text.transportation"),
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={transportationTypes?.map((cat) => ({
              value: cat.id,
              label: t(cat.dictionaryKey),
            }))}
            name="transportationTypeId"
            value={parcelgroupsdata.transportationTypeId}
            setFormData={setparcelgroupsdata}
            onChange={(e) =>
              setparcelgroupsdata((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "parcelGroupTypeDesc",
        header: t("text.parcel_group"),
        translateable: true,
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={parcelGroupTypes?.map((cat) => ({
              value: cat.id,
              label: t(cat.dictionaryKey),
            }))}
            name="parcelGroupTypeId"
            value={parcelgroupsdata.parcelGroupTypeId}
            setFormData={setparcelgroupsdata}
            onChange={(e) =>
              setparcelgroupsdata((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "totalCount",
        header: t("text.tq"),
        total: totalQuantity,
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: getAmountKey(selectedCurrency),
        header: t("text.amount"),
        total: totalPrice,
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={[
              { value: "₾", label: "₾" },
              { value: "$", label: "$" },
            ]}
            setFormData={setparcelgroupsdata}
            name="currency"
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
          />
        ),
        actions: getActions,
        condition:
          selectedCurrency === "$"
            ? isPermission.parcelGroupShowTotalAmountUsd?.action ===
              "parcelGroupShowTotalAmountUsd"
            : isPermission.parcelGroupShowTotalAmountGel?.action ===
              "parcelGroupShowTotalAmountGel",
      },
      {
        key: "payedAmountGel",
        header: t("text.ap"),
        filterable: false,
        actions: getActions,
        condition:
          isPermission.parcelGroupShowPayedAmountUsd?.action ===
          "parcelGroupShowPayedAmountUsd",
      },
      {
        key: "payedAmountPercentage",
        header: "%",
        filterable: false,
        actions: getActions,
        condition:
          isPermission.parcelGroupShowPayedAmountUsd?.action ===
          "parcelGroupShowPayedAmountUsd",
      },
      {
        key: "ccyRate",
        header: t("text.ccy_rate"),
        filterable: false,
        actions: getActions,
        condition:
          isPermission.parcelGroupCcyRate?.action === "parcelGroupCcyRate",
      },
      {
        key: "status",
        header: t("text.status"),
        filterable: true,
        hasClassName: true,
        filterComponent: () => (
          <SelectToFilter
            options={statuses?.map((cat) => ({
              value: cat.status,
              label: t(cat.statusDictionaryKey),
            }))}
            name="status"
            value={parcelgroupsdata.status}
            setFormData={setparcelgroupsdata}
            onChange={(e) =>
              setparcelgroupsdata((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        cell: (row) => getStatusName(row.status, t, statuses),
        className: (row) => getStatusClassName(row.status, statuses),
        actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useParcelGroupsConfig;
