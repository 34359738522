import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDeliveryTime } from "../../features/dashboard/deliveryTime/DeliveryTimeSlice";
import { ArrowRightSvg, ProgressSvg, RegressSvg } from "../icons";
import FilterByPeriods from "./components/FilterByPeriods";
import Spinner from "../common/Spinner";

const DeliveryTime = () => {
  const [time, setTime] = useState(0);
  const [progressValue, setProgressValue] = useState("00:00:00");
  const [formData, setFormData] = useState({
    reportType: "avgTerminalWithdrawTime",
    groupPeriod: "",
    departmentId: "",
  });
  const { reportType, groupPeriod, departmentId } = formData;
  const { isLoading, deliveryTime } = useSelector(
    (state) => state.deliveryTime
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const old = deliveryTime?.old;
    const current = deliveryTime?.current;

    if (old && current) {
      const [oldHour, oldMinute, oldSecond] = old.split(":").map(Number);
      const [currentHour, currentMinute, currentSecond] = current
        .split(":")
        .map(Number);

      const oldTotalSeconds = oldHour * 3600 + oldMinute * 60 + oldSecond;
      const currentTotalSeconds =
        currentHour * 3600 + currentMinute * 60 + currentSecond;
      const timeDifference = currentTotalSeconds - oldTotalSeconds;

      setTime(timeDifference);

      const hours = Math.floor(Math.abs(timeDifference) / 3600);
      const remainingSeconds = Math.abs(timeDifference) % 3600;
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      setProgressValue(
        `${
          timeDifference < 0 ? "-" : ""
        }${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      );
    }
  }, [deliveryTime]);

  useEffect(() => {
    if (reportType && groupPeriod) {
      dispatch(getDeliveryTime({ reportType, groupPeriod, departmentId }));
    }
  }, [reportType, groupPeriod, departmentId, dispatch]);

  return (
    <div className="relative min-h-44 flex flex-col justify-between bg-white border border-black/5 p-4 rounded-3xl shadow-xl">
      <div className="flex items-center justify-between gap-5 w-full mb-4">
        <h2 className="text-base text-purple-5 font-medium">
          {t("text.average_delivery_time")}
        </h2>
        <FilterByPeriods setFormData={setFormData} />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex justify-between items-end gap-2.5">
          <div className="flex flex-col items-start gap-4">
            <div>
              <div className="text-base font-bold text-purple-5 xl:text-2xl">
                {deliveryTime?.old === null ? (
                  `${t("text.no_time")}`
                ) : (
                  <>{deliveryTime?.old || "00:00:00"}</>
                )}
              </div>
              <span className="text-sm text-purple-5/70 font-medium">
                {t("text.last")} {t(`text.${formData.groupPeriod}`)}
              </span>
            </div>
            <div>
              <div className="text-base font-bold text-purple-5 xl:text-2xl">
                {deliveryTime?.current === null ? (
                  `${t("text.no_time")}`
                ) : (
                  <>{deliveryTime?.current || "00:00:00"}</>
                )}
              </div>
              <span className="text-sm text-purple-5/70 font-medium">
                {t("text.current")} {t(`text.${formData.groupPeriod}`)}
              </span>
            </div>
          </div>
          <div className="max-w-28 w-full flex flex-col items-end gap-2 xl:max-w-28">
            <div
              className={`w-full flex items-center justify-center gap-3 p-2 rounded-lg border ${
                time > 0
                  ? "border-teal-500 text-teal-500 bg-teal-100"
                  : time < 0
                  ? "border-purple-500 text-purple-500 bg-purple-100"
                  : "border-gray-400 text-gray-400 bg-gray-100"
              }`}
            >
              {time !== 0 && <>{time > 0 ? <ProgressSvg /> : <RegressSvg />}</>}
              <span className="text-xs font-semibold xl:text-sm">
                {deliveryTime?.current === null || deliveryTime?.old === null
                  ? `${t("text.no_time")}`
                  : progressValue || "00:00:00"}
              </span>
            </div>
            <Link
              to="/deliveryTimeDetails"
              className={`flex items-center gap-2 text-sm font-medium ${
                time > 0
                  ? "text-green-1"
                  : time < 0
                  ? "text-purple-1"
                  : "text-purple-5/70"
              } hover:opacity-50 transition-opacity duration-300 whitespace-nowrap lg:text-xs xl:text-sm`}
            >
              {t("text.view_details")}
              <ArrowRightSvg
                fill={`${
                  time > 0 ? "#38C7C8" : time < 0 ? "#A183EA" : "#8690C9"
                }`}
              />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeliveryTime;
