import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineUpload, AiOutlineDelete } from "react-icons/ai";
import ModalsHeadline from "../components/ModalsHeadline";
import ButtonDefault from "../../buttons/ButtonDefault";
import { useDispatch } from "react-redux";
import { transferGroupsExcel } from "../../../features/groups/groups/GroupsSlice";
import { useAppContext } from "../../../libs/AppContext";

const TransferExcel = ({
  handleShowModal,
  parcelGroupId,
  setShowResponseMessage,
  setTransferExcelModal,
  setSeverity,
  setMessage,
}) => {
  const [formData, setFormData] = useState({
    "file[]": null,
    parcelGroupId: "",
  });
  const { t } = useTranslation();
  const { setRefetch, refetch } = useAppContext();
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData((prev) => ({ ...prev, parcelGroupId: parcelGroupId }));
  }, [parcelGroupId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFormData((prev) => ({ ...prev, "file[]": file }));
    } else {
      setMessage("გთხოვთ, ატვირთოთ მხოლოდ Excel ფაილი.");
      setSeverity("error");
      setShowResponseMessage(true);
    }
  };

  const handleRemoveFile = () => {
    setFormData((prev) => ({ ...prev, "file[]": null }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData["file[]"]) {
      setMessage("გთხოვთ, ატვირთოთ Excel ფაილი.");
      setSeverity("error");
      setShowResponseMessage(true);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("file[]", formData["file[]"]);
    formDataToSend.append("parcelGroupId", formData.parcelGroupId);

    try {
      const response = await dispatch(transferGroupsExcel(formDataToSend));

      if (response.payload.type === "success") {
        setRefetch(!refetch);
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        setFormData({
          "file[]": null,
          parcelGroupId: "",
        });
        setTransferExcelModal(false);
      }

      if (response.error.message === "Rejected") {
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
        setFormData({
          "file[]": null,
          parcelGroupId: "",
        });
        setTransferExcelModal(false);
      }
    } catch (error) {}
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between rounded-lg h-full "
    >
      <ModalsHeadline
        text={`${t("text.parcel_transfer")} Excel`}
        onClick={handleShowModal}
      />

      <div className="flex flex-col items-center space-y-4 p-6">
        {formData["file[]"] ? (
          <div className="flex items-center space-x-4 border p-3 rounded-lg">
            <span className="text-sm">{formData["file[]"].name}</span>
            <button
              type="button"
              className="text-red-500 hover:text-red-700"
              onClick={handleRemoveFile}
            >
              <AiOutlineDelete size={20} />
            </button>
          </div>
        ) : (
          <label className="border border-dashed p-4 rounded-lg w-full text-center cursor-pointer">
            <input
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept=".xlsx"
            />
            <div className="flex items-center justify-center space-x-2 text-blue-500">
              <AiOutlineUpload size={20} />
              <span>{t("text.upload")} Excel</span>
            </div>
          </label>
        )}
      </div>
      <div className="p-6">
        <ButtonDefault
          type="submit"
          className="mt-4 bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700"
        >
          {t("text.upload")}
        </ButtonDefault>
      </div>
    </form>
  );
};

export default TransferExcel;
