import { useEffect, useRef, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackSvg } from "../../components/icons";
import { updateParcelNew } from "../../features/groups/GroupsSlice";
import ParcelForm from "../../components/forms/parcelGroups/ParcelForm";
import ParcelGroupsLayout from "./ParcelGroupsLayout";
import Modal from "../../components/common/Modal";
import EditParcelPictures from "../../components/modals/groupsNew/EditParcelPictures";

const EditStandartParcel = () => {
  const [formData, setFormData] = useState({
    parcelGroupId: "",
    tds_code: "",
    roomNumber: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        volumeWeight: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
  });
  const [shouldPrint, setShouldPrint] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [subCategories, setSubCategories] = useState([]);

  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [message, setMessage] = useState("");

  const parcelCodeInputRef = useRef();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parcelGroupId, parcelId } = useParams();

  const { parcels } = useSelector((state) => state.parcels);
  const { categoriesWithMaster } = useSelector((state) => state.groups);

  useEffect(() => {
    const item = parcels?.parcels?.find(
      (parcel) => parseInt(parcel.id) === parseInt(parcelId)
    );
    if (item) {
      setFormData({
        parcelId: item.id || "",
        tds_code: item.tdsCode || "",
        roomNumber: item.roomNumber || "",
        parcelDetails: item.parcelDetails.length
          ? item.parcelDetails.map((detail) => ({
              id: detail.id || "",
              quantity: detail.quantity || "",
              weight: detail.weight || "",
              length: detail.length || "",
              width: detail.width || "",
              height: detail.height || "",
            }))
          : [
              {
                id: "",
                quantity: "",
                weight: "",
                length: "",
                width: "",
                height: "",
              },
            ],
        warehouseComment: item.warehouseComment || "",
        warehouseCategoryId: item.warehouseCategoryId || "",
        "file[]": item.parcelFiles || [],
      });

      setSelectedSubCategory(item.warehouseCategoryId);

      const parentCategory = categoriesWithMaster.find((category) =>
        category.children.some(
          (sub) => sub.id === parseInt(item.warehouseCategoryId)
        )
      );
      if (parentCategory) {
        setSubCategories(parentCategory?.children || []);
        setSelectedMainCategory(parentCategory.id);
      }
    }
  }, [parcelId, parcels?.parcels, categoriesWithMaster]);

  useEffect(() => {
    setFormData(
      (prev) => ({
        ...prev,
        parcelGroupId: parcelGroupId,
      }),
      []
    );
  }, [parcelGroupId]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.tds_code) {
      newErrors.tds_code = "TDS code is required";
    }
    if (!formData.roomNumber) {
      newErrors.roomNumber = "Room number is required";
    }

    if (formData.parcelDetails.length === 1) {
      const detail = formData.parcelDetails[0];

      if (!detail.weight) {
        newErrors[`parcelDetails_0`] = {
          ...newErrors[`parcelDetails_0`],
          weight: "Weight is required",
        };
      }
    } else {
      formData.parcelDetails.forEach((detail, index) => {
        const parcelErrors = {};

        const hasFilledField =
          detail.weight ||
          detail.quantity ||
          detail.length ||
          detail.width ||
          detail.height;

        if (hasFilledField) {
          if (!detail.weight) {
            parcelErrors.weight = "Weight is required";
          }

          if (Object.keys(parcelErrors).length > 0) {
            newErrors[`parcelDetails_${index}`] = parcelErrors;
          }
        }
      });
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formattedParcelDetails = formData.parcelDetails
      .map((detail) => {
        const formattedDetail = {
          quantity: parseFloat(detail.quantity) || 0,
          weight: parseFloat(detail.weight) || 0,
          length: parseFloat(detail.length) || 0,
          width: parseFloat(detail.width) || 0,
          height: parseFloat(detail.height) || 0,
        };

        if (detail.id) {
          formattedDetail.id = parseFloat(detail.id);
        }
        return formattedDetail;
      })
      .filter(
        (detail) =>
          detail.quantity !== 0 ||
          detail.weight !== 0 ||
          detail.length !== 0 ||
          detail.width !== 0 ||
          detail.height !== 0
      );

    const formDataToSend = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "parcelDetails") {
        formDataToSend.append(key, JSON.stringify(formattedParcelDetails));
      } else if (key === "file[]") {
        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else if (key === "tds_code") {
        formDataToSend.append("tdsCode", value);
      } else if (key === "parcelId") {
        formDataToSend.append("parcelId", parcelId);
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(updateParcelNew(formDataToSend));
      if (response.payload && response.payload.type === "success") {
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
      }

      if (response.error && response.error.message === "Rejected") {
        setSeverity("error");
        setShowResponseMessage(true);
        setMessage(response.payload.message);
      }
    } catch (error) {}
  };

  return (
    <ParcelGroupsLayout>
      <div>
        {showResponseMessage && (
          <Snackbar
            open={showResponseMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        )}
        <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
          <div className="flex items-center gap-3">
            <Link
              to={`/ParcelGroupNew/${parcelGroupId}/Parcels`}
              className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11 cursor-pointer"
            >
              <BackSvg />
            </Link>
            <div className="text-xl font-semibold text-gray-800">
              სტანდარტული ამანათის {t("text.edit")}
            </div>
          </div>
        </div>
        <Modal showmodal={showUploadModal} setShowModal={setShowUploadModal}>
          <EditParcelPictures
            formData={formData}
            setFormData={setFormData}
            setShowUploadModal={setShowUploadModal}
          />
        </Modal>
        <ParcelForm
          handleSubmit={handleSubmit}
          setFormData={setFormData}
          formData={formData}
          setShouldPrint={setShouldPrint}
          shouldPrint={shouldPrint}
          errors={errors}
          setErrors={setErrors}
          setSelectedMainCategory={setSelectedMainCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          selectedMainCategory={selectedMainCategory}
          selectedSubCategory={selectedSubCategory}
          setShowUploadModal={setShowUploadModal}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          parcelCodeInputRef={parcelCodeInputRef}
        />
      </div>
    </ParcelGroupsLayout>
  );
};

export default EditStandartParcel;
