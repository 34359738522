import { Link } from "react-router-dom";
import { BackSvg } from "../../icons";
import { useAppContext } from "../../../libs/AppContext";
import { RxHamburgerMenu } from "react-icons/rx";

const Headline = ({ children, linkUrl, text }) => {
  const { handleMobileMenu } = useAppContext();

  return (
    <div className="flex justify-between items-center border-b border-gray-300 bg-gray-100 flex-col lg:gap-3 lg:flex-row lg:p-4">
      <div className="w-full flex justify-between items-center gap-6 border-b border-gray-300 lg:border-transparent p-3 lg:p-0">
        <div className="flex items-center justify-start gap-3 ">
          <Link
            to={linkUrl}
            className="border border-black/10 rounded flex items-center justify-center h-8 w-8 min-w-8 lg:p-3.5 lg:h-11 lg:w-11"
          >
            <BackSvg />
          </Link>
          <div className="font-semibold text-gray-800 text-base lg:text-lg md:text-xl">
            {text}
          </div>
        </div>
        <button
          className="flex z-20 cursor-pointer text-2xl lg:hidden"
          type="button"
          onClick={handleMobileMenu}
        >
          <RxHamburgerMenu />
        </button>
      </div>
      <div className="w-full flex justify-end items-center lg:w-auto lg:p-0">
        {children}
      </div>
    </div>
  );
};

export default Headline;
