import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { routes } from "../routes";

const useLinkToNavigate = () => {
  const { links } = useSelector((state) => state.menu);
  const [linkToNavigate, setLinkToNavigate] = useState(null);

  useEffect(() => {
    const matchedObjects = routes[1].routes.filter((route) => {
      const routePathWithoutSlash = route.path.replace("/", "");

      return links?.permissions?.some((permission) => {
        if (!permission.children || permission.children.length === 0) {
          return permission.pageUrl === routePathWithoutSlash;
        } else {
          return permission.children.some(
            (child) => child.pageUrl === routePathWithoutSlash
          );
        }
      });
    });

    if (matchedObjects) {
      setLinkToNavigate(matchedObjects[0]?.path);
    }
  }, [links?.permissions]);

  return linkToNavigate;
};

export default useLinkToNavigate;
