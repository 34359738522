import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import departmentLinksService from "./DepartmentLinksService";

const initialState = {
  departmentLinks: [],
  statusCode: null,
  rolesError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getDepartmentLinks = createAsyncThunk(
  "projects/delegation/get_user_department_links",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await departmentLinksService.getDepartmentLinks(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const deleteDepartmentLinks = createAsyncThunk(
  "projects/delegation/delete_user_department_link",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await departmentLinksService.deleteDepartmentLink(token, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const departmentLinksSlice = createSlice({
  name: "departmentLinks",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentLinks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartmentLinks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departmentLinks = action.payload;
        state.statusCode = 200;
      })
      .addCase(getDepartmentLinks.rejected, (state, action) => {
        state.isLoading = false;
        state.rolesError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(deleteDepartmentLinks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDepartmentLinks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departmentLinks = state.departmentLinks?.filter(
          (departmentLink) => departmentLink.id !== action.payload
        );
        state.statusCode = 200;
      })
      .addCase(deleteDepartmentLinks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = departmentLinksSlice.actions;
export default departmentLinksSlice.reducer;
