import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProgressBarElement from "../dashboard/components/ProgressBarElement";

const UsersAgentsBonus = () => {
  const { t } = useTranslation();
  const { analytics } = useSelector((state) => state.referralAnalytics);

  return (
    <div className="flex flex-col items-start gap-2 rounded-xl border border-gray-100 shadow-xl p-4 md:gap-4">
      <ProgressBarElement
        label={t("text.bonus_from_user")}
        number={analytics ? analytics.usersBonusSum : 0}
        value={100}
        backgroundcolor="#fc56b743"
        linecolor="rgba(252, 86, 182, 1)"
      />
      <ProgressBarElement
        label={t("text.bonus_from_agent")}
        number={analytics ? analytics.agentsBonusSum : 0}
        value={100}
        backgroundcolor="#38c8c848"
        linecolor="rgba(56, 199, 200, 1)"
      />
    </div>
  );
};

export default UsersAgentsBonus;
