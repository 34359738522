import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientsService from "./ClientsService";

const initialState = {
  clients: {},
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getClients = createAsyncThunk(
  "projects/get_users",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await clientsService.getClients(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const deleteClient = createAsyncThunk(
  "projects/delete_user",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await clientsService.deleteClient(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const registerClient = createAsyncThunk(
  "projects/register_user",
  async (clientData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await clientsService.registerClient(clientData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const updateClient = createAsyncThunk(
  "projects/change_user",
  async (updatedClientData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await clientsService.updateClient(updatedClientData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const removeAttachments = createAsyncThunk(
  "projects/remove_user_attachment",
  async (attachmentIds, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await clientsService.removeAttachments(attachmentIds, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clients = action.payload;
        state.statusCode = 200;
      })
      .addCase(getClients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(deleteClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clients = state.clients?.users?.filter(
          (client) => client.id !== action.payload
        );
        state.statusCode = 200;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(registerClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clients.users.push(action.payload);
        state.statusCode = 200;
      })
      .addCase(registerClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(updateClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.clients.users.findIndex(
          (client) => client.id === action.payload.id
        );
        state.clients.users[index] = action.payload;
        state.statusCode = 200;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(removeAttachments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeAttachments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const fileIdsToDelete = action.payload?.file_ids || [];

        if (state.clients?.users) {
          state.clients.users = state.clients.users.map((client) => {
            return {
              ...client,
              attachments: client.attachments.filter(
                (attachment) => !fileIdsToDelete.includes(attachment.id)
              ),
            };
          });
        }

        state.statusCode = 200;
      })
      .addCase(removeAttachments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = clientsSlice.actions;
export default clientsSlice.reducer;
