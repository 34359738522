import { useEffect, useState } from "react";
import { getCountries } from "../../features/groups/countries/CountriesSlice";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../libs/AppContext";
import { Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStatuses } from "../../features/processingParcelGroups/StatusesSlice";
import {
  completeProcessingGroup,
  deleteProcessingGroup,
} from "../../features/processingParcelGroups/ParcelGroupsSlice";
import ProcessingParcelGroupsPropertiesBar from "../../components/table/processingParcelGroups/ProcessingParcelGroupsPropertiesBar";
import ProcessingParcelGroupsTable from "../../components/table/processingParcelGroups/ProcessingParcelGroupsTable";
import Modal from "../../components/common/Modal";
import EditBarcode from "../../components/modals/processingGroups/EditBarcode";
import Processing from "../../components/modals/processingGroups/Processing";
import UploadImages from "../../components/modals/processingGroups/UploadImages";
import UploadFile from "../../components/modals/processingGroups/UploadFile";
import AddButton from "../../components/common/AddButton";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import ProcessingLayout from "./ProcessingLayout";
import Message from "../../components/common/Message";

const ProcessingParcelGroups = () => {
  const [country, setCountry] = useState("");
  const [showEditBarcode, setShowEditBarcode] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [showUploadImages, setShowUploadImages] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [groupIds, setGroupIds] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const { showMainModal, setShowMainModal, refetch, setRefetch } =
    useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getStatuses());
  }, [dispatch]);

  const handleEditBarcode = (id) => {
    setShowEditBarcode(!showEditBarcode);
    setGroupId(id);
  };

  const handleUploadImages = (id) => {
    setShowUploadImages(!showUploadImages);
    setGroupId(id);
  };

  const handleShowProcessing = (id) => {
    setShowProcessing(!showProcessing);
    setGroupIds((prevIds) =>
      prevIds.includes(id) ? prevIds : [...prevIds, id]
    );
  };

  const handleComplite = async () => {
    const groupData = {
      processingParcelGroupIds: JSON.stringify(groupIds),
    };

    const response = await dispatch(completeProcessingGroup(groupData));
    if (response?.payload?.type === "success") {
      setShowProcessing(false);
      setShowResponseMessage(true);
      setGroupIds([]);
      setMessage(response.payload.message);
      setSeverity("success");
      setRefetch(!refetch);
    }

    if (response?.error?.message === "Rejected") {
      setShowProcessing(false);
      setShowResponseMessage(true);
      setMessage(response.payload.message);
      setSeverity("error");
    }
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setGroupIds((prevIds) =>
      prevIds.includes(id) ? prevIds : [...prevIds, id]
    );
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleDeleteGroup = async () => {
    const groupData = {
      processingParcelGroupIds: JSON.stringify(groupIds),
    };

    const response = await dispatch(deleteProcessingGroup(groupData));
    if (response?.payload?.type === "success") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setGroupIds([]);
      setMessage(response.payload.message);
      setSeverity("success");
      setRefetch(!refetch);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setMessage(response.payload.message);
      setSeverity("error");
    }
  };

  return (
    <ProcessingLayout setCountry={setCountry}>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <ProcessingParcelGroupsPropertiesBar />
      <Modal showmodal={showEditBarcode} setShowModal={setShowEditBarcode}>
        <EditBarcode
          setShowModal={setShowEditBarcode}
          groupId={groupId}
          setShowResponseMessage={setShowResponseMessage}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      </Modal>
      <Modal showmodal={showUploadImages} setShowModal={setShowUploadImages}>
        <UploadImages
          groupId={groupId}
          setGroupId={setGroupId}
          setShowModal={setShowUploadImages}
        />
      </Modal>
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <UploadFile
          warehouseCountryId={country}
          setShowModal={setShowMainModal}
          setShowResponseMessage={setShowResponseMessage}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      </Modal>
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteGroup}
        />
      )}
      {showProcessing && (
        <Processing
          handleCancel={handleShowProcessing}
          handleProcessing={handleComplite}
          groupId={groupId}
          setGroupId={setGroupId}
        />
      )}
      {country && <AddButton />}

      {country ? (
        <ProcessingParcelGroupsTable
          handleEditBarcode={handleEditBarcode}
          handleUploadImages={handleUploadImages}
          handleProcessing={handleShowProcessing}
          handleDeleteConfirmation={handleDeleteConfirmation}
        />
      ) : (
        <Message text={t("text.select_country")} />
      )}
    </ProcessingLayout>
  );
};

export default ProcessingParcelGroups;
