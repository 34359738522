import { LinearProgress, Typography } from "@mui/material";

const ProgressBarElement = ({
  label,
  value,
  backgroundcolor,
  linecolor,
  number,
}) => {
  return (
    <div className="w-full">
      <Typography variant="h6" className="text-purple-3 !text-sm font-medium">
        {label}
      </Typography>
      <div className="flex items-center justify-between mb-2.5">
        <span
          className="text-2xl leading-8 md:text-lg md:leading-6"
          style={{ color: linecolor }}
        >
          {number}
        </span>
        <Typography
          variant="body2"
          className="text-sm font-medium"
          style={{ color: linecolor === "white" ? "white" : linecolor }}
        >
          {value > 0 ? value.toFixed(2) : 0}%
        </Typography>
      </div>

      <div
        className="w-full h-2.5 rounded-full"
        style={{ backgroundColor: backgroundcolor }}
      >
        <LinearProgress
          variant="determinate"
          value={value > 0 ? value : 0}
          sx={{
            height: "10px",
            borderRadius: "5px",
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: linecolor,
              borderRadius: "5px",
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBarElement;
