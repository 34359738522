import React, { useRef, useState } from "react";
import takeAPic from "../../assets/take-a-picture.svg";

const UploadFileOrTakeAPicture = ({ onChange }) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const openCamera = async () => {
    setIsCameraOpen(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
    videoRef.current.play();
  };

  const takePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        const file = new File([blob], "photo.jpg", { type: "image/jpeg" });
        onChange({ target: { files: [file] } });
      }, "image/jpeg");
      closeCamera();
    }
  };

  const closeCamera = () => {
    if (videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  return (
    <div className="border-dashed border-2 border-gray-300 rounded-lg">
      {isCameraOpen ? (
        <div className="flex flex-col items-center p-4">
          <video ref={videoRef} className="w-full h-auto" />
          <div className="flex justify-center items-center gap-2 mt-2">
            <button
              onClick={takePhoto}
              className="bg-blue-2 text-white text-sm px-4 py-2 rounded"
            >
              Capture Photo
            </button>
            <button
              onClick={closeCamera}
              className="bg-red-500 text-white text-sm px-4 py-2 rounded"
            >
              Close Camera
            </button>
          </div>
          <canvas ref={canvasRef} className="hidden" width={640} height={480} />
        </div>
      ) : (
        <div className="px-12 py-14 flex justify-center items-center">
          <img src={takeAPic} alt="camera" />
          <div className="flex flex-col items-center">
            <p className="text-center text-sm text-gray-1">
              Drag & drop your photo here{" "}
              <span className="font-medium text-blue-2 underline cursor-pointer relative">
                Choose File
                <input
                  type="file"
                  id="file"
                  onChange={onChange}
                  multiple
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </span>{" "}
              or{" "}
              <span
                onClick={openCamera}
                className="font-medium text-blue-2 underline cursor-pointer"
              >
                Take Photo
              </span>
            </p>
            <span className=" text-blue-1 text-base font-medium">
              500 mb max size
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFileOrTakeAPicture;
