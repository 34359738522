import { useTranslation } from "react-i18next";
import { SearchSvg } from "../icons";

const SearchDark = ({ searchTerm, handleSearch }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex justify-start items-center gap-2 py-2 px-4 bg-gray-9 border border-gray-1/15 rounded-xl">
      <span className="min-w-4">
        <SearchSvg />
      </span>
      <input
        className="bg-transparent outline-none text-sm"
        type="text"
        placeholder={t("text.search")}
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

export default SearchDark;
