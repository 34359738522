import { useEffect, useState } from "react";
import { useAppContext } from "../../libs/AppContext";
import { useDispatch } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { getCountries } from "../../features/prices/getCountries/GetCountriesSlice";
import { getTransportationTypes } from "../../features/prices/getTransportationTypes/GetTransportationTypesSlice";
import { getUserTypes } from "../../features/prices/getUserTypes/GetUserTypesSlice";
import {
  deleteSystemPrice,
  getSystemPrices,
} from "../../features/prices/systemPrice/SystemPriceSlice";
import { getParcelGroupTypes } from "../../features/groups/GroupTypesSlice";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/common/AddButton";
// import PricesPropertiesBar from "../../components/table/standartPrices/PricesPropertiesBar";
import StandartPricesTable from "../../components/table/standartPrices/StandartPricesTable";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import AddSystemPrice from "../../components/modals/standartPrices/AddSystemPrice";
import EditSystemPrice from "../../components/modals/standartPrices/EditSystemPrice";
import Modal from "../../components/common/Modal";
import Headline from "../../components/common/shortList/Headline";
import useLinkToNavigate from "../../libs/useLinkToNavigate";

const StandartPrices = () => {
  const [formData, setFormData] = useState({
    fromCountryId: "",
    toCountryId: "",
    userTypeId: "",
    price: "",
    transportationTypeId: "",
    parcelGroupTypeId: "",
    page: 1,
    perPage: 10,
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showEditPrice, setShowEditPrice] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [systemPriceId, setSystemPriceId] = useState("");
  const [editPriceId, setEditPriceId] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [refetch, setRefetch] = useState(false);
  const { setShowMainModal, showMainModal } = useAppContext();
  const {
    fromCountryId,
    toCountryId,
    userTypeId,
    price,
    transportationTypeId,
    parcelGroupTypeId,
    page,
    perPage,
  } = formData;

  const linkToNavigate = useLinkToNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getTransportationTypes());
    dispatch(getParcelGroupTypes());
    dispatch(getUserTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getSystemPrices({
        fromCountryId,
        toCountryId,
        userTypeId,
        price,
        transportationTypeId,
        parcelGroupTypeId,
        page,
        perPage,
      })
    );
  }, [
    dispatch,
    fromCountryId,
    toCountryId,
    userTypeId,
    price,
    transportationTypeId,
    parcelGroupTypeId,
    page,
    perPage,
    refetch,
  ]);

  const handleEditPrice = (id) => {
    setEditPriceId(id);
    setShowEditPrice(!showEditPrice);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setSystemPriceId(id);
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleDeleteSystemPrice = async () => {
    const systemPriceData = {
      id: systemPriceId,
    };

    const response = await dispatch(deleteSystemPrice(systemPriceData));
    if (response?.payload?.type === "success") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setSystemPriceId("");
      setMessage(response.payload.message);
      setSeverity("success");
      setRefetch(!refetch);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setSystemPriceId("");
      setMessage(response.payload.message);
      setSeverity("error");
    }
  };

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddSystemPrice
          setMessage={setMessage}
          setSeverity={setSeverity}
          setShowResponseMessage={setShowResponseMessage}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      </Modal>
      <Modal showmodal={showEditPrice} setShowModal={setShowEditPrice}>
        <EditSystemPrice
          setMessage={setMessage}
          setSeverity={setSeverity}
          setShowEditPrice={setShowEditPrice}
          setShowResponseMessage={setShowResponseMessage}
          editPriceId={editPriceId}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      </Modal>
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteSystemPrice}
        />
      )}
      {/* <PricesPropertiesBar /> */}
      <Headline linkUrl={linkToNavigate} text={t("text.standart_prices")} />
      <StandartPricesTable
        setFormData={setFormData}
        formData={formData}
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleEditPrice={handleEditPrice}
      />
      <AddButton />
    </div>
  );
};

export default StandartPrices;
