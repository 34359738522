import { Link, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import CheckAll from "../components/common/CheckAll";
import Checkbox from "../components/common/Checkbox";
import { EditSvgBlue, PhotoSvg, PrintBarcodeSvg } from "../components/icons";
import { useTranslation } from "react-i18next";
import TableSearch2 from "../components/common/shortList/TableSearch2";

const useParcelsConfig = (
  parcels,
  parcelIds,
  setParcelIds,
  setSelectedRows,
  selectedRows,
  typeId,
  parcelsdata,
  setparcelsdata,
  setPrintData,
  printRef,
  handleShowPicturesList,
  subCategories
) => {
  const { parcelGroupId } = useParams();
  const { t } = useTranslation();

  const CheckboxComponent =
    parcelIds?.length === parcels?.parcels?.length ? Checkbox : CheckAll;

  const handleSelectRows = (id) => {
    const isSelected = selectedRows.includes(id);
    const updatedSelectedRows = isSelected
      ? selectedRows.filter((selectedId) => selectedId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
  };

  const getWarehouseCategoryName = (categoryId) => {
    if (categoryId) {
      const subCategory = subCategories.find((sub) => sub.id === categoryId);
      return subCategory ? subCategory.description : t("text.not_found");
    }
  };

  const handleCheckAllRow = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
      setParcelIds([]);
    } else {
      const allParcels = parcels?.parcels
        ? parcels?.parcels.map((row) => row.id)
        : [];

      setSelectedRows(allParcels);
    }
  };

  const handlePrint = async (id) => {
    if (!printRef.current) {
      console.error("printRef is not initialized");
      return;
    }

    const parcel = parcels?.parcels?.find((item) => item.id === id);

    if (parcel) {
      await setPrintData(parcel);
    }

    setTimeout(() => {
      if (!printRef.current) {
        console.error("printRef is still not initialized after setting data.");
        return;
      }

      const printContent = printRef.current.innerHTML;
      const newWindow = window.open("", "_blank");

      if (!newWindow) {
        console.error("Failed to open a new window");
        return;
      }

      newWindow.document.write(`
        <html>
          <head>
            <title>Print Parcel</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                height: 100vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }
              .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                max-width: 800px;
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
              }
              img {
                display: block;
                max-width: 200px;
                height: auto;
                margin-bottom: 10px;
              }
              h2 {
                margin: 5px 0;
              }
              h3 {
                font-size: 14px;
              }
              .barcode {
                margin-top: 20px;
              }
              p {
                max-width: 270px;
                width: 100%;
                font-size: 12px;
                text-align: center;
              }
              .grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                max-width: 270px;
                width: 100%;
              }
              .first {
                border-right: solid 1px #000;
                padding: 5px;
                text-align: start;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
              }
              .second {
                padding: 5px;
                text-align: end;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
              }
            </style>
          </head>
          <body>
            <div class="content">
              ${printContent}
            </div>
          </body>
        </html>
      `);

      newWindow.document.close();
      newWindow.focus();
      newWindow.print();

      newWindow.onafterprint = () => {
        newWindow.close();
        window.focus();
      };
    }, 100);
  };

  const actionConfig = [
    {
      type: "link",
      permission: "",
      action: (row) => ({
        to: `${
          typeId === 2
            ? `/ParcelGroupNew/${parcelGroupId}/Parcels/individual/${row.id}/edit`
            : `/ParcelGroupNew/${parcelGroupId}/Parcels/standart/${row.id}/edit`
        }`,
        icon: <EditSvgBlue />,
        tooltip: t("text.edit"),
      }),
      condition: true,
    },
    {
      type: "button",
      permission: "photo",
      action: (row) => ({
        onClick: () => handleShowPicturesList(row.id),
        icon: (
          <span
            className={`w-6 h-6 min-w-6 flex justify-center items-center relative ${
              row?.parcelFiles &&
              row?.parcelFiles?.length > 0 &&
              "after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-2 after:absolute after:-top-0.5 after:-right-0.5"
            }`}
          >
            <PhotoSvg />
          </span>
        ),
        tooltip: t("text.photo"),
      }),
      condition: true,
    },
  ];

  const getActions = (row) => {
    return actionConfig
      .filter((action) => action.condition)
      .map((action, index) => (
        <Tooltip title={action.action(row).tooltip} key={index}>
          {action.type === "link" ? (
            <Link
              to={action.action(row).to}
              onClick={action.action(row).onClick}
              target={action.action(row).target || undefined}
            >
              {action.action(row).icon}
            </Link>
          ) : (
            <button onClick={action.action(row).onClick}>
              {action.action(row).icon}
            </button>
          )}
        </Tooltip>
      ));
  };

  return {
    columns: [
      {
        key: "",
        header: (
          <CheckboxComponent
            checked={parcelIds?.length > 0}
            onChange={handleCheckAllRow}
          />
        ),
        checkbox: true,
        filterable: false,
        cell: (row) => (
          <Checkbox
            checked={parcelIds?.length > 0 && selectedRows.includes(row.id)}
            onChange={() => handleSelectRows(row.id)}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "",
        header: t("text.barcode"),
        checkbox: false,
        label: true,
        filterable: false,
        cell: (row) => (
          <button type="button" onClick={() => handlePrint(row.id)}>
            <PrintBarcodeSvg />
          </button>
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "fullName",
        header: t("text.firsname_lastname"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "tdsCode",
        header: t("text.TDS_code"),
        filterable: true,
        important: true,
        filterComponent: (parcelsdata, setparcelsdata, searchKey) => (
          <TableSearch2
            formData={parcelsdata}
            setFormData={setparcelsdata}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "roomNumber",
        header: t("text.room_number"),
        filterable: true,
        filterComponent: (parcelsdata, setparcelsdata, searchKey) => (
          <TableSearch2
            formData={parcelsdata}
            setFormData={setparcelsdata}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "warehouseCategoryId",
        header: t("text.category"),
        cell: (row) => getWarehouseCategoryName(row.warehouseCategoryId),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "weight",
        header: t("text.total_weight"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "totalVolumeWeight",
        header: t("text.total_volume_weight"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "cbm",
        header: t("text.CBM"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "expectedAmountGel",
        header: t("text.expected_amount_in_gel"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useParcelsConfig;
