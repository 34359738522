import { PrintBarcodeSvg } from "../components/icons";
import { useTranslation } from "react-i18next";
import TableSearch2 from "../components/common/shortList/TableSearch2";

const useParcelsInfoConfig = (
  parcels,
  setPrintData,
  printRef,
  subCategories
) => {
  const { t } = useTranslation();

  const getWarehouseCategoryName = (categoryId) => {
    if (categoryId) {
      const subCategory = subCategories.find((sub) => sub.id === categoryId);
      return subCategory ? subCategory.description : t("text.not_found");
    }
  };

  const handlePrint = async (id) => {
    if (!printRef.current) {
      console.error("printRef is not initialized");
      return;
    }

    const parcel = parcels?.parcels?.find((item) => item.id === id);

    if (parcel) {
      await setPrintData(parcel);
    }

    setTimeout(() => {
      if (!printRef.current) {
        console.error("printRef is still not initialized after setting data.");
        return;
      }

      const printContent = printRef.current.innerHTML;
      const newWindow = window.open("", "_blank");

      if (!newWindow) {
        console.error("Failed to open a new window");
        return;
      }

      newWindow.document.write(`
        <html>
          <head>
            <title>Print Parcel</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                height: 100vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }
              .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                max-width: 800px;
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
              }
              img {
                display: block;
                max-width: 200px;
                height: auto;
                margin-bottom: 10px;
              }
              h2 {
                margin: 5px 0;
              }
              h3 {
                font-size: 14px;
              }
              .barcode {
                margin-top: 20px;
              }
              p {
                max-width: 270px;
                width: 100%;
                font-size: 12px;
                text-align: center;
              }
              .grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                max-width: 270px;
                width: 100%;
              }
              .first {
                border-right: solid 1px #000;
                padding: 5px;
                text-align: start;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
              }
              .second {
                padding: 5px;
                text-align: end;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
              }
            </style>
          </head>
          <body>
            <div class="content">
              ${printContent}
            </div>
          </body>
        </html>
      `);

      newWindow.document.close();
      newWindow.focus();
      newWindow.print();

      newWindow.onafterprint = () => {
        newWindow.close();
        window.focus();
      };
    }, 100);
  };

  return {
    animation: true,
    columns: [
      {
        key: "",
        header: t("text.barcode"),
        checkbox: false,
        label: true,
        filterable: false,
        cell: (row) => (
          <button type="button" onClick={() => handlePrint(row.id)}>
            <PrintBarcodeSvg />
          </button>
        ),
        condition: true,
      },
      {
        key: "fullName",
        header: t("text.firsname_lastname"),
        filterable: false,
        condition: true,
      },
      {
        key: "tdsCode",
        header: t("text.TDS_code"),
        filterable: true,
        important: true,
        filterComponent: (parcelsdata, setparcelsdata, searchKey) => (
          <TableSearch2
            formData={parcelsdata}
            setFormData={setparcelsdata}
            searchKey={searchKey}
          />
        ),
        condition: true,
      },
      {
        key: "roomNumber",
        header: t("text.room_number"),
        filterable: true,
        filterComponent: (parcelsdata, setparcelsdata, searchKey) => (
          <TableSearch2
            formData={parcelsdata}
            setFormData={setparcelsdata}
            searchKey={searchKey}
          />
        ),
        condition: true,
      },
      {
        key: "warehouseCategoryId",
        header: t("text.category"),
        cell: (row) => getWarehouseCategoryName(row.warehouseCategoryId),
        filterable: false,
        condition: true,
      },
      {
        key: "weight",
        header: t("text.total_weight"),
        filterable: false,
        condition: true,
      },
      {
        key: "totalVolumeWeight",
        header: t("text.total_volume_weight"),
        filterable: false,
        condition: true,
      },
      {
        key: "cbm",
        header: t("text.CBM"),
        filterable: false,
        condition: true,
      },
      {
        key: "expectedAmountGel",
        header: t("text.expected_amount_in_gel"),
        filterable: false,
        condition: true,
      },
    ],
  };
};

export default useParcelsInfoConfig;
