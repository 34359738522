import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { useAppContext } from "../../../libs/AppContext";
import { DeleteSvg, EditSvg, UserSvg } from "../../icons";
import { useTranslation } from "react-i18next";
import CustomDrop from "../../common/CustomDrop";
import Checkbox from "../../common/Checkbox";
import Spinner from "../../common/Spinner";
import CheckAll from "../../common/CheckAll";

const DepartmentsTable = ({
  handleShowAddEmployee,
  handleShowEditEmployee,
  handleDeleteConfirmation,
}) => {
  const { isLoading, departments } = useSelector((state) => state.departments);
  const { isPermission } = useAppContext();
  const { t } = useTranslation();

  const columns = [
    { header: t("text.department"), key: "name" },
    { header: t("text.employees"), key: "userId" },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {columns.map((column) => (
                  <th
                    key={column.key}
                    className="max-w-5 text-left border-b font-medium"
                  >
                    <div className="p-4 w-full truncate border-r border-black/5">
                      {column.header}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {departments &&
                departments.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-black/5 border-b"
                  >
                    <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                      <Checkbox />
                    </td>
                    {columns.map((column) => (
                      <td
                        key={column.key}
                        className="max-w-5 text-xs text-blue-4 font-medium"
                      >
                        <div className="py-2 px-3 w-full truncate border-r border-black/5">
                          {column.key === "userId" ? (
                            <>
                              {row.users?.map((user, idx) => (
                                <span key={idx}>{user.userId}</span>
                              ))}
                            </>
                          ) : (
                            row[column.key]
                          )}
                        </div>
                      </td>
                    ))}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <div className="flex gap-2">
                        {isPermission.registerUser?.action ===
                          "registerUser" && (
                          <Tooltip title={t("text.add_employee")}>
                            <div onClick={() => handleShowAddEmployee(row.id)}>
                              <UserSvg />
                            </div>
                          </Tooltip>
                        )}
                        <CustomDrop>
                          {isPermission.deleteDepartment?.action ===
                            "deleteDepartment" && (
                            <div
                              onClick={() => handleDeleteConfirmation(row.id)}
                            >
                              <DeleteSvg />
                              {t("text.delete")}
                            </div>
                          )}
                          {isPermission.changeDepartment?.action ===
                            "changeDepartment" && (
                            <div onClick={() => handleShowEditEmployee(row.id)}>
                              <EditSvg />
                              {t("text.edit")}
                            </div>
                          )}
                        </CustomDrop>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DepartmentsTable;
