import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import { DeleteSvg2, EditSvg3 } from "../../icons";
// import { Tooltip } from "@mui/material";
// import { IoCalendarOutline } from "react-icons/io5";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
// import Message from "../../common/Message";
// import Filter from "../standartPrices/Filter";
// import TableSearch from "../../common/TableSearch";
// import DatePickerFilter from "./DatePickerFilter";
import LargeTable from "../../tables/LargeTable";
import MobileFilter from "../../tabelMobileFilters/MobileFilter";
import Modal from "../../common/Modal";
import useUserPricesConfig from "../../../tableConfigs/userPrices";

const UserPricesTable = ({
  setFormData,
  formData,
  handleDeleteConfirmation,
  handleEditPrice,
}) => {
  const [recordsNumber, setRecordsNumber] = useState("");
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  // const [visibleDatePicker, setVisibleDatePicker] = useState(null);
  const { isLoading, individualPrices } = useSelector(
    (state) => state.individualPrices
  );
  // const { countries } = useSelector((state) => state.countriesForPrice);
  // const { transportationTypes } = useSelector(
  //   (state) => state.transportationTypesForPrice
  // );
  // const { parcelGroupTypes } = useSelector((state) => state.parcelGroupsTypes);

  // const { t } = useTranslation();
  // const datePickersRef = useRef([]);

  const tableConfig = useUserPricesConfig(
    formData,
    setFormData,
    handleEditPrice,
    handleDeleteConfirmation
  );

  // const columnkeymappings = {
  //   User: "fullName",
  //   "1 kg Price in $": "price",
  //   "Room Number": "roomNumber",
  //   "From Country": "fromCountryDesc",
  //   "To Country": "toCountryDesc",
  //   "From Date": "fromDate",
  //   "To Date": "toDate",
  //   "Transportation Type": "transportationTypeDesc",
  //   "Parcel Group Type": "parcelGroupTypeDesc",
  // };

  useEffect(() => {
    setRecordsNumber(individualPrices.recordsNumber);
  }, [individualPrices.recordsNumber]);

  const handlePageIncrement = () => {
    setFormData((prevData) => ({ ...prevData, page: formData.page + 1 }));
  };

  const handlePageDecrement = () => {
    if (formData.page > 1) {
      setFormData((prevData) => ({ ...prevData, page: formData.page - 1 }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setFormData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setFormData((prevData) => ({ ...prevData, perPage: e }));
  };

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       !datePickersRef.current
  //         .filter((ref) => ref)
  //         .some((ref) => ref.contains(event.target))
  //     ) {
  //       setVisibleDatePicker(null);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  // const handleDatePickerToggle = (pickerName) => {
  //   setVisibleDatePicker((prev) => (prev === pickerName ? null : pickerName));
  // };

  // const handleFilterChange = (e) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  // const handleDateSelect = (name, value) => {
  //   const formatDate = (date) => {
  //     const newDate = new Date(date);
  //     const year = newDate.getFullYear();
  //     const month = String(newDate.getMonth() + 1).padStart(2, "0");
  //     const day = String(newDate.getDate()).padStart(2, "0");
  //     return `${year}-${month}-${day}`;
  //   };

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value ? formatDate(value) : "",
  //   }));
  //   setVisibleDatePicker(null);
  // };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
          <Modal
            showmodal={showMobileFilters}
            setShowModal={setShowMobileFilters}
          >
            <MobileFilter
              columns={tableConfig.columns}
              setShowModal={setShowMobileFilters}
              formData={formData}
              setFormData={setFormData}
            />
          </Modal>
          <LargeTable
            config={tableConfig}
            data={individualPrices?.userPrices}
            formData={formData}
            setFormData={setFormData}
            handleShowFilters={() => setShowMobileFilters((prev) => !prev)}
          />
          {/* <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center"></div>
                </th>
                {Object.keys(columnkeymappings).map((key) => (
                  <th
                    key={key}
                    className="max-w-5 text-left border-b font-medium min-w-26"
                  >
                    <div className="relative p-4 w-full border-r border-black/5 flex justify-between items-center gap-1">
                      <span className="block truncate pr-2">{key}</span>
                      {key === "1 kg Price in $" && (
                        <TableSearch
                          formData={formData}
                          setFormData={setFormData}
                          searchKey={columnkeymappings[key]}
                        />
                      )}
                      {key === "Room Number" && (
                        <TableSearch
                          formData={formData}
                          setFormData={setFormData}
                          searchKey={columnkeymappings[key]}
                        />
                      )}
                      {key === "From Country" && (
                        <Filter
                          options={countries
                            ?.filter((cat) => cat.sendAllowed === "Y")
                            .map((cat) => ({
                              value: cat.countryId,
                              label: t(cat.countryDictionaryKey),
                            }))}
                          name="fromCountryId"
                          value={formData.fromCountryId}
                          onChange={handleFilterChange}
                        />
                      )}
                      {key === "To Country" && (
                        <Filter
                          options={countries
                            ?.filter((cat) => cat.sendAllowed === "Y")
                            .map((cat) => ({
                              value: cat.countryId,
                              label: t(cat.countryDictionaryKey),
                            }))}
                          name="toCountryId"
                          value={formData.toCountryId}
                          onChange={handleFilterChange}
                        />
                      )}
                      {key === "Transportation Type" && (
                        <Filter
                          options={transportationTypes?.map((cat) => ({
                            value: cat.id,
                            label: t(cat.dictionaryKey),
                          }))}
                          name="transportationTypeId"
                          value={formData.transportationTypeId}
                          onChange={handleFilterChange}
                          setFormData={setFormData}
                        />
                      )}
                      {key === "Parcel Group Type" && (
                        <Filter
                          options={parcelGroupTypes?.map((cat) => ({
                            value: cat.id,
                            label: t(cat.dictionaryKey),
                          }))}
                          name="parcelGroupTypeId"
                          value={formData.parcelGroupTypeId}
                          onChange={handleFilterChange}
                          setFormData={setFormData}
                        />
                      )}
                      {key === "From Date" && (
                        <div className="relative">
                          <button
                            className="text-gray-1 flex justify-center items-center"
                            onClick={() => handleDatePickerToggle("fromDate")}
                          >
                            <IoCalendarOutline />
                          </button>
                          {visibleDatePicker === "fromDate" && (
                            <div
                              ref={(el) => (datePickersRef.current[0] = el)}
                              className="absolute w-36 z-10 left-1/2 transform -translate-x-1/2 -bottom-20"
                            >
                              <DatePickerFilter
                                name="fromDate"
                                value={formData.fromDate || ""}
                                onChange={(e) =>
                                  handleDateSelect("fromDate", e.target.value)
                                }
                                text={key}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {key === "To Date" && (
                        <div className="relative">
                          <button
                            className="text-gray-1 flex justify-center items-center"
                            onClick={() => handleDatePickerToggle("toDate")}
                          >
                            <IoCalendarOutline />
                          </button>
                          {visibleDatePicker === "toDate" && (
                            <div
                              ref={(el) => (datePickersRef.current[1] = el)}
                              className="absolute w-36 z-10 left-1/2 transform -translate-x-1/2 -bottom-20"
                            >
                              <DatePickerFilter
                                name="toDate"
                                value={formData.toDate || ""}
                                onChange={(e) =>
                                  handleDateSelect("toDate", e.target.value)
                                }
                                text={key}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {individualPrices?.userPrices &&
              individualPrices?.userPrices?.length > 0 ? (
                individualPrices?.userPrices?.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-black/5 border-b"
                  >
                    <td className="py-2 px-3 border-r border-black/5 flex justify-center"></td>
                    {Object.keys(columnkeymappings).map((key) => (
                      <td
                        key={key}
                        className="max-w-5 text-xs text-blue-4 font-medium"
                      >
                        <Tooltip title={row[columnkeymappings[key]]}>
                          <div className="py-2 px-3 w-full truncate border-r border-black/5">
                            {row[columnkeymappings[key]]}
                          </div>
                        </Tooltip>
                      </td>
                    ))}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <div className="flex gap-2">
                        {row.actions?.includes("edit") && (
                          <button
                            type="button"
                            className="w-4 flex justify-center items-center"
                            onClick={() => handleEditPrice(row.id)}
                          >
                            <EditSvg3 />
                          </button>
                        )}
                        {row.actions?.includes("delete") && (
                          <button
                            onClick={() => handleDeleteConfirmation(row.id)}
                            className="w-4 flex justify-center items-center"
                          >
                            <DeleteSvg2 />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={Object.keys(columnkeymappings).length + 2}
                    className="border-none p-2"
                  >
                    <Message text={t("text.not_found")} />
                  </td>
                </tr>
              )}
            </tbody>
          </table> */}
          {recordsNumber > 1 && (
            <Pagination
              currentPage={formData.page}
              onNextPage={handlePageIncrement}
              onPrevPage={handlePageDecrement}
              handlePerPage={handlePerPage}
              perPage={formData.perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UserPricesTable;
