import axios from "axios";

const getCustomerScore = async (token, reportType, groupPeriod) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const getCustomerScoreDetails = async (
  token,
  reportType,
  groupPeriod,
  departmentId,
  page,
  perPage,
  userName,
  tdsCode,
  startDate,
  endDate,
  rate
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
    page: page,
    perPage: perPage,
    userName: userName,
    tdsCode: tdsCode,
    startDate: startDate,
    endDate: endDate,
    rate: rate,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report_details`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const exportExcel = async (
  token,
  reportType,
  groupPeriod,
  departmentId,
  page,
  perPage,
  userName,
  tdsCode,
  startDate,
  endDate,
  rate
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
    page: page,
    perPage: perPage,
    userName: userName,
    tdsCode: tdsCode,
    startDate: startDate,
    withdrawEndDate: endDate,
    rate: rate,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report_details_excel`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const customerScoreService = {
  getCustomerScore,
  getCustomerScoreDetails,
  exportExcel,
};

export default customerScoreService;
