import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DeleteSvg2, EditSvg3 } from "../components/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Checkbox from "../components/common/Checkbox";
import CheckAll from "../components/common/CheckAll";
import TableSearch2 from "../components/common/shortList/TableSearch2";
import SelectToFilter from "../components/common/shortList/SelectToFilter";
import TableDatePicker from "../components/common/shortList/TableDatePicker";

const useUserPricesConfig = (
  formData,
  setFormData,
  handleEditPrice,
  handleDeleteConfirmation
) => {
  const { t } = useTranslation();
  const { countries } = useSelector((state) => state.countriesForPrice);
  const { parcelGroupTypes } = useSelector((state) => state.parcelGroupsTypes);
  const { transportationTypes } = useSelector(
    (state) => state.transportationTypesForPrice
  );

  const CheckboxComponent = 2 === 3 ? Checkbox : CheckAll;

  const actionConfig = [
    {
      type: "button",
      permission: "edit",
      action: (row) => ({
        onClick: () => handleEditPrice(row.id),
        icon: <EditSvg3 />,
        tooltip: t("text.edit"),
      }),
      condition: (row) => row.actions?.includes("edit"),
    },
    {
      type: "button",
      permission: "delete",
      action: (row) => ({
        onClick: () => handleDeleteConfirmation(row.id),
        icon: <DeleteSvg2 />,
        tooltip: t("text.delete"),
      }),
      condition: (row) => row.actions?.includes("delete"),
    },
  ];

  const getActions = (row) => {
    return actionConfig
      .filter((action) => action.condition(row))
      .map((action, index) => (
        <Tooltip title={action.action(row).tooltip} key={index}>
          {action.type === "link" ? (
            <Link
              to={action.action(row).to}
              onClick={action.action(row).onClick}
              target={action.action(row).target || undefined}
            >
              {action.action(row).icon}
            </Link>
          ) : (
            <button onClick={action.action(row).onClick}>
              {action.action(row).icon}
            </button>
          )}
        </Tooltip>
      ));
  };

  return {
    columns: [
      {
        key: "",
        header: <CheckboxComponent checked={true} onChange={() => true} />,
        checkbox: true,
        filterable: false,
        cell: (row) => <Checkbox checked={true} onChange={() => true} />,
        actions: getActions,
        condition: true,
      },
      {
        key: "fullName",
        header: t("text.user"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "price",
        header: `1 ${t("text.KG")} ${t("text.price_gel")} $`,
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "roomNumber",
        header: t("text.room_number"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "fromCountryDesc",
        translateable: true,
        header: t("text.from_country"),
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={countries
              ?.filter((cat) => cat.sendAllowed === "Y")
              .map((cat) => ({
                value: cat.countryId,
                label: t(cat.countryDictionaryKey),
              }))}
            name="fromCountryId"
            value={formData.fromCountryId}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "toCountryDesc",
        translateable: true,
        header: t("text.to_country"),
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={countries
              ?.filter((cat) => cat.sendAllowed === "Y")
              .map((cat) => ({
                value: cat.countryId,
                label: t(cat.countryDictionaryKey),
              }))}
            name="toCountryId"
            value={formData.toCountryId}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "fromDate",
        header: t("text.from_date"),
        filterable: true,
        filterComponent: () => (
          <TableDatePicker
            formData={formData}
            setFormData={setFormData}
            name="fromDate"
            value={formData.fromDate}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "toDate",
        header: t("text.to_date"),
        filterable: true,
        filterComponent: () => (
          <TableDatePicker
            formData={formData}
            setFormData={setFormData}
            name="toDate"
            value={formData.toDate}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "transportationTypeDesc",
        translateable: true,
        header: t("text.transportation"),
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={transportationTypes?.map((cat) => ({
              value: cat.id,
              label: t(cat.dictionaryKey),
            }))}
            name="transportationTypeId"
            value={formData.transportationTypeId}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "parcelGroupTypeDesc",
        translateable: true,
        header: t("text.parcel_group"),
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={parcelGroupTypes?.map((cat) => ({
              value: cat.id,
              label: t(cat.dictionaryKey),
            }))}
            name="parcelGroupTypeId"
            value={formData.parcelGroupTypeId}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useUserPricesConfig;
