import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../features/notifications/notifications/NotificationsSlice";

const NotificationsLayout = ({ children, refetch }) => {
  const [formData, setFormData] = useState({
    title: "",
    recipientType: "",
    status: "",
    notificationTypes: "",
    page: 1,
    perPage: 10,
  });
  const { title, recipientType, status, notificationTypes, page, perPage } =
    formData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getNotifications({
        title: title,
        recipientType: recipientType,
        status: status,
        notificationType: notificationTypes,
        page: page,
        perPage: perPage,
      })
    );
  }, [
    dispatch,
    title,
    recipientType,
    status,
    notificationTypes,
    page,
    perPage,
    refetch,
  ]);

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { formData, setFormData })
          : child
      )}
    </div>
  );
};

export default NotificationsLayout;
