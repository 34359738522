const Checkbox = ({ checked, onChange }) => {
  return (
    <label className="relative cursor-pointer select-none flex items-center gap-2">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="absolute opacity-0 cursor-pointer h-0 w-0"
      />
      <span
        className={`block h-6 w-6 rounded-sm  ${
          checked ? "bg-purple-1" : "bg-purple-4/40"
        } flex items-center justify-center`}
      >
        {checked && (
          <svg
            className="w-5 h-5 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </span>
    </label>
  );
};

export default Checkbox;
