import { useTranslation } from "react-i18next";
import { AttachSvg } from "../icons";

const UploadFile = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <div className="relative w-full overflow-hidden">
      <label
        htmlFor="file"
        className="flex items-center gap-3 p-4 w-full h-full border-dashed border-2 border-gray-300 rounded-lg cursor-pointer transition-all duration-300 ease-in-out hover:border-gray-500"
      >
        <span className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
          <AttachSvg />
        </span>
        <div className="flex flex-col items-start">
          <h3 className="text-blue-4 text-base font-semibold">
            {t("text.upload_file")}
          </h3>
          <p className="text-gray-600 text-sm font-normal">
            File must be max 23MB
          </p>
        </div>
      </label>
      <input
        type="file"
        id="file"
        onChange={onChange}
        multiple
        className="absolute inset-0 opacity-0 cursor-pointer"
      />
    </div>
  );
};

export default UploadFile;
