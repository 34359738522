import axios from "axios";

const getUsers = async (
  token,
  firstName,
  lastName,
  pinOrInn,
  mobile,
  status,
  referrerUserName,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    firstName: firstName,
    lastName: lastName,
    pinOrInn: pinOrInn,
    mobile: mobile,
    status: status,
    referrerUserName: referrerUserName,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/referral/get_referral_users`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const registerUser = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/referral/register_referral_user`,
    userData,
    config
  );

  return response.data;
};

const changeUser = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/referral/change_referral_user`,
    userData,
    config
  );

  return response.data;
};

const deleteUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/referral/delete_referral_user`,
    userId,
    config
  );

  return response.data;
};

const users = { getUsers, registerUser, changeUser, deleteUser };

export default users;
