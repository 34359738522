import axios from "axios";

const getNotifications = async (
  token,
  title,
  recipientType,
  status,
  notificationType,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    title: title,
    recipientType: recipientType,
    status: status,
    notificationType: notificationType,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/notification/get_notifications`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const getNotificationDetails = async (token, notificationId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    notificationId: notificationId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/notification/get_notification_details`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const addNotification = async (notificationData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/notification/add_notification`,
    notificationData,
    config
  );

  return response.data;
};

const editNotification = async (notificationData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/notification/edit_notification`,
    notificationData,
    config
  );

  return response.data;
};

const deleteNotification = async (notificationId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/notification/delete_notification`,
    notificationId,
    config
  );

  return response.data;
};

const notificationsService = {
  getNotifications,
  addNotification,
  editNotification,
  deleteNotification,
  getNotificationDetails,
};

export default notificationsService;
