import { useState } from "react";
import Checkbox from "./Checkbox";

const Notifications = () => {
  const [isChechked, setIsChechked] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (e) => {
    setIsChechked(!isChechked);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <h2 className="text-xl font-medium text-gray-7 mb-6">Notifications</h2>
      <div className="flex flex-col md:flex-row md:gap-24">
        <div className="flex flex-col gap-4">
          <span className="text-xl font-medium text-blue-5 md:text-lg">
            Email შეტყობინებები
          </span>
          <div className="flex items-center gap-2 text-sm font-normal text-gray-8">
            <Checkbox checked={isChechked} onChange={handleChange} /> ამანათი
            საწყობშია
          </div>
          <div className="flex items-center gap-2 text-sm font-normal text-gray-8">
            <Checkbox /> ამანათი საწყობშია
          </div>
          <div className="flex items-center gap-2 text-sm font-normal text-gray-8">
            <Checkbox /> ამანათი საწყობშია
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-8 md:mt-0">
          <span className="text-xl font-medium text-blue-5 md:text-lg">
            SMS შეტყობინებები
          </span>
          <Checkbox />
          <Checkbox />
          <Checkbox />
        </div>
      </div>
      <button
        type="submit"
        className="bg-purple-4 rounded-full text-white text-lg font-normal py-2.5 px-4 mt-16 cursor-pointer transition-colors duration-500 hover:bg-purple-1"
      >
        Save changes
      </button>
    </form>
  );
};

export default Notifications;
