import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getDepartments,
  registerDepartment,
} from "../../../features/departments/DepartamentsSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";
import Input from "../../common/Input";

const DepartmentsModal = ({
  setResponseMessage,
  setShowResponseMessage,
  setShowModal,
  closeModal,
}) => {
  const [formData, setFormData] = useState({ departmentName: "" });
  const [errors, setErrors] = useState({ departmentName: "" });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { departmentName } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddDepartment = async (e) => {
    e.preventDefault();

    if (!departmentName) {
      setErrors({
        departmentName: `${t("text.department_name_required")}`,
      });
      return;
    }

    const departmentData = { departmentName };

    try {
      const response = await dispatch(registerDepartment(departmentData));
      if (response.payload.type === "success") {
        dispatch(getDepartments());
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setFormData({ departmentName: "" });
      }

      if (response.error.message === "Rejected") {
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          departmentName: error.response.data.departmentName || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleAddDepartment}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text={t("text.add_department")} onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.name")}
              name="departmentName"
              value={departmentName}
              onchange={handleInputChange}
              error={errors.departmentName}
              errormark={errors.departmentName ? "error-border" : ""}
            />
            {errors.departmentName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.departmentName}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.add")}</ButtonDefault>
      </div>
    </form>
  );
};

export default DepartmentsModal;
