import React, { useState, useEffect } from "react";
import { getProcessingParcels } from "../../features/processingParcelGroups/ParcelGroupsSlice";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../libs/AppContext";

const ProcessingLayout = ({ children, setCountry }) => {
  const initialProcessingData = JSON.parse(
    localStorage.getItem("processingGroupsData")
  ) || {
    warehouseCountryId: "",
    fileName: "",
    userName: "",
    parcelGroupBarcode: "",
    tdsCode: "",
    page: 1,
    perPage: 10,
  };

  const [formData, setFormData] = useState(initialProcessingData);
  const dispatch = useDispatch();
  const {
    warehouseCountryId,
    fileName,
    userName,
    parcelGroupBarcode,
    tdsCode,
    page,
    perPage,
  } = formData;
  const { refetch } = useAppContext();

  useEffect(() => {
    if (warehouseCountryId) {
      if (setCountry) {
        setCountry(warehouseCountryId);
      }
      dispatch(
        getProcessingParcels({
          warehouseCountryId,
          fileName,
          userName,
          parcelGroupBarcode,
          tdsCode,
          page,
          perPage,
        })
      );
    }
  }, [
    dispatch,
    setCountry,
    warehouseCountryId,
    fileName,
    userName,
    parcelGroupBarcode,
    tdsCode,
    page,
    perPage,
    refetch,
  ]);

  useEffect(() => {
    if (formData.warehouseCountryId) {
      localStorage.setItem("processingGroupsData", JSON.stringify(formData));
    }
  }, [formData]);

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) && typeof child.type !== "string"
          ? React.cloneElement(child, {
              formData,
              setFormData,
            })
          : child
      )}
    </div>
  );
};

export default ProcessingLayout;
