import axios from "axios";

const getFileCategories = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/delegation/get_file_category`,
    config
  );

  return response.data;
};

const registerFileCategory = async (fileData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/register_file_category`,
    fileData,
    config
  );

  return response.data;
};

const updateFileCategory = async (updatedFileData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/change_file_category`,
    updatedFileData,
    config
  );

  return response.data;
};

const deleteFileCategory = async (fileId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/delete_file_category`,
    fileId,
    config
  );

  return response.data;
};

const fileCategoriesService = {
  getFileCategories,
  registerFileCategory,
  updateFileCategory,
  deleteFileCategory,
};

export default fileCategoriesService;
