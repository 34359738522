import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileCategoriesService from "./FileCategoriesService";

const initialState = {
  fileCategories: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getFileCategories = createAsyncThunk(
  "projects/delegation/get_file_category",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await fileCategoriesService.getFileCategories(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const registerFileCategory = createAsyncThunk(
  "projects/delegation/register_file_category",
  async (fileData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await fileCategoriesService.registerFileCategory(fileData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const updateFileCategory = createAsyncThunk(
  "projects/delegation/change_file_category",
  async (updatedFileData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await fileCategoriesService.updateFileCategory(
        updatedFileData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const deleteFileCategory = createAsyncThunk(
  "projects/delegation/delete_file_category",
  async (fileId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await fileCategoriesService.deleteFileCategory(fileId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const fileCategoriesSlice = createSlice({
  name: "fileCategories",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFileCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFileCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fileCategories = action.payload;
        state.statusCode = 200;
      })
      .addCase(getFileCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(registerFileCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerFileCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fileCategories.push(action.payload);
        state.statusCode = 200;
      })
      .addCase(registerFileCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(updateFileCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFileCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.fileCategories.findIndex(
          (category) => category.id === action.payload.id
        );
        state.fileCategories[index] = action.payload;
        state.statusCode = 200;
      })
      .addCase(updateFileCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(deleteFileCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFileCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fileCategories = state.fileCategories?.filter(
          (category) => category.id !== action.payload
        );
        state.statusCode = 200;
      })
      .addCase(deleteFileCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = fileCategoriesSlice.actions;
export default fileCategoriesSlice.reducer;
