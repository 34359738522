import axios from "axios";

const getProcessingParcels = async (
  token,
  warehouseCountryId,
  fileName,
  userName,
  parcelGroupBarcode,
  tdsCode,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
    fileName: fileName,
    userName: userName,
    parcelGroupBarcode: parcelGroupBarcode,
    tdsCode: tdsCode,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/processing_parcel_group/get_processing_parcel_groups`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const uploadProcessingGroupFile = async (fileData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/processing_parcel_group/file_upload`,
    fileData,
    config
  );

  return response.data;
};

const changeProcessingParcelGroup = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/processing_parcel_group/change_parcel_group`,
    groupData,
    config
  );

  return response.data;
};

const exportProcessingGroups = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/processing_parcel_group/export_parcels`,
      { id },
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteProcessingGroup = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/processing_parcel_group/delete`,
    groupData,
    config
  );

  return response.data;
};

const completeProcessingGroup = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/processing_parcel_group/complete`,
    groupData,
    config
  );

  return response.data;
};

// const uploadImages = async (imagesData, token) => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   const response = await axios.post(
//     `${process.env.REACT_APP_URI}/processing_parcel_group/upload_images`,
//     imagesData,
//     config
//   );

//   return response.data;
// };

const processingParcelsService = {
  getProcessingParcels,
  uploadProcessingGroupFile,
  changeProcessingParcelGroup,
  exportProcessingGroups,
  deleteProcessingGroup,
  completeProcessingGroup,
  // uploadImages,
};

export default processingParcelsService;
