import { useAppContext } from "../../libs/AppContext";
import { PlusSvg } from "../icons";

const AddButton = () => {
  const { handleShowMainModal } = useAppContext();
  return (
    <button
      onClick={handleShowMainModal}
      className="h-11 w-11 p-3.5 fixed z-10 right-6 bottom-6 rounded-full bg-purple-3 flex justify-center items-center transition-all duration-500 ease-in-out cursor-pointer hover:bg-purple-1 md:p-5 md:h-16 md:w-16"
    >
      <PlusSvg />
    </button>
  );
};

export default AddButton;
