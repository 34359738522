import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import TextEditorTools from "./TextEditorTools";
import Templates from "./Templates";

const ContentTemplate = ({ font, setFont }) => {
  return (
    <TabGroup className="px-5 py-3 border-r border-black/10 ">
      <TabList className="grid grid-cols-[auto_auto]">
        <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
          Content
        </Tab>
        <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
          Template Design
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel className="py-6">
          <TextEditorTools font={font} setFont={setFont} />
        </TabPanel>
        <TabPanel className="py-6">
          <Templates />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default ContentTemplate;
