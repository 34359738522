import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import responseTimeService from "./ResponseTimeService";

const initialState = {
  responseTime: [],
  responseTimeDetails: [],
  terminalTime: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getResponseTime = createAsyncThunk(
  "responseTimeReport/get_report",
  async ({ reportType, groupPeriod, departmentId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await responseTimeService.getResponseTime(
        token,
        reportType,
        groupPeriod,
        departmentId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const getResponseTimeDetails = createAsyncThunk(
  "responseTimeReport/get_report_details",
  async ({ reportType, groupPeriod, departmentId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await responseTimeService.getResponseTimeDetails(
        token,
        reportType,
        groupPeriod,
        departmentId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const responseTimeSlice = createSlice({
  name: "responseTime",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResponseTime.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResponseTime.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.responseTime = action.payload;
        state.statusCode = 200;
      })
      .addCase(getResponseTime.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(getResponseTimeDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getResponseTimeDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.responseTimeDetails = action.payload;
        state.statusCode = 200;
      })
      .addCase(getResponseTimeDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = responseTimeSlice.actions;
export default responseTimeSlice.reducer;
