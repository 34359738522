import axios from "axios";

const getStatuses = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/processing_parcel_group/get_processing_parcel_group_detail_statuses`,
    config
  );

  return response.data;
};

const statusesService = { getStatuses };
export default statusesService;
