import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BackSvg } from "../../../components/icons";
import { getDeliveryTimeDetails } from "../../../features/dashboard/deliveryTime/DeliveryTimeSlice";
import { getPeriods } from "../../../features/dashboard/periods/PeriodsSlice";
import { getBranches } from "../../../features/dashboard/branches/BranchesSlice";
import Spinner from "../../../components/common/Spinner";
import AverageTimeDetailsElement from "../../../components/dashboard/components/AverageTimeDetailsElement";
import InnerPagesPeriods from "../../../components/dashboard/components/InnerPagesPeriods";
import InnerPagesBranches from "../../../components/dashboard/components/InnerPagesBranches";
import Message from "../../../components/common/Message";
import Pagination from "../../../components/dashboard/components/Pagination";
import DeliveryTimeChart from "../../../components/dashboard/components/deliveryTime/DeliveryTimeChart";
import DeliveryTimeChartSmall from "../../../components/dashboard/components/deliveryTime/DeliveryTimeChartSmall";
import { useTranslation } from "react-i18next";

const DeliveryTimeDetails = () => {
  const [formData, setFormData] = useState({
    reportType: "avgTerminalWithdrawTime",
    groupPeriod: "",
    departmentId: "",
  });
  const [longestTime, setLongestTime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isUser, setIsUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const [activeName, setActiveName] = useState(null);
  const elementsPerPage = 12;
  const { reportType, groupPeriod, departmentId } = formData;
  const { isLoading, isError, deliveryTimeDetails } = useSelector(
    (state) => state.deliveryTime
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (groupPeriod) {
      dispatch(
        getDeliveryTimeDetails({ reportType, groupPeriod, departmentId })
      );
    }
  }, [dispatch, reportType, groupPeriod, departmentId]);

  useEffect(() => {
    dispatch(getPeriods());
    dispatch(getBranches());
  }, [dispatch]);

  const timeToSeconds = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const secondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    let longestTimeSeconds = 0;

    deliveryTimeDetails?.details?.forEach((record) => {
      const currentTimeSeconds = timeToSeconds(record.avgTime);
      if (currentTimeSeconds > longestTimeSeconds) {
        longestTimeSeconds = currentTimeSeconds;
      }
    });

    const longestTime = secondsToTime(longestTimeSeconds);
    setLongestTime(longestTime);
  }, [deliveryTimeDetails]);

  const indexOfLastElement = currentPage * elementsPerPage;
  const indexOfFirstElement = indexOfLastElement - elementsPerPage;
  const currentDetails = deliveryTimeDetails?.details?.slice(
    indexOfFirstElement,
    indexOfLastElement
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(
    deliveryTimeDetails?.details?.length / elementsPerPage
  );

  const handleGetWeekResponse = (name) => {
    if (name === activeName) {
      setIsUser(false);
      setActiveName(null);
      setUserData([]);
    } else {
      setIsUser(true);
      setActiveName(name);
      const filteredData = deliveryTimeDetails?.detailsByUser?.filter(
        (item) => item.changeUser === name
      );
      setUserData(filteredData);
    }
  };

  return (
    <div>
      <div className="w-full flex-col bg-white-1 p-3 flex items-start justify-between gap-2 border-b border-black/10 md:flex-row md:items-center md:gap-3">
        <div className="flex items-center gap-3">
          <Link
            to="/MainPage"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          <div className="text-lg font-semibold text-gray-800 md:text-xl">
            {t("text.average_delivery_time")}
          </div>
        </div>
        <div className="flex justify-center relative z-50 flex-col-reverse items-start gap-2 w-full md:w-max md:items-center md:flex-row md:gap-4 ">
          <InnerPagesPeriods setFormData={setFormData} />
          <InnerPagesBranches setFormData={setFormData} />
        </div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Message text="Something went wrong, please try again" />
      ) : (
        <div className="p-9 w-full flex flex-col items-center gap-[100px] lg:p-9 md:p-6">
          <div className="w-full flex flex-col items-end gap-4">
            {currentDetails?.length > 0 ? (
              <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                {currentDetails?.map((detail, index) => (
                  <AverageTimeDetailsElement
                    key={index}
                    detail={detail}
                    longestTime={longestTime}
                    type="deliveryTime"
                    onClick={() => handleGetWeekResponse(detail.changeUser)}
                    isActive={detail.changeUser === activeName}
                  />
                ))}
              </div>
            ) : (
              <Message text={t("text.not_found")} />
            )}
            {currentDetails?.length > 0 && (
              <Pagination
                totalPages={totalPages}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            )}
          </div>
          {isUser ? (
            <DeliveryTimeChartSmall data={userData} />
          ) : (
            <DeliveryTimeChart data={currentDetails} />
          )}
        </div>
      )}
    </div>
  );
};

export default DeliveryTimeDetails;
