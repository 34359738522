import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset, searchUser } from "../../features/groups/UsersSlice";
import { IoCloseSharp } from "react-icons/io5";
import MultiRoomNumbers from "./MultiRoomNumbers";
import Checkbox from "./Checkbox";
import InputSmall from "../common/InputSmall";

const OneUser = ({ formData, setFormData }) => {
  const [showRoomNumbers, setShowRoomNumbers] = useState(false);
  const [selectedType, setSelectedType] = useState("email");
  const [currentInput, setCurrentInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { users, isLoading } = useSelector((state) => state.roomUsers);
  const debounceTimeout = useRef(null);
  const dispatch = useDispatch();

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if (name === "userRoomNumbers" && value.length > 2) {
      await dispatch(reset());
      setShowRoomNumbers(true);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        dispatch(searchUser(value));
      }, 500);
    } else {
      setShowRoomNumbers(false);
    }
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setCurrentInput("");
    setErrorMessage("");

    setFormData((prev) => ({
      ...prev,
      [type === "email" ? "mobiles" : "emails"]: [],
      [type === "email" ? "emails" : "mobiles"]: [],
    }));
  };

  const validateMobile = (mobile) => /^5\d{8}$/.test(mobile);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleAddToList = () => {
    const items = currentInput.split(";").map((item) => item.trim());
    const validItems = [];
    const invalidItems = [];

    items.forEach((item) => {
      if (
        selectedType === "email" ? validateEmail(item) : validateMobile(item)
      ) {
        validItems.push(item);
      } else {
        invalidItems.push(item);
      }
    });

    if (invalidItems.length > 0) {
      setErrorMessage(
        `Invalid ${
          selectedType === "email" ? "email(s)" : "phone number(s)"
        }: ${invalidItems.join(", ")}`
      );
    } else {
      setErrorMessage("");
    }

    if (validItems.length > 0) {
      setFormData((prev) => ({
        ...prev,
        [selectedType === "email" ? "emails" : "mobiles"]: [
          ...(prev[selectedType === "email" ? "emails" : "mobiles"] || []),
          ...validItems,
        ],
      }));
    }

    setCurrentInput("");
  };

  const handleCurrentInputChange = (e) => {
    const inputValue = e.target.value;
    setCurrentInput(inputValue);

    if (inputValue && !inputValue.endsWith(";")) {
      setErrorMessage(
        `Entries must end with a semicolon (;). Example: ${
          selectedType === "email" ? "example@mail.com;" : "512345678;"
        }`
      );
    } else {
      setErrorMessage("");
    }

    if (inputValue.endsWith(";")) {
      handleAddToList();
    }
  };

  const handleRemoveItem = (index) => {
    setFormData((prev) => ({
      ...prev,
      [selectedType === "email" ? "emails" : "mobiles"]: prev[
        selectedType === "email" ? "emails" : "mobiles"
      ].filter((_, i) => i !== index),
    }));
  };

  const options = users?.map((user) => user.roomNumber) || [];

  return (
    <div className="w-full flex flex-col items-start gap-6">
      <MultiRoomNumbers
        options={options}
        showOptions={showRoomNumbers}
        setShowOptions={setShowRoomNumbers}
        isLoading={isLoading}
        name="userRoomNumbers"
        onChange={handleInputChange}
        setFormData={setFormData}
        formData={formData}
      />
      <div>
        <span className="text-xs text-gray-1">Notification Type</span>
        <ul className="py-3 w-full flex flex-col items-start gap-3">
          <li className="flex justify-start items-center gap-2 text-xs text-gray-1">
            <Checkbox
              checked={selectedType === "email"}
              onChange={() => handleTypeChange("email")}
            />
            <span>E-mail</span>
          </li>
          <li className="flex justify-start items-center gap-2 text-xs text-gray-1">
            <Checkbox
              checked={selectedType === "mobile"}
              onChange={() => handleTypeChange("mobile")}
            />
            <span>Phone</span>
          </li>
        </ul>
      </div>
      <div className="w-full">
        <InputSmall
          name={selectedType === "email" ? "emails" : "mobiles"}
          value={currentInput}
          text={`Type ${
            selectedType === "email" ? "Emails" : "Numbers"
          } (end with ;)`}
          onchange={handleCurrentInputChange}
        />
        {errorMessage && (
          <div className="text-red-500 text-xs mt-2">{errorMessage}</div>
        )}
        <div className="text-xs text-gray-500 flex flex-col items-start gap-1 mt-2">
          <p> Current {selectedType === "email" ? "Emails" : "Phones"}:</p>
          {(
            formData[selectedType === "email" ? "emails" : "mobiles"] || []
          ).map((item, index) => (
            <div
              key={index}
              className="text-blue-1 font-semibold flex items-center gap-2"
            >
              <span>{item}</span>
              <button
                type="button"
                onClick={() => handleRemoveItem(index)}
                className="w-4 h-4 flex justify-center items-center text-base"
              >
                <IoCloseSharp />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OneUser;
