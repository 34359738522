import { CloseModalSvg } from "../../icons";

const ModalsHeadline = ({ onClick, text }) => {
  return (
    <div className="w-full flex items-center justify-between p-4 border-b border-gray-200 gap-x-4 md:p-6">
      <h2 className="text-base font-bold text-blue-1 capitalize md:text-xl">
        {text}
      </h2>
      <button type="button" onClick={onClick} className="cursor-pointer">
        <CloseModalSvg />
      </button>
    </div>
  );
};

export default ModalsHeadline;
