import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import providerParcelsService from "./ProviderParcelsService";

const initialState = {
  providerParcels: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getProviderParcels = createAsyncThunk(
  "processing_parcel_group/get_provider_processing_parcels",
  async (
    { warehouseCountryId, tdsCode, roomNumber, page, perPage },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await providerParcelsService.getProviderParcels(
        token,
        warehouseCountryId,
        tdsCode,
        roomNumber,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const exportProviderParcels = createAsyncThunk(
  "processing_parcel_group/export_provider_processing_parcels",
  async ({ warehouseCountryId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await providerParcelsService.exportProviderParcels(
        warehouseCountryId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const providerParcelsSlice = createSlice({
  name: "providerParcels",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProviderParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProviderParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.providerParcels = action.payload;
      })
      .addCase(getProviderParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = providerParcelsSlice.actions;
export default providerParcelsSlice.reducer;
