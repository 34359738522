import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Snackbar } from "@mui/material";
import { getRoles } from "../../features/roles/RolesSlice";
import {
  deleteDepartment,
  getDepartments,
} from "../../features/departments/DepartamentsSlice";
import { getDepartmentLinks } from "../../features/departmentLinks/DepartmentLinksSlice";
import { useAppContext } from "../../libs/AppContext";
import { useTranslation } from "react-i18next";
import AddButton from "../common/AddButton";
import DepartmentsTable from "../table/departments/DepartmentsTable";
import Modal from "../common/Modal";
import EditDepartment from "../../components/modals/departments/EditDepartment";
import AddDepartment from "../../components/modals/departments/AddDepartment";
import DeleteConfirmation from "../common/DeleteConfirmation";
import AddEmployee from "../../components/modals/departments/AddEmployee";
import TableHeadline from "../common/TableHeadline";

const DepartmentsLayout = () => {
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [departmentId, setDepartment] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const { setShowMainModal, showMainModal, handleShowMainModal } =
    useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getRoles(departmentId));
    dispatch(getDepartmentLinks(departmentId));
  }, [departmentId, dispatch]);

  const handleShowAddEmployee = (id) => {
    setShowAddEmployee(!showAddEmployee);
    setDepartment(id);
  };

  const handleShowEditEmployee = (id) => {
    setShowEditEmployee(!showEditEmployee);
    setDepartment(id);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setDepartment(id);
  };

  const handleDeleteDepartment = async () => {
    const departmentData = {
      departmentId: departmentId,
    };

    const response = await dispatch(deleteDepartment(departmentData));
    if (response.payload.type === "success") {
      dispatch(getDepartments());
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }

    if (response.error.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          message={responseMessage}
        />
      )}
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddDepartment
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowModal={setShowMainModal}
          closeModal={handleShowMainModal}
        />
      </Modal>
      <Modal showmodal={showAddEmployee} setShowModal={setShowAddEmployee}>
        <AddEmployee
          handleShowAddEmployee={handleShowAddEmployee}
          setShowAddEmployee={setShowAddEmployee}
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          departmentID={departmentId}
        />
      </Modal>
      <Modal showmodal={showEditEmployee} setShowModal={setShowEditEmployee}>
        <EditDepartment
          handleShowEditEmployee={handleShowEditEmployee}
          setShowEditEmployee={setShowEditEmployee}
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          departmentID={departmentId}
        />
      </Modal>
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteDepartment}
          text={t("text.department")}
        />
      )}
      <TableHeadline text={t("text.delegationDepartments")} />
      <DepartmentsTable
        handleShowAddEmployee={handleShowAddEmployee}
        handleShowEditEmployee={handleShowEditEmployee}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
      <AddButton />
    </div>
  );
};

export default DepartmentsLayout;
