import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { MoreSvg } from "../icons";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomDrop = (props) => {
  const { t } = useTranslation();
  const { text, children } = props;
  return (
    <div className="w-full cursor-pointer">
      <Menu as="div" className="h-full w-full flex justify-center items-center">
        <MenuButton className="w-full cursor-pointer px-2">
          <Tooltip title={t("text.more")}>
            <div className="flex items-center">
              <MoreSvg />
              <h3>{text}</h3>
            </div>
          </Tooltip>
        </MenuButton>
        <MenuItems className="z-10 w-20 bg-white-1 right-0 top-0 flex flex-col items-start justify-between border border-white-2 rounded-md overflow-hidden absolute">
          {Array.isArray(children) &&
            children.map((child, index) => (
              <MenuItem
                className="flex items-center justify-start gap-2 text-sm p-1.5 w-full hover:bg-blue-2/5"
                key={index}
              >
                {child}
              </MenuItem>
            ))}
        </MenuItems>
      </Menu>
    </div>
  );
};

export default CustomDrop;
