import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSvg, DocumentSvg } from "../../icons";
import { useTranslation } from "react-i18next";
import {
  getClients,
  removeAttachments,
  updateClient,
} from "../../../features/clients/ClientsSlice";
import Input from "../../common/Input";
import Select from "../../common/Select";
import UploadFile from "../../common/UploadFile";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";

const EditClient = ({
  setShowModal,
  closeModal,
  clientId,
  data,
  setResponseMessage,
  setShowResponseMessage,
}) => {
  const { countries } = useSelector((state) => state.countries);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesData, setSelectedFilesData] = useState({ file_ids: [] });
  const [prevValue, setPrevValue] = useState("");
  const [formData, setFormData] = useState({
    id: clientId,
    inn: "",
    fullName: "",
    email: "",
    countryId: "",
    cityName: "",
    districtName: "",
    phone: "",
    address: "",
    "file[]": [],
  });
  const [errors, setErrors] = useState({
    inn: "",
    fullName: "",
    email: "",
    countryId: "",
    cityName: "",
    districtName: "",
    phone: "",
    address: "",
  });
  const resetSelectedOptionRef = useRef(null);
  const { file_ids } = selectedFilesData;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = Array.isArray(countries)
    ? countries.map((country) => country.name)
    : [];

  useEffect(() => {
    const clientData = data.find((client) => client.id === clientId);
    if (clientData) {
      setPrevValue(clientData.country);
      setFormData({
        id: clientData.id,
        inn: clientData.inn || "",
        fullName: clientData.fullName || "",
        email: clientData.email || "",
        countryId: clientData.countryId || "",
        cityName: clientData.cityName || "",
        districtName: clientData.districtName || "",
        phone: clientData.phone || "",
        address: clientData.address || "",
        "file[]": clientData.attachments || [],
      });
    }
  }, [clientId, data]);

  useEffect(() => {
    setSelectedFilesData({ file_ids: JSON.stringify(selectedFiles) });
  }, [selectedFiles]);

  const updateCountryId = (countryId) => {
    setFormData({
      ...formData,
      countryId: countryId,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      countryId: "",
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const newFiles = e.target.files;

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": [...prevFormData["file[]"], ...Array.from(newFiles)],
    }));
  };

  const handleDeleteFile = (index, id) => {
    const newFiles = [...formData["file[]"]];
    newFiles.splice(index, 1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": newFiles,
    }));

    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, id]);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (
      !formData.inn ||
      !formData.fullName ||
      !formData.email ||
      !formData.countryId ||
      !formData.cityName ||
      !formData.districtName ||
      !formData.phone ||
      !formData.address
    ) {
      setErrors({
        inn: formData.inn ? "" : "ID is required",
        fullName: formData.fullName ? "" : "Name & Username is required",
        email: formData.email ? "" : "Email is required",
        countryId: formData.countryId ? "" : "Country is required",
        cityName: formData.cityName ? "" : "City is required",
        districtName: formData.districtName ? "" : "Distract is required",
        phone: formData.phone ? "" : "Phone is required",
        address: formData.address ? "" : "Address is required",
      });
      return;
    }

    const filesData = { file_ids };
    const formDataToSend = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "file[]") {
        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(updateClient(formDataToSend));
      const deleteResponse = await dispatch(removeAttachments(filesData));

      if (
        response.payload.type === "success" ||
        deleteResponse.payload.type === "success"
      ) {
        setShowModal(false);
        dispatch(getClients());
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }

      if (
        response.error.message === "Rejected" ||
        deleteResponse.error.message === "Rejected"
      ) {
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          inn: error.response.data.inn || "",
          fullName: error.response.data.fullName || "",
          email: error.response.data.email || "",
          countryId: error.response.data.countryId || "",
          cityName: error.response.data.cityName || "",
          districtName: error.response.data.districtName || "",
          phone: error.response.data.phone || "",
          address: error.response.data.address || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleUpdate}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text={t("text.change_client")} onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.inn")}
              name="inn"
              value={formData.inn}
              onchange={handleInputChange}
              error={errors.inn}
              errormark={errors.inn ? "error-border" : ""}
            />
            {errors.inn && (
              <p className="text-red-500 text-xs mt-1">{errors.inn}</p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text="Name & Surname"
              name="fullName"
              value={formData.fullName}
              onchange={handleInputChange}
              error={errors.fullName}
              errormark={errors.fullName ? "error-border" : ""}
            />
            {errors.fullName && (
              <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text="Email"
              name="email"
              value={formData.email}
              onchange={handleInputChange}
              error={errors.email}
              errormark={errors.email ? "error-border" : ""}
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text="Choose a country"
              options={options}
              value={formData.countryId}
              countries={countries}
              type="countries"
              updateCountryId={updateCountryId}
              prevOption={prevValue}
              resetSelectedOption={resetSelectedOptionRef}
              error={errors.countryId}
              errormark={errors.countryId ? "error-border" : ""}
            />
            {errors.countryId && (
              <p className="text-red-500 text-xs mt-1">{errors.countryId}</p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text="City"
              name="cityName"
              value={formData.cityName}
              onchange={handleInputChange}
              error={errors.cityName}
              errormark={errors.cityName ? "error-border" : ""}
            />
            {errors.cityName && (
              <p className="text-red-500 text-xs mt-1">{errors.cityName}</p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text="District"
              name="districtName"
              value={formData.districtName}
              onchange={handleInputChange}
              error={errors.districtName}
              errormark={errors.districtName ? "error-border" : ""}
            />
            {errors.districtName && (
              <p className="text-red-500 text-xs mt-1">{errors.districtName}</p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text="Mobile"
              name="phone"
              value={formData.phone}
              onchange={handleInputChange}
              error={errors.phone}
              errormark={errors.phone ? "error-border" : ""}
            />
            {errors.phone && (
              <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text="Address"
              name="address"
              value={formData.address}
              onchange={handleInputChange}
              error={errors.address}
              errormark={errors.address ? "error-border" : ""}
            />
            {errors.address && (
              <p className="text-red-500 text-xs mt-1">{errors.address}</p>
            )}
          </div>
          <div className="relative w-full">
            <UploadFile onChange={handleFileChange} />
          </div>
          <div className="relative w-full">
            {formData["file[]"]?.map((file, index) => (
              <div
                key={index}
                className="w-full rounded-lg bg-gray-100 p-3 flex items-center justify-between gap-3"
              >
                <div className="flex items-center gap-3">
                  <div className="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center">
                    <DocumentSvg />
                  </div>
                  <div className="flex flex-col items-start">
                    <h3 className="text-sm font-medium text-blue-900 truncate">
                      {file.name}
                    </h3>
                  </div>
                </div>
                <div
                  className="p-2 flex justify-center items-center transition-transform duration-500 hover:cursor-pointer hover:text-red-500"
                  onClick={() => handleDeleteFile(index, file.fileId)}
                >
                  <DeleteSvg />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="p-6">
          <ButtonDefault type="submit">{t("text.change_client")}</ButtonDefault>
        </div>
      </div>
    </form>
  );
};

export default EditClient;
