import React, { useState, useRef } from "react";
import { UploadImageSvg } from "../icons";

const PhotoUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  console.log(selectedFile);

  return (
    <div className="relative">
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
      <div
        id="upload"
        onClick={handleUploadClick}
        className="flex flex-col items-center justify-center gap-4 w-32 h-32 border-dashed border-gray-7 border-2 rounded-lg bg-white-1 cursor-pointer transition-all duration-500 hover:bg-white-3"
      >
        {preview ? (
          <div className="w-32 h-32 rounded-lg overflow-hidden border border-white-1 cursor-pointer">
            <img
              src={preview}
              alt="Selected"
              className="h-full w-full object-cover"
            />
          </div>
        ) : (
          <>
            <UploadImageSvg />
            <span className="text-xs font-normal max-w-20 text-center">
              Upload your photo
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default PhotoUpload;
