import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSvg, DocumentSvg } from "../../icons";
import { useTranslation } from "react-i18next";
import {
  getClients,
  registerClient,
} from "../../../features/clients/ClientsSlice";
import Input from "../../common/Input";
import Select from "../../common/Select";
import UploadFile from "../../common/UploadFile";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";

const ClientsModal = ({
  setResponseMessage,
  setShowResponseMessage,
  setShowModal,
  closeModal,
}) => {
  const { countries } = useSelector((state) => state.countries);
  const [formData, setFormData] = useState({
    inn: "",
    fullName: "",
    email: "",
    countryId: "",
    cityName: "",
    districtName: "",
    phone: "",
    address: "",
    "file[]": [],
  });
  const [errors, setErrors] = useState({
    inn: "",
    fullName: "",
    email: "",
    countryId: "",
    cityName: "",
    districtName: "",
    phone: "",
    address: "",
  });
  const dispatch = useDispatch();
  const resetSelectedOptionRef = useRef(null);
  const options = Array.isArray(countries)
    ? countries.map((country) => country.name)
    : [];
  const { t } = useTranslation();

  const updateCountryId = (countryId) => {
    setFormData({
      ...formData,
      countryId: countryId,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      countryId: "",
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const newFiles = e.target.files;

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": [...prevFormData["file[]"], ...Array.from(newFiles)],
    }));
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...formData["file[]"]];
    newFiles.splice(index, 1);

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": newFiles,
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (
      !formData.inn ||
      !formData.fullName ||
      !formData.email ||
      !formData.countryId ||
      !formData.cityName ||
      !formData.districtName ||
      !formData.phone ||
      !formData.address
    ) {
      setErrors({
        inn: formData.inn ? "" : t("text.id_required"),
        fullName: formData.fullName ? "" : t("text.name_required"),
        email: formData.email ? "" : t("text.email_required"),
        countryId: formData.countryId ? "" : t("text.country_required"),
        cityName: formData.cityName ? "" : t("text.city_required"),
        districtName: formData.districtName ? "" : t("text.district_required"),
        phone: formData.phone ? "" : t("text.phone_required"),
        address: formData.address ? "" : t("text.address_required"),
      });
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "file[]") {
        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(registerClient(formDataToSend));
      if (response.payload.type === "success") {
        setShowModal(false);
        dispatch(getClients());
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setFormData({
          inn: "",
          fullName: "",
          email: "",
          countryId: "",
          cityName: "",
          districtName: "",
          phone: "",
          address: "",
          "file[]": [],
        });
        if (resetSelectedOptionRef.current) {
          resetSelectedOptionRef.current();
        }
      }
      if (response.error.message === "Rejected") {
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          inn: error.response.data.inn || "",
          fullName: error.response.data.fullName || "",
          email: error.response.data.email || "",
          countryId: error.response.data.countryId || "",
          cityName: error.response.data.cityName || "",
          districtName: error.response.data.districtName || "",
          phone: error.response.data.phone || "",
          address: error.response.data.address || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleRegister}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text={t("text.add_client")} onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.id")}
              name="inn"
              value={formData.inn}
              onchange={handleInputChange}
              error={errors.inn}
              errormark={errors.inn ? "error-border" : ""}
            />
            {errors.inn && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.inn}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.name_and_surname")}
              name="fullName"
              value={formData.fullName}
              onchange={handleInputChange}
              error={errors.fullName}
              errormark={errors.fullName ? "error-border" : ""}
            />
            {errors.fullName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.fullName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.email")}
              name="email"
              value={formData.email}
              onchange={handleInputChange}
              error={errors.email}
              errormark={errors.email ? "error-border" : ""}
            />
            {errors.email && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.email}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Select
              text={t("text.choose_country")}
              options={options}
              value={formData.countryId}
              countries={countries}
              type="countries"
              updateCountryId={updateCountryId}
              resetSelectedOption={resetSelectedOptionRef}
              error={errors.countryId}
              errormark={errors.countryId ? "error-border" : ""}
            />
            {errors.countryId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.countryId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.city")}
              name="cityName"
              value={formData.cityName}
              onchange={handleInputChange}
              error={errors.cityName}
              errormark={errors.cityName ? "error-border" : ""}
            />
            {errors.cityName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.cityName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.district")}
              name="districtName"
              value={formData.districtName}
              onchange={handleInputChange}
              error={errors.districtName}
              errormark={errors.districtName ? "error-border" : ""}
            />
            {errors.districtName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.districtName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.phone")}
              name="phone"
              value={formData.phone}
              onchange={handleInputChange}
              error={errors.phone}
              errormark={errors.phone ? "error-border" : ""}
            />
            {errors.phone && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.phone}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.address")}
              name="address"
              value={formData.address}
              onchange={handleInputChange}
              error={errors.address}
              errormark={errors.address ? "error-border" : ""}
            />
            {errors.address && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.address}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <UploadFile onChange={handleFileChange} />
          </div>
          <div className="gap-y-4">
            {formData["file[]"].map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-100 rounded-lg shadow"
              >
                <div className="flex items-center space-x-3">
                  <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                    <DocumentSvg />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-blue-900 truncate">
                      {file.name}
                    </h3>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => handleDeleteFile(index)}
                  className="p-2 text-red-500 hover:text-red-700 transition-colors duration-300"
                >
                  <DeleteSvg />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="p-6">
          <ButtonDefault type="submit">{t("text.add")}</ButtonDefault>
        </div>
      </div>
    </form>
  );
};

export default ClientsModal;
