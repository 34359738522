import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import racesProgressService from "./RacesProgressService";

const initialState = {
  racesProgress: {},
  racesProgressDetails: [],
  countries: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getRacesProgress = createAsyncThunk(
  "racesProgressReport/get_report",
  async ({ reportType, groupPeriod, departmentId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await racesProgressService.getRacesProgress(
        token,
        reportType,
        groupPeriod,
        departmentId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const getRacesProgressDetails = createAsyncThunk(
  "racesProgressReport/get_report_details",
  async (
    {
      reportType,
      groupPeriod,
      departmentId,
      countryCode,
      page,
      perPage,
      userName,
      roomNumber,
      sendStartDate,
      sendEndDate,
      warehouseStartDate,
      warehouseEndDate,
      tdsCode,
      arrivedStartDate,
      arrivedEndDate,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await racesProgressService.getRacesProgressDetails(
        token,
        reportType,
        groupPeriod,
        departmentId,
        countryCode,
        page,
        perPage,
        userName,
        roomNumber,
        sendStartDate,
        sendEndDate,
        warehouseStartDate,
        warehouseEndDate,
        tdsCode,
        arrivedStartDate,
        arrivedEndDate
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const exportExcel = createAsyncThunk(
  "racesProgressReport/get_report_details_excel",
  async (
    {
      reportType,
      groupPeriod,
      departmentId,
      countryCode,
      page,
      perPage,
      userName,
      roomNumber,
      sendStartDate,
      sendEndDate,
      warehouseStartDate,
      warehouseEndDate,
      tdsCode,
      arrivedStartDate,
      arrivedEndDate,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await racesProgressService.exportExcel(
        token,
        reportType,
        groupPeriod,
        departmentId,
        countryCode,
        page,
        perPage,
        userName,
        roomNumber,
        sendStartDate,
        sendEndDate,
        warehouseStartDate,
        warehouseEndDate,
        tdsCode,
        arrivedStartDate,
        arrivedEndDate
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const getCountries = createAsyncThunk(
  "report/get_countries",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await racesProgressService.getCountries(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const racesProgressSlice = createSlice({
  name: "racesProgress",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRacesProgress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRacesProgress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.racesProgress = action.payload;
        state.statusCode = 200;
      })
      .addCase(getRacesProgress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(getRacesProgressDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRacesProgressDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.racesProgressDetails = action.payload;
        state.statusCode = 200;
      })
      .addCase(getRacesProgressDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload;
        state.statusCode = 200;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = racesProgressSlice.actions;
export default racesProgressSlice.reducer;
