import axios from "axios";

const calculatePrice = async (token, parcelData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/calculate_price`,
      parcelData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const calculationsService = { calculatePrice };
export default calculationsService;
