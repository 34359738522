import { useState, useEffect, useRef } from "react";
import { ArrowDownSvg } from "../../icons";
import { useTranslation } from "react-i18next";

const ListBoxSmall = ({ options, text, name, value, onChange, error }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(term)
    );
    setFilteredOptions(filtered);
  };

  const handleSelectChange = (value) => {
    onChange({ target: { name, value } });
    setShowDropdown(false);
    setSearchTerm("");
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  // const handleClearValue = (e) => {
  //   e.stopPropagation();
  //   onChange({ target: { name, value: "" } });
  //   // localStorage.removeItem("parcelgroupsdata");
  //   setSearchTerm("");
  // };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        className={`relative cursor-pointer w-full text-xs border border-gray-200 rounded-lg flex items-center justify-between px-4 py-2 whitespace-nowrap md:text-sm lg:py-2.5`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {value ? (
          options.find((option) => parseInt(option.value) === parseInt(value))
            ?.label
        ) : (
          <span className="text-gray-1 text-xs md:text-sm">{text}</span>
        )}

        <ArrowDownSvg />
      </div>

      {showDropdown && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-lg max-h-60 overflow-auto">
          {filteredOptions.length > 10 && (
            <input
              type="text"
              className="w-full text-sm px-4 py-3 border-b border-gray-300 outline-none"
              placeholder={t("text.search")}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          )}

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelectChange(option.value)}
                className={`flex justify-between items-center gap-1 cursor-pointer px-4 py-1 text-xs hover:bg-blue-2/10 md:py-2 md:text-sm ${
                  value === option.value ? "bg-blue-2/10 font-semibold" : ""
                }`}
              >
                {option.label}
                {/* {value === option.value && (
                  <span
                    className="h-4 w-4 flex justify-center items-center md:h-5 md:w-5"
                    onClick={(e) => handleClearValue(e, name)}
                  >
                    <CloseModalSvg />
                  </span>
                )} */}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500 text-xs md:text-sm">
              {t("text.not_found")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ListBoxSmall;
