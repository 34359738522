import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import issuedParcelsService from "./IssuedParcelsService";

const initialState = {
  terminalRequests: [],
  terminalRequestsDetails: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getIssuedParcels = createAsyncThunk(
  "issuedParcelsReport/get_report",
  async ({ reportType, groupPeriod, departmentId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await issuedParcelsService.getIssuedParcels(
        token,
        reportType,
        groupPeriod,
        departmentId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const getIssuedParcelsDetails = createAsyncThunk(
  "issuedParcelsReport/get_report_details",
  async (
    {
      reportType,
      groupPeriod,
      departmentId,
      page,
      perPage,
      userName,
      tdsCode,
      withdrawStartDate,
      withdrawEndDate,
      rate,
      inpStartDate,
      inpEndDate,
      status,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await issuedParcelsService.getIssuedParcelsDetails(
        token,
        reportType,
        groupPeriod,
        departmentId,
        page,
        perPage,
        userName,
        tdsCode,
        withdrawStartDate,
        withdrawEndDate,
        rate,
        inpStartDate,
        inpEndDate,
        status
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const exportExcel = createAsyncThunk(
  "issuedParcelsReport/get_report_details_excel",
  async (
    {
      reportType,
      groupPeriod,
      departmentId,
      page,
      perPage,
      userName,
      tdsCode,
      withdrawStartDate,
      withdrawEndDate,
      rate,
      inpStartDate,
      inpEndDate,
      status,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await issuedParcelsService.exportExcel(
        token,
        reportType,
        groupPeriod,
        departmentId,
        page,
        perPage,
        userName,
        tdsCode,
        withdrawStartDate,
        withdrawEndDate,
        rate,
        inpStartDate,
        inpEndDate,
        status
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const issuedParcelsSlice = createSlice({
  name: "issuedParcels",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIssuedParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIssuedParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.terminalRequests = action.payload;
        state.statusCode = 200;
      })
      .addCase(getIssuedParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(getIssuedParcelsDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIssuedParcelsDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.terminalRequestsDetails = action.payload;
        state.statusCode = 200;
      })
      .addCase(getIssuedParcelsDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = issuedParcelsSlice.actions;
export default issuedParcelsSlice.reducer;
