import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DeleteSvg, DocumentSvg, ExportFileSvg } from "../../icons";
import {
  getClients,
  removeAttachments,
  updateClient,
} from "../../../features/clients/ClientsSlice";
import Checkbox from "../../common/Checkbox";
import UploadFile from "../../common/UploadFile";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";

const ClientFiles = ({
  closeModal,
  clientId,
  data,
  setResponseMessage,
  setShowResponseMessage,
  setShowModal,
}) => {
  const [files, setFiles] = useState(null);
  const [filesData, setFilesData] = useState({ file_ids: [] });
  const [uploadFilesData, setUploadFilesData] = useState({
    id: clientId,
    inn: "",
    fullName: "",
    email: "",
    countryId: "",
    cityName: "",
    districtName: "",
    phone: "",
    address: "",
    "file[]": [],
  });
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const dispatch = useDispatch();
  const { file_ids } = filesData;

  useEffect(() => {
    const filteredFile = data.find((file) => file.id === clientId);
    setFiles(filteredFile);
  }, [clientId, data]);

  useEffect(() => {
    const clientData = data.find((client) => client.id === clientId);
    if (clientData) {
      setUploadFilesData({
        id: clientData.id,
        inn: clientData.inn || "",
        fullName: clientData.fullName || "",
        email: clientData.email || "",
        countryId: clientData.countryId || "",
        cityName: clientData.cityName || "",
        districtName: clientData.districtName || "",
        phone: clientData.phone || "",
        address: clientData.address || "",
        "file[]": [],
      });
    }
  }, [clientId, data]);

  const handleCheckboxChange = (attachmentId) => {
    setSelectedAttachments((prevSelection) => {
      const updatedSelection = (prevSelection || []).includes(attachmentId)
        ? prevSelection.filter((id) => id !== attachmentId)
        : [...(prevSelection || []), attachmentId];
      setFilesData({ file_ids: JSON.stringify(updatedSelection) });
      return updatedSelection;
    });
  };

  const handleFileChange = (e) => {
    const newFiles = e.target.files;
    setUploadFilesData((prevFormData) => ({
      ...prevFormData,
      "file[]": [...prevFormData["file[]"], ...Array.from(newFiles)],
    }));
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...uploadFilesData["file[]"]];
    newFiles.splice(index, 1);

    setUploadFilesData((prevFormData) => ({
      ...prevFormData,
      "file[]": newFiles,
    }));
  };

  const handleRemoveAttachments = async (e) => {
    e.preventDefault();
    const filesData = { file_ids };
    try {
      const response = await dispatch(removeAttachments(filesData));
      if (response.payload.type === "success") {
        dispatch(getClients());
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {}
  };

  const handleUploadFiles = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.entries(uploadFilesData).forEach(([key, value]) => {
      if (key === "file[]") {
        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(updateClient(formDataToSend));
      if (response.payload.type === "success") {
        dispatch(getClients());
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
      }
    } catch (error) {}
  };

  return (
    <div className="flex flex-col justify-between rounded-lg h-full">
      <ModalsHeadline text="Client Files" onClick={closeModal} />
      <div className="h-full flex flex-col justify-between">
        <form
          onSubmit={handleRemoveAttachments}
          className="p-6 flex flex-col gap-3"
        >
          <button
            type="submit"
            disabled={selectedAttachments.length === 0}
            className={`text-sm font-medium flex items-center gap-1 mb-2 ${
              selectedAttachments.length === 0
                ? "opacity-50 cursor-not-allowed"
                : "text-gray-700 cursor-pointer"
            }`}
          >
            Delete <DeleteSvg />
          </button>
          {files?.attachments?.map((attachment, index) => (
            <div
              key={index}
              className="w-full flex items-center gap-3 p-3 bg-gray-100 rounded-md"
            >
              <div className="flex-1 flex items-center justify-between gap-3">
                <div className="flex items-center gap-3">
                  <span className="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center">
                    <DocumentSvg />
                  </span>
                  <div className="flex flex-col">
                    <h3 className="text-sm font-medium text-blue-900 truncate">
                      {attachment.name}
                    </h3>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Link
                    to={attachment.url}
                    target="_blank"
                    className="p-2 flex items-center justify-center hover:text-blue-600"
                  >
                    <ExportFileSvg />
                  </Link>
                </div>
              </div>
              <Checkbox
                type="select"
                checked={selectedAttachments.includes(attachment.fileId)}
                onChange={() => handleCheckboxChange(attachment.fileId)}
              />
            </div>
          ))}
        </form>
        <form
          onSubmit={handleUploadFiles}
          className="p-6 flex flex-col items-center gap-4"
        >
          <UploadFile onChange={handleFileChange} />
          {uploadFilesData["file[]"]?.map((file, index) => (
            <div
              key={index}
              className="w-full flex items-center gap-3 p-3 bg-gray-100 rounded-md"
            >
              <div className="flex-1 flex items-center gap-3">
                <span className="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center">
                  <DocumentSvg />
                </span>
                <div className="flex flex-col">
                  <h3 className="text-sm font-medium text-blue-900 truncate">
                    {file.name}
                  </h3>
                </div>
              </div>
              <button
                type="button"
                onClick={() => handleDeleteFile(index)}
                className="p-2 text-red-500 hover:text-red-700 transition-colors"
              >
                <DeleteSvg />
              </button>
            </div>
          ))}
          <ButtonDefault type="submit">Upload</ButtonDefault>
        </form>
      </div>
    </div>
  );
};

export default ClientFiles;
