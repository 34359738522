import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../../libs/AppContext";
import { useTranslation } from "react-i18next";
import { changeGroupStatus } from "../../../features/groups/GroupsSlice";

const ChangeStatus = ({
  changeStatusForm,
  setShowChangeStatus,
  setGroupsIds,
  setSelectedRows,
  setMessage,
  setShowResponseMessage,
  setSeverity,
}) => {
  const { statuses } = useSelector((state) => state.parcelStatuses);
  const { parcelGroupIds, status } = changeStatusForm;
  const { setRefetch, refetch } = useAppContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getStatusName = (statusSymbol, t, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? t(status.statusDictionaryKey) : "";
  };

  const getStatusClassName = (statusSymbol, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? status.status.toLowerCase() : "";
  };

  const handleChangeStatus = async (e) => {
    e.preventDefault();

    const newStatus = { parcelGroupIds, status };

    try {
      const response = await dispatch(changeGroupStatus(newStatus));
      if (response.type === "parcel_group/change_status/fulfilled") {
        setRefetch(!refetch);
        setShowChangeStatus(false);
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage("ოპერაცია წარმატებით დასრულდა");
        setGroupsIds([]);
        setSelectedRows([]);
      } else if (response.error.message === "Rejected") {
        setShowChangeStatus(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("ოპერაციის შესრულებისას დაფიქსირდა შეცდომა");
      }
    } catch (error) {}
  };

  return (
    <div className="z-50 fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex justify-center items-start p-6">
      <form
        onSubmit={handleChangeStatus}
        className="flex flex-col items-center bg-white p-10 rounded-lg shadow-lg max-w-md w-full mt-24"
      >
        <p className="text-lg font-semibold text-gray-900 text-center mb-2 flex flex-col items-center">
          {t("text.confirm_parcel_group_change_status")}{" "}
          <span
            className={`${getStatusClassName(
              changeStatusForm.status,
              statuses
            )} status !bg-transparent !text-base !w-max`}
          >
            {getStatusName(changeStatusForm.status, t, statuses)}?
          </span>
        </p>
        <div className="w-2/3 flex justify-center items-center gap-3 mt-6">
          <button
            type="button"
            onClick={() => setShowChangeStatus(false)}
            className="max-w-48 w-full text-xs font-medium rounded-lg py-2.5 px-6 border transition-opacity ease-in-out duration-500 text-blue-2 bg-blue-2/10 border-blue-2/40 hover:opacity-50"
          >
            {t("text.cancel")}
          </button>
          <button
            type="submit"
            className="max-w-48 w-full text-xs font-medium rounded-lg py-2.5 px-6 border border-transparent transition-opacity ease-in-out duration-500 text-white bg-blue-2 hover:opacity-50"
          >
            {t("button.confirm")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangeStatus;
