import axios from "axios";

const uploadImages = async (imagesData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/processing_parcel_group/upload_images_new`,
    imagesData,
    config
  );

  return response.data;
};

const imagesService = { uploadImages };
export default imagesService;
