import axios from "axios";

const getPeriods = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_periods`,
    config
  );

  return response.data;
};

const periodsService = {
  getPeriods,
};

export default periodsService;
