import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";
import UploadFile from "../../common/UploadFile";
import { DeleteSvg } from "../../icons";
import { uploadProcessingGroupFile } from "../../../features/processingParcelGroups/ParcelGroupsSlice";
import { useAppContext } from "../../../libs/AppContext";

const UploadProcessingFile = ({
  setShowModal,
  warehouseCountryId,
  setShowResponseMessage,
  setMessage,
  setSeverity,
}) => {
  const [formData, setFormData] = useState({
    warehouseCountryId: "",
    "file[]": "",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { refetch, setRefetch } = useAppContext();

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      warehouseCountryId: warehouseCountryId,
    }));
  }, [warehouseCountryId]);

  const handleFileChange = (e) => {
    const newFiles = e.target.files;

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": [...prevFormData["file[]"], ...Array.from(newFiles)],
    }));
  };

  const removeFile = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": prevFormData["file[]"].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "file[]") {
        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(
        uploadProcessingGroupFile(formDataToSend)
      );
      if (response.payload.type === "success") {
        setRefetch(!refetch);
        setFormData((prev) => ({
          ...prev,
          "file[]": "",
        }));
        setShowModal(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("success");
      }

      if (response?.error?.message === "Rejected") {
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.upload_file")}
          onClick={() => setShowModal(false)}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <UploadFile onChange={handleFileChange} />

            {formData["file[]"].length > 0 && (
              <div className="mt-4 p-3 border rounded-lg bg-gray-100">
                <ul className="space-y-2">
                  {formData["file[]"].map((file, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between gap-3"
                    >
                      {file.type.startsWith("image/") ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          className="w-12 h-12 object-cover rounded-lg"
                        />
                      ) : (
                        <span className="text-gray-700 text-xs md:text-sm">
                          {file.name}
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="text-red-500 text-sm font-semibold hover:text-red-700"
                      >
                        <DeleteSvg />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.add")}</ButtonDefault>
      </div>
    </form>
  );
};

export default UploadProcessingFile;
