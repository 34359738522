import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics } from "../../features/acquisitions/AnalyticsSlice";
import { useAppContext } from "../../libs/AppContext";
import Headline from "../../components/common/shortList/Headline";
import CustomLink from "../../components/acquisitions/CustomLink";
import registerUsers from "../../assets/reg-users.svg";
import registerAgents from "../../assets/reg-agents.svg";
import ActiveUserAgents from "../../components/acquisitions/ActiveUserAgents";
import UsersAgentsBonus from "../../components/acquisitions/UsersAgentsBonus";
import ActiveUsers from "../../components/acquisitions/ActiveUsers";
import UsersBonus from "../../components/acquisitions/UsersBonus";
import useLinkToNavigate from "../../libs/useLinkToNavigate";

const Acquisitions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const linkToNavigate = useLinkToNavigate();
  const { isPermission } = useAppContext();
  const { analytics } = useSelector((state) => state.referralAnalytics);

  useEffect(() => {
    dispatch(getAnalytics());
  }, [dispatch]);

  return (
    <div>
      <Headline text={t("text.Acquisitions")} linkUrl={linkToNavigate} />
      <div className="w-full flex flex-col items-center p-4 gap-4 lg:p-12">
        <div className="grid grid-cols-2 gap-4 w-full lg:gap-5 lg:w-2/3 ">
          {isPermission.seeReferralUser?.action === "seeReferralUser" ||
          isPermission.seeAllReferralUser?.action === "seeAllReferralUser" ? (
            <CustomLink
              text={t("text.registered_users")}
              image={registerUsers}
              url="/acquisitions/users"
            />
          ) : (
            ""
          )}

          {isPermission.seeReferralAgent?.action === "seeReferralAgent" ||
          isPermission.seeAllReferralAgent?.action === "seeAllReferralAgent" ? (
            <CustomLink
              text={t("text.registered_agents")}
              image={registerAgents}
              url="/acquisitions/agents"
            />
          ) : (
            ""
          )}
        </div>
        <div className="grid grid-cols-1 gap-4 w-full lg:gap-5 lg:w-2/3 md:grid-cols-2">
          {analytics &&
          analytics.activeUsersCount &&
          analytics.activeAgentsCount ? (
            <ActiveUserAgents />
          ) : (
            ""
          )}
          {analytics && analytics.usersBonusSum && analytics.agentsBonusSum ? (
            <UsersAgentsBonus />
          ) : (
            ""
          )}
        </div>
        <div className="grid grid-cols-1 gap-4 w-full lg:gap-5 lg:w-2/3 md:grid-cols-2">
          {analytics && analytics.activeUsersCount ? <ActiveUsers /> : ""}
          {analytics && analytics.usersBonusSum ? <UsersBonus /> : ""}
        </div>
      </div>
    </div>
  );
};

export default Acquisitions;
