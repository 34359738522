import { Outlet, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/AppContext";
import { RxHamburgerMenu } from "react-icons/rx";
import Sidebar from "../components/navigation/Sidebar";

const MainLayout = () => {
  const { handleMobileMenu } = useAppContext();
  const location = useLocation();

  return (
    // <div className="w-full min-h-screen h-full relative overflow-x-hidden lg:grid lg:grid-cols-[265px_auto] xl:grid-cols-[350px_auto]">
    //   {location.pathname === "/MainPage" && (
    //     <div className="flex justify-end p-4 bg-gray-100 w-full lg:hidden">
    //       <button
    //         className="flex z-20 cursor-pointer text-2xl lg:hidden"
    //         type="button"
    //         onClick={handleMobileMenu}
    //       >
    //         <RxHamburgerMenu />
    //       </button>
    //     </div>
    //   )}

    //   <Sidebar />
    //   <Outlet />
    // </div>
    <div className="relative">
      <Sidebar />
      <div className="w-full min-h-screen h-full relative overflow-x-hidden lg:pl-64 xl:pl-80">
        {location.pathname === "/MainPage" && (
          <div className="flex justify-end p-4 bg-gray-100 w-full lg:hidden">
            <button
              className="flex z-20 cursor-pointer text-2xl lg:hidden"
              type="button"
              onClick={handleMobileMenu}
            >
              <RxHamburgerMenu />
            </button>
          </div>
        )}

        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
