import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../libs/AppContext";
import { MdDelete, MdAdd } from "react-icons/md";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { registerCompany } from "../../../features/company/CompanySlice";
import SelectToSetLarge from "../../common/shortList/SelectToSetLarge";
import Checkbox from "../../common/Checkbox";
import useCompanyConfigs from "../../formConfigs/company";
import ModalsHeadline from "../components/ModalsHeadline";
import ButtonDefault from "../../buttons/ButtonDefault";
import Input from "../../common/Input";
import UploadFileSmall from "../../common/UploadFileSmall";
import UploadedFileComponent from "../../common/UploadedFileComponent";

const AddCompany = ({ setShowResponseMessage, setMessage, setSeverity }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    inn: "",
    countryId: "",
    cityName: "",
    districtName: "",
    address: "",
    phone: "",
    invoiceNote: "",
    "logo[]": "",
    "ring[]": "",
    defaultFlag: "N",
    banks: [
      {
        bankId: "",
        bankAccount: "",
        bankCcy: "",
      },
    ],
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    inn: "",
    countryId: "",
    cityName: "",
    districtName: "",
    address: "",
    phone: "",
    invoiceNote: "",
    "logo[]": "",
    "ring[]": "",
    defaultFlag: "",
    banks: [
      {
        bankId: "",
        bankAccount: "",
        bankCcy: "",
      },
    ],
  });
  const dispatch = useDispatch();
  const { defaultFlag } = formData;
  const { formFields } = useCompanyConfigs();
  const { handleShowMainModal, setRefetch, setShowMainModal } = useAppContext();
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e, fieldName) => {
    const files = Array.from(e.target.files);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));

    setFormData((prev) => ({
      ...prev,
      [fieldName]: files,
    }));
  };

  const handleDynamicChange = (fieldName, index, fieldValue, subField) => {
    setFormData((prev) => {
      const updatedData = { ...prev };
      updatedData[fieldName][index] = {
        ...updatedData[fieldName][index],
        [subField]: fieldValue,
      };
      return updatedData;
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[fieldName] && updatedErrors[fieldName][index]) {
        delete updatedErrors[fieldName][index][subField];
      }
      return updatedErrors;
    });
  };

  const handleAddBank = () => {
    setFormData((prev) => ({
      ...prev,
      banks: [
        ...prev.banks,
        {
          bankId: "",
          bankAccount: "",
          bankCcy: "",
        },
      ],
    }));
  };

  const handleRemoveBank = (index) => {
    const updatedBanks = formData.banks.filter((_, idx) => idx !== index);
    setFormData((prev) => ({
      ...prev,
      banks: updatedBanks,
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      defaultFlag: defaultFlag === "N" ? "Y" : "N",
    }));
  };

  const handleOpenFile = (file) => {
    if (file.type.startsWith("image/")) {
      window.open(URL.createObjectURL(file), "_blank");
    } else {
      alert("File type not supported for preview");
    }
  };

  const handleDeleteFile = (index, fieldName) => {
    const updatedFiles = [...formData[fieldName]];
    updatedFiles.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: updatedFiles,
    }));
  };

  const renderInputFields = () => {
    return formFields.map((field, index) => {
      const errorMessage = errors[field.name];

      switch (field.type) {
        case "input":
          return (
            <div key={index} className="relative">
              <Input
                text={t(field.label)}
                name={field.name}
                value={formData[field.name]}
                onchange={handleInputChange}
                error={errorMessage}
                errormark={errorMessage ? "error-border" : ""}
              />
              {errorMessage && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {t(errorMessage)}
                </p>
              )}
            </div>
          );
        case "select":
          return (
            <div key={index} className="relative">
              <SelectToSetLarge
                options={field.options}
                text={t(field.label)}
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                error={errorMessage}
                errormark={errorMessage ? "error-border" : ""}
              />
              {errorMessage && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {t(errorMessage)}
                </p>
              )}
            </div>
          );
        case "file":
          return (
            <div key={index} className="relative">
              <label className="block mb-2 text-sm font-semibold">
                {t(field.label)}
              </label>
              <UploadFileSmall
                onChange={(e) => handleFileChange(e, field.name)}
              />
              <div className="mt-2 flex flex-col items-start gap-2">
                {Array.isArray(formData[field.name]) &&
                  formData[field.name].map((file, index) => (
                    <UploadedFileComponent
                      openFile={() => handleOpenFile(file)}
                      deleteFile={() => handleDeleteFile(index, field.name)}
                      file={file}
                    />
                  ))}
              </div>

              {errorMessage && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {t(errorMessage)}
                </p>
              )}
            </div>
          );
        case "checkbox":
          return (
            <div key={index} className="flex flex-col items-center relative">
              <div className="flex justify-start items-center gap-2 mb-2">
                <Checkbox
                  checked={formData[field.name] === "Y"}
                  onChange={handleCheckboxChange}
                />
                <label className="text-sm">{t(field.label)}</label>
              </div>
              {errorMessage && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {t(errorMessage)}
                </p>
              )}
            </div>
          );

        case "dynamic-array":
          return (
            <div
              key={index}
              className="relative col-span-3 bg-gray-100 border border-gray-200 px-4 py-8 rounded-xl flex flex-col items-start gap-4 w-full"
            >
              <label className="block mb-2 text-sm font-semibold">
                {t(field.label)}
              </label>
              {formData[field.name] &&
                formData[field.name].map((item, idx) => (
                  <div key={idx} className="flex gap-4 w-full items-center">
                    {field.fields.map((subField, subIndex) => {
                      const subErrorMessage =
                        errors[field.name]?.[idx]?.[subField.name];
                      return (
                        <div key={subIndex} className="w-full">
                          {subField.type === "select" && (
                            <div className="relative">
                              <SelectToSetLarge
                                text={t(subField.label)}
                                name={`${field.name}[${idx}].${subField.name}`}
                                value={item[subField.name] || ""}
                                onChange={(e) =>
                                  handleDynamicChange(
                                    field.name,
                                    idx,
                                    e.target.value,
                                    subField.name
                                  )
                                }
                                options={subField.options}
                                error={subErrorMessage}
                                errormark={
                                  subErrorMessage ? "error-border" : ""
                                }
                              />
                              {subErrorMessage && (
                                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                                  {t(subErrorMessage)}
                                </p>
                              )}
                            </div>
                          )}
                          {subField.type === "input" && (
                            <div className="relative">
                              <Input
                                text={t(subField.label)}
                                name={`${field.name}[${idx}].${subField.name}`}
                                value={item[subField.name] || ""}
                                onchange={(e) =>
                                  handleDynamicChange(
                                    field.name,
                                    idx,
                                    e.target.value,
                                    subField.name
                                  )
                                }
                                error={subErrorMessage}
                                errormark={
                                  subErrorMessage ? "error-border" : ""
                                }
                              />
                              {subErrorMessage && (
                                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                                  {t(subErrorMessage)}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <Tooltip title={t("text.delete")}>
                      <button
                        type="button"
                        onClick={() => handleRemoveBank(idx)}
                        className="text-red-500 text-xl min-w-8 w-8 h-8 rounded-full flex justify-center items-center transition-all duration-300 hover:bg-gray-200"
                      >
                        <MdDelete />
                      </button>
                    </Tooltip>
                  </div>
                ))}

              <Tooltip title={t("text.add")}>
                <button
                  type="button"
                  onClick={handleAddBank}
                  className="text-blue-500 text-2xl w-8 h-8 rounded-full flex justify-center items-center transition-all duration-300 hover:bg-gray-200"
                >
                  <MdAdd />
                </button>
              </Tooltip>
            </div>
          );

        default:
          return null;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.fullName ||
      !formData.email ||
      !formData.inn ||
      !formData.countryId ||
      !formData.cityName ||
      !formData.districtName ||
      !formData.address ||
      !formData.phone ||
      !formData.invoiceNote ||
      formData["logo[]"].length === 0 ||
      formData["ring[]"].length === 0 ||
      !formData.banks[0].bankId ||
      !formData.banks[0].bankAccount ||
      !formData.banks[0].bankCcy
    ) {
      setErrors((prev) => ({
        ...prev,
        fullName: formData.fullName ? "" : t("text.field_required"),
        email: formData.email ? "" : t("text.field_required"),
        inn: formData.inn ? "" : t("text.field_required"),
        countryId: formData.countryId ? "" : t("text.field_required"),
        cityName: formData.cityName ? "" : t("text.field_required"),
        districtName: formData.districtName ? "" : t("text.field_required"),
        address: formData.address ? "" : t("text.field_required"),
        phone: formData.phone ? "" : t("text.field_required"),
        invoiceNote: formData.invoiceNote ? "" : t("text.field_required"),
        "logo[]": formData["logo[]"].length > 0 ? "" : t("text.field_required"),
        "ring[]": formData["ring[]"].length > 0 ? "" : t("text.field_required"),
        banks: [
          {
            bankId: formData.banks[0]?.bankId ? "" : t("text.field_required"),
            bankAccount: formData.banks[0]?.bankAccount
              ? ""
              : t("text.field_required"),
            bankCcy: formData.banks[0]?.bankCcy ? "" : t("text.field_required"),
          },
        ],
      }));
      return;
    }
    const formattedBanks = formData.banks.map((bank) => ({
      bankId: bank.bankId,
      bankAccount: bank.bankAccount,
      bankCcy: bank.bankCcy,
    }));

    const formDataToSend = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "banks") {
        formDataToSend.append(key, JSON.stringify(formattedBanks));
      } else if (key === "logo[]") {
        value.forEach((file) => {
          formDataToSend.append("logo[]", file);
        });
      } else if (key === "ring[]") {
        value.forEach((file) => {
          formDataToSend.append("ring[]", file);
        });
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(registerCompany(formDataToSend));

      if (response.payload && response.payload.type === "success") {
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        setRefetch((prev) => !prev);
        setShowMainModal(false);
        setFormData({
          fullName: "",
          email: "",
          inn: "",
          countryId: "",
          cityName: "",
          districtName: "",
          address: "",
          phone: "",
          invoiceNote: "",
          "logo[]": [],
          "ring[]": [],
          defaultFlag: "N",
          banks: [
            {
              bankId: "",
              bankAccount: "",
              bankCcy: "",
            },
          ],
        });
      }

      if (response.error && response.error.message === "Rejected") {
        setSeverity("error");
        setShowResponseMessage(true);
        setMessage(response.payload.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.add_company")}
          onClick={handleShowMainModal}
        />
        <div className="p-6 w-full flex flex-col gap-4 lg:gap-6 lg:grid lg:grid-cols-3">
          {renderInputFields()}
        </div>
      </div>
      <div className="px-6 pb-24 pt-6">
        <ButtonDefault>{t("text.add")}</ButtonDefault>
      </div>
    </form>
  );
};

export default AddCompany;
