import { useTranslation } from "react-i18next";
import ProgressBarElement from "./ProgressBarElement";

const ProgressBar = ({
  requestsCount,
  withdrawParcelsCount,
  errorParcelsCount,
}) => {
  const withdrawPercentage = (requestsCount / requestsCount) * 100;
  const errorPercentage = (errorParcelsCount / requestsCount) * 100;
  const successfullyPercentage = (withdrawParcelsCount / requestsCount) * 100;

  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col items-start gap-4 my-4">
      <ProgressBarElement
        label={t("text.all")}
        number={requestsCount}
        value={withdrawPercentage}
        backgroundcolor="#38c8c848"
        linecolor="rgba(56, 199, 200, 1)"
      />
      <ProgressBarElement
        label={t("text.successfully_issued")}
        number={withdrawParcelsCount}
        value={successfullyPercentage}
        backgroundcolor="#a083ea51"
        linecolor="rgba(161, 131, 234, 1)"
      />
      <ProgressBarElement
        label={t("text.problem")}
        number={errorParcelsCount}
        value={errorPercentage}
        backgroundcolor="#fc56b743"
        linecolor="rgba(252, 86, 182, 1)"
      />
    </div>
  );
};

export default ProgressBar;
