import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { ExportFileSvg } from "../../icons";
import Checkbox from "../../common/Checkbox";
import Pagination from "../../common/Pagination";
import Spinner from "../../common/Spinner";
import Message from "../../common/Message";
import CheckAll from "../../common/CheckAll";
import TableSearch from "../../common/TableSearch";
import DatePickerComponent from "../../common/DatePickerComponent";
import TableSearchSelect from "../../common/TableSearchSelect";
import { useTranslation } from "react-i18next";

const RacesProgressTable = ({
  data,
  handlePageClick,
  currentPage,
  onNextPage,
  onPrevPage,
  handlePerPage,
  perPage,
  formData,
  setFormData,
}) => {
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const { isLoading } = useSelector((state) => state.racesProgress);
  const { countries } = useSelector((state) => state.racesProgress);
  const recordsNumber = useSelector(
    (state) => state.racesProgress?.racesProgressDetails?.recordsNumber
  );
  const { t } = useTranslation();

  const columnkeymappings = {
    [t("text.user")]: "userName",
    [t("text.room_number")]: "roomNumber",
    [t("text.TDS_code")]: "tdsCode",
    [t("text.warehouse_date")]: "warehouseDate",
    [t("text.send_date")]: "sendDate",
    [t("text.date_of_arrival")]: "arrivedDate",
    [t("text.country")]: "countryCode",
  };

  useEffect(() => {
    const name = countries?.find((item) => item.code === formData?.countryCode);
    setSelectedCountryName(name?.countryDictionaryKey);
  }, [selectedCountryName, formData, countries]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20 no-scrollbar overflow-x-scroll">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {Object.keys(columnkeymappings).map((key) => (
                  <th
                    key={key}
                    className="max-w-5 text-left border-b font-medium min-w-26"
                  >
                    <div className="relative p-4 w-full border-r border-black/5">
                      {(key === `${t("text.user")}` ||
                        key === `${t("text.room_number")}` ||
                        key === `${t("text.TDS_code")}`) && (
                        <>
                          <span className="block truncate pr-2"> {key}</span>
                          <TableSearch
                            formData={formData}
                            setFormData={setFormData}
                            searchKey={columnkeymappings[key]}
                          />
                        </>
                      )}
                      {key === `${t("text.warehouse_date")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <DatePickerComponent
                            value={{
                              startDate: formData.warehouseStartDate,
                              endDate: formData.warehouseEndDate,
                            }}
                            onChange={(newValue) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                warehouseStartDate: newValue.startDate,
                                warehouseEndDate: newValue.endDate,
                              }));
                            }}
                          />
                        </div>
                      )}
                      {key === `${t("text.send_date")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <DatePickerComponent
                            value={{
                              startDate: formData.sendStartDate,
                              endDate: formData.sendEndDate,
                            }}
                            onChange={(newValue) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                sendStartDate: newValue.startDate,
                                sendEndDate: newValue.endDate,
                              }));
                            }}
                          />
                        </div>
                      )}
                      {key === `${t("text.date_of_arrival")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <DatePickerComponent
                            value={{
                              startDate: formData.arrivedStartDate,
                              endDate: formData.arrivedEndDate,
                            }}
                            onChange={(newValue) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                arrivedStartDate: newValue.startDate,
                                arrivedEndDate: newValue.endDate,
                              }));
                            }}
                          />
                        </div>
                      )}
                      {key === `${t("text.country")}` && (
                        <div className="relative flex justify-between items-center gap-2">
                          <span className="truncate">{key}</span>
                          <TableSearchSelect
                            setFormData={setFormData}
                            formData={formData}
                            data={countries}
                            searchKey={columnkeymappings[key]}
                            selectedValue={selectedCountryName}
                            translationKey="countryDictionaryKey"
                            valueKey="code"
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20 min-w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-black/5 border-b"
                  >
                    <td className="py-2 px-3 border-r border-black/5">
                      <Checkbox />
                    </td>
                    {Object.keys(columnkeymappings).map((key) => {
                      const dataKey = columnkeymappings[key];
                      return (
                        <td
                          key={key}
                          className="max-w-5 text-xs text-blue-4 font-medium"
                        >
                          <div className="py-2 px-3 w-full truncate border-r border-black/5">
                            <Tooltip title={row[dataKey]}>
                              <span>{row[dataKey]}</span>
                            </Tooltip>
                          </div>
                        </td>
                      );
                    })}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <div className="flex gap-2">
                        <Tooltip title={t("text.export")}>
                          <span>
                            <ExportFileSvg />
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={Object.keys(columnkeymappings).length + 2}
                    className="border-none p-2"
                  >
                    <Message text={t("text.not_found")} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {recordsNumber > 1 && (
            <Pagination
              currentPage={currentPage}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              handlePerPage={handlePerPage}
              perPage={perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RacesProgressTable;
