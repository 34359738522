import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useParcelTrackingConfig from "../../../tableConfigs/parcelTracking";
import LargeTable from "../../tables/LargeTable";
import Pagination from "../../common/Pagination";
import Spinner from "../../common/Spinner";

const ParcelTrackingTable = ({
  parcels,
  formData,
  setFormData,
  setLastParcels,
  setSelectedImage,
}) => {
  const [recordsNumber, setRecordsNumber] = useState("");
  const { trackingParcels, isLoading } = useSelector(
    (state) => state.trackingParcels
  );
  const tableConfig = useParcelTrackingConfig(
    formData,
    setFormData,
    setSelectedImage
  );

  useEffect(() => {
    setRecordsNumber(trackingParcels.recordsNumber);
  }, [trackingParcels.recordsNumber]);

  const handlePageIncrement = () => {
    setLastParcels([]);
    setFormData((prevData) => ({ ...prevData, page: formData.page + 1 }));
  };

  const handlePageDecrement = () => {
    setLastParcels([]);
    if (formData.page > 1) {
      setFormData((prevData) => ({ ...prevData, page: formData.page - 1 }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setLastParcels([]);
    setFormData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setLastParcels([]);
    setFormData((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <div className="relative h-[calc(100vh-175px)]">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-175px)]">
          <LargeTable
            config={tableConfig}
            data={parcels}
            formData={formData}
            setFormData={setFormData}
          />
          {recordsNumber > 1 && (
            <Pagination
              currentPage={formData.page}
              onNextPage={handlePageIncrement}
              onPrevPage={handlePageDecrement}
              handlePerPage={handlePerPage}
              perPage={formData.perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ParcelTrackingTable;
