import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatuses } from "../../../features/processingParcelGroups/StatusesSlice";
import Modal from "../../common/Modal";
import MobileFilter from "../../tabelMobileFilters/MobileFilter";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
import LargeTable from "../../tables/LargeTable";
import useProcessingDetalsConfig from "../../../tableConfigs/processingDetails";

const ProcessingDetailsTabe = ({
  detailsData,
  setDetailsData,
  setSelectedImage,
  handleShowEditParcel,
  handleDeleteConfirmation,
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [recordsNumber, setRecordsNumber] = useState("");
  const { isLoading, processingParcelGroupDetails } = useSelector(
    (state) => state.processingDetails
  );
  const dispatch = useDispatch();

  const tableConfig = useProcessingDetalsConfig(
    detailsData,
    setDetailsData,
    setSelectedImage,
    handleShowEditParcel,
    handleDeleteConfirmation
  );

  useEffect(() => {
    dispatch(getStatuses());
  }, [dispatch]);

  useEffect(() => {
    setRecordsNumber(processingParcelGroupDetails.recordsNumber);
  }, [processingParcelGroupDetails.recordsNumber]);

  const handlePageIncrement = () => {
    setDetailsData((prevData) => ({ ...prevData, page: detailsData.page + 1 }));
  };

  const handlePageDecrement = () => {
    if (detailsData.page > 1) {
      setDetailsData((prevData) => ({
        ...prevData,
        page: detailsData.page - 1,
      }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setDetailsData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setDetailsData((prevData) => ({ ...prevData, perPage: e }));
  };
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
          <Modal
            showmodal={showMobileFilters}
            setShowModal={setShowMobileFilters}
          >
            <MobileFilter
              columns={tableConfig.columns}
              setShowModal={setShowMobileFilters}
              formData={detailsData}
              setFormData={setDetailsData}
            />
          </Modal>
          <LargeTable
            config={tableConfig}
            data={processingParcelGroupDetails?.parcels}
            formData={detailsData}
            setFormData={setDetailsData}
            handleShowFilters={() => setShowMobileFilters((prev) => !prev)}
          />
          {recordsNumber > 1 && (
            <Pagination
              currentPage={detailsData.page}
              onNextPage={handlePageIncrement}
              onPrevPage={handlePageDecrement}
              handlePerPage={handlePerPage}
              perPage={detailsData.perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ProcessingDetailsTabe;
