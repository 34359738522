import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BarcodeModal from "../../modals/groups/BarcodeModal";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
import useParcelGroupsConfig from "../../../tableConfigs/parcelGroups";
import MobileFilter from "../../tabelMobileFilters/MobileFilter";
import Modal from "../../common/Modal";
import LargeTable from "../../tables/LargeTable";

const ParcelGroupsTable = ({
  groupsIds,
  handleShowEditGroup,
  selectedRows,
  setSelectedRows,
  setGroupsIds,
  parcelgroupsdata,
  setparcelgroupsdata,
  handleTransferExcel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [barcodeImageValue, setBarcodeImageValue] = useState("");
  const [recordsNumber, setRecordsNumber] = useState("");
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const { groups, isLoading, isSuccess } = useSelector(
    (state) => state.parcelGroups
  );

  const tableConfig = useParcelGroupsConfig(
    parcelgroupsdata,
    setparcelgroupsdata,
    handleShowEditGroup,
    showModal,
    setShowModal,
    setBarcodeImageValue,
    groupsIds,
    groups,
    selectedRows,
    setSelectedRows,
    setGroupsIds,
    handleTransferExcel
  );

  useEffect(() => {
    setRecordsNumber(groups?.recordsNumber);
  }, [groups?.recordsNumber]);

  useEffect(() => {
    setGroupsIds(selectedRows);
  }, [selectedRows, setGroupsIds]);

  const handlePageIncrement = () => {
    setparcelgroupsdata((prevData) => ({
      ...prevData,
      page: parcelgroupsdata.page + 1,
    }));
  };

  const handlePageDecrement = () => {
    if (parcelgroupsdata.page > 1) {
      setparcelgroupsdata((prevData) => ({
        ...prevData,
        page: parcelgroupsdata.page - 1,
      }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setparcelgroupsdata((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setparcelgroupsdata((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isSuccess && (
            <div className="w-full flex flex-col justify-between 2xl:gap-20 xl:h-[calc(100vh-79px)] overflow-x-scroll">
              <Modal
                showmodal={showMobileFilters}
                setShowModal={setShowMobileFilters}
              >
                <MobileFilter
                  columns={tableConfig.columns}
                  setShowModal={setShowMobileFilters}
                  formData={parcelgroupsdata}
                  setFormData={setparcelgroupsdata}
                />
              </Modal>
              <LargeTable
                config={tableConfig}
                data={groups.parcelGroups}
                formData={parcelgroupsdata}
                setFormData={setparcelgroupsdata}
                handleShowFilters={() => setShowMobileFilters((prev) => !prev)}
              />
              {recordsNumber > 1 && (
                <Pagination
                  recordsNumber={recordsNumber}
                  currentPage={parcelgroupsdata.page}
                  perPage={parcelgroupsdata.perPage}
                  onNextPage={handlePageIncrement}
                  onPrevPage={handlePageDecrement}
                  handlePerPage={handlePerPage}
                  handlePageClick={handlePageClick}
                />
              )}
            </div>
          )}
          {showModal && (
            <BarcodeModal
              setShowModal={setShowModal}
              setBarcodeImageValue={setBarcodeImageValue}
              barcodeImageValue={barcodeImageValue}
            />
          )}
        </>
      )}
    </>
  );
};

export default ParcelGroupsTable;
