import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTrackingParcels } from "../../features/parcelTracking/ParcelTrackingSlice";
import { Howl } from "howler";
import Headline from "../../components/common/shortList/Headline";
import Statuses from "../../components/parcelTracking/Statuses";
import ParcelTrackingTable from "../../components/table/parcelTracking/ParcelTrackingTable";
import WebSocketConnection from "../../WebSocketConnection";
import errorSound from "../../sounds/error.mp3";
import useLinkToNavigate from "../../libs/useLinkToNavigate";

import declaredEn1 from "../../sounds/declared-en-1.mp3";
import declaredEn2 from "../../sounds/declared-en-2.mp3";
import declaredEn3 from "../../sounds/declared-en-3.mp3";
import declaredEn4 from "../../sounds/declared-en-4.mp3";

import notDeclaredEn1 from "../../sounds/not-declared-en-1.mp3";
import notDeclaredEn2 from "../../sounds/not-declared-en-2.mp3";
import notDeclaredEn3 from "../../sounds/not-declared-en-3.mp3";
import notDeclaredEn4 from "../../sounds/not-declared-en-4.mp3";

import declaredGe1 from "../../sounds/declared-ge-1.mp3";
import declaredGe2 from "../../sounds/declared-ge-2.mp3";

import notDeclaredGe1 from "../../sounds/not-declared-ge-1.mp3";
import notDeclaredGe2 from "../../sounds/not-declared-ge-2.mp3";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const ParcelTracking = () => {
  const [formData, setFormData] = useState({
    tdsCode: "",
    roomNumber: "",
    declared: "",
    page: 1,
    perPage: 10,
  });
  const [parcels, setParcels] = useState([]);
  const [audioContext, setAudioContext] = useState(null);
  const [lastParcels, setLastParcels] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [message, setMessage] = useState("");
  const [statuses, setStatuses] = useState({
    warning: false,
    success: false,
    error: false,
  });
  const linkToNavigate = useLinkToNavigate();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tdsCode, roomNumber, declared, page, perPage } = formData;
  const { trackingParcels } = useSelector((state) => state.trackingParcels);

  const getRandomSound = (language, type) => {
    const englishDeclaredSounds = [
      declaredEn1,
      declaredEn2,
      declaredEn3,
      declaredEn4,
    ];
    const englishNotDeclaredSounds = [
      notDeclaredEn1,
      notDeclaredEn2,
      notDeclaredEn3,
      notDeclaredEn4,
    ];
    const georgianDeclaredSounds = [declaredGe1, declaredGe2];
    const georgianNotDeclaredSounds = [notDeclaredGe1, notDeclaredGe2];

    if (language === "EN") {
      if (type === "declared") {
        return englishDeclaredSounds[
          Math.floor(Math.random() * englishDeclaredSounds.length)
        ];
      } else if (type === "notDeclared") {
        return englishNotDeclaredSounds[
          Math.floor(Math.random() * englishNotDeclaredSounds.length)
        ];
      }
    } else if (language === "GE") {
      if (type === "declared") {
        return georgianDeclaredSounds[
          Math.floor(Math.random() * georgianDeclaredSounds.length)
        ];
      } else if (type === "notDeclared") {
        return georgianNotDeclaredSounds[
          Math.floor(Math.random() * georgianNotDeclaredSounds.length)
        ];
      }
    }

    return errorSound;
  };

  useEffect(() => {
    const enableAudio = () => {
      if (!audioContext) {
        const newAudioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        setAudioContext(newAudioContext);
      }
    };

    document.body.addEventListener("click", enableAudio, { once: true });

    return () => {
      document.body.removeEventListener("click", enableAudio);
    };
  }, [audioContext]);

  useEffect(() => {
    const language =
      JSON.parse(localStorage.getItem("language"))?.code?.toUpperCase() || "EN";

    if (statuses.success) {
      const successSound = getRandomSound(language, "declared");
      const playSuccess = new Howl({ src: [successSound], volume: 1 });
      playSuccess.play();
    } else if (statuses.warning) {
      const warningSound = getRandomSound(language, "notDeclared");
      const playWarning = new Howl({ src: [warningSound], volume: 1 });
      playWarning.play();
    } else if (statuses.error) {
      const playError = new Howl({ src: [errorSound], volume: 1 });
      playError.play();
    }
  }, [statuses]);

  useEffect(() => {
    dispatch(
      getTrackingParcels({ tdsCode, roomNumber, declared, page, perPage })
    );
  }, [dispatch, tdsCode, roomNumber, declared, page, perPage]);

  useEffect(() => {
    if (trackingParcels && trackingParcels.scanParcel) {
      setParcels(() => [...trackingParcels.scanParcel]);
    }
  }, [trackingParcels]);

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <Headline linkUrl={linkToNavigate} text={t("text.parcel_tracking")} />
      <Statuses statuses={statuses} message={message} />
      <ParcelTrackingTable
        parcels={lastParcels.length === 0 ? parcels : lastParcels}
        formData={formData}
        setFormData={setFormData}
        setParcels={setParcels}
        setLastParcels={setLastParcels}
        setSelectedImage={setSelectedImage}
      />
      {selectedImage && (
        <div className="fixed inset-0 z-50">
          <div
            className="w-full h-full bg-black/20"
            onClick={handleCloseImage}
          ></div>
          <div className="absolute max-w-6xxl w-full p-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
            <TransformWrapper disablePadding="true">
              <TransformComponent>
                <img className="w-full" src={selectedImage} alt="Selected" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
      <WebSocketConnection
        setParcels={setParcels}
        setStatuses={setStatuses}
        setLastParcels={setLastParcels}
        setMessage={setMessage}
      />
    </div>
  );
};

export default ParcelTracking;
