import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPeriods } from "../../../features/dashboard/periods/PeriodsSlice";
import SatisfactionScore from "../../../components/dashboard/SatisfactionScore";
import ResponseTime from "../../../components/dashboard/ResponseTime";
import DeliveryTime from "../../../components/dashboard/DeliveryTime";
import RacesProgress from "../../../components/dashboard/RacesProgress";
import IssuedParcels from "../../../components/dashboard/IssuedParcels";
import ShelfParcels from "../../../components/dashboard/ShelfParcels";

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPeriods());
  }, [dispatch]);

  return (
    <>
      <div className="h-full w-full bg-white-1 p-6 flex flex-col items-center gap-6">
        <div className="w-full flex justify-between gap-6 flex-col lg:flex-row">
          <div className="w-full grid gap-6">
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
              <ResponseTime />
              <DeliveryTime />
            </div>
            <RacesProgress />
          </div>
          <SatisfactionScore />
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
          <IssuedParcels />
          <ShelfParcels />
        </div>
      </div>
    </>
  );
};

export default MainPage;
