const ButtonDefault = (props) => {
  const { onClick, children, type } = props;
  return (
    <button
      type={type}
      onClick={onClick}
      className="px-4 py-2.5 w-full text-xs font-medium text-blue-2 border border-blue-240 rounded text-center cursor-pointer transition-all duration-500 ease-in-out bg-blue-2/30 capitalize md:py-4 hover:bg-transparent"
    >
      {children}
    </button>
  );
};

export default ButtonDefault;
