import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { ExportFileSvg2 } from "../../icons";
// import { Tooltip } from "@mui/material";
import Headline from "../../common/shortList/Headline";
import ListBoxSmall from "../../common/shortList/ListBoxSmall";

const ProcessingParcelGroupsPropertiesBar = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  //   const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.parcelCountries);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Headline linkUrl="/MainPage" text={t("text.processing_parcel_groups")}>
      <div className="p-3 lg:p-0 flex items-center gap-4">
        {/* {formData.warehouseCountryId && (
          <Tooltip text={t("text.excel")}>
            <button type="button" onClick={handleExport}>
              <ExportFileSvg2 />
            </button>
          </Tooltip>
        )} */}

        <div className="flex items-center justify-end bg-white rounded-lg w-44 md:w-60">
          <ListBoxSmall
            options={countries?.map((cat) => ({
              value: cat.countryId,
              label: t(cat.countryDictionaryKey),
            }))}
            text={t("text.calculator_select_country")}
            name="warehouseCountryId"
            value={formData.warehouseCountryId}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </Headline>
  );
};

export default ProcessingParcelGroupsPropertiesBar;
