import axios from "axios";

const getCategories = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/delegation/get_categories`,
    config
  );

  return response.data;
};

const registerCategory = async (categoryData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/register_category`,
    categoryData,
    config
  );

  return response.data;
};

const updateCategory = async (categoryData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/change_category`,
    categoryData,
    config
  );

  return response.data;
};

const deleteCategory = async (categoryId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/delete_category`,
    categoryId,
    config
  );

  return response.data;
};

const categoriesService = {
  getCategories,
  registerCategory,
  updateCategory,
  deleteCategory,
};

export default categoriesService;
