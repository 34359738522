import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changeUser } from "../../../features/acquisitions/users/UsersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../../libs/AppContext";
import ModalsHeadline from "../components/ModalsHeadline";
import Input from "../../common/Input";
import ButtonDefault from "../../buttons/ButtonDefault";

const EditUser = ({
  setMessage,
  setSeverity,
  setShowResponseMessage,
  setShowEditReferralUser,
  referralUserId,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    pinOrInn: "",
    mobile: "",
    referralTypeId: 2,
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    pinOrInn: "",
    mobile: "",
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { refetch, setRefetch } = useAppContext();
  const { users } = useSelector((state) => state.referralUsers);

  useEffect(() => {
    const user = users?.potentialUsers?.find(
      (user) => user.id === referralUserId
    );

    if (user) {
      setFormData((prev) => ({
        ...prev,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        pinOrInn: user.pinOrInn || "",
        mobile: user.mobile || "",
      }));
    }
  }, [referralUserId, users?.potentialUsers]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!referralUserId) {
      return;
    }

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.pinOrInn ||
      !formData.mobile
    ) {
      setErrors({
        firstName: formData.firstName ? "" : t("text.field_required"),
        lastName: formData.lastName ? "" : t("text.field_required"),
        pinOrInn: formData.pinOrInn ? "" : t("text.field_required"),
        mobile: formData.mobile ? "" : t("text.field_required"),
      });

      return;
    }

    const dataToSent = {
      referralUserId: referralUserId,
      referralTypeId: formData.referralTypeId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      pinOrInn: formData.pinOrInn,
      mobile: formData.mobile,
    };

    try {
      const response = await dispatch(changeUser(dataToSent));
      if (response.payload && response.payload.type === "success") {
        setRefetch(!refetch);
        setFormData((prev) => ({
          ...prev,
          firstName: "",
          lastName: "",
          pinOrInn: "",
          mobile: "",
        }));
        setShowEditReferralUser(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("success");
      }
      if (response.error && response?.error?.message === "Rejected") {
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.changeUser")}
          onClick={() => setShowEditReferralUser(false)}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.First_Name")}
              name="firstName"
              value={formData.firstName}
              onchange={handleInputChange}
              error={errors.firstName}
              errormark={errors.firstName ? "error-border" : ""}
            />
            {errors.firstName && (
              <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                {errors.firstName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.Last_Name")}
              name="lastName"
              value={formData.lastName}
              onchange={handleInputChange}
              error={errors.lastName}
              errormark={errors.lastName ? "error-border" : ""}
            />
            {errors.lastName && (
              <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                {errors.lastName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.inn")}
              name="pinOrInn"
              value={formData.pinOrInn}
              onchange={handleInputChange}
              error={errors.pinOrInn}
              errormark={errors.pinOrInn ? "error-border" : ""}
            />
            {errors.pinOrInn && (
              <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                {errors.pinOrInn}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.mobile")}
              name="mobile"
              value={formData.mobile}
              onchange={handleInputChange}
              error={errors.mobile}
              errormark={errors.mobile ? "error-border" : ""}
            />
            {errors.mobile && (
              <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                {errors.mobile}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="px-6 pb-24 pt-6">
        <ButtonDefault>{t("text.edit")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditUser;
