import { useSelector } from "react-redux";

const useCompanyConfigs = () => {
  const { countries } = useSelector((state) => state.countries);
  const { banks } = useSelector((state) => state.companies.banks);
  const countriesOptions = Array.isArray(countries)
    ? countries.map((country) => country)
    : [];

  return {
    formFields: [
      {
        name: "fullName",
        type: "input",
        label: "text.company_or_department_name",
      },
      {
        name: "email",
        type: "input",
        label: "text.email",
      },
      {
        name: "inn",
        type: "input",
        label: "text.inn",
      },
      {
        name: "countryId",
        type: "select",
        label: "text.country",
        options: countriesOptions?.map((cat) => ({
          value: cat.id,
          label: cat.name,
        })),
      },
      {
        name: "cityName",
        type: "input",
        label: "text.city",
      },
      {
        name: "districtName",
        type: "input",
        label: "text.district",
      },
      {
        name: "address",
        type: "input",
        label: "text.address",
      },
      {
        name: "phone",
        type: "input",
        label: "text.phone",
      },
      {
        name: "invoiceNote",
        type: "input",
        label: "text.invoice_standard_notes",
      },
      {
        name: "banks",
        type: "dynamic-array",
        label: "text.requisites",
        fields: [
          {
            name: "bankId",
            type: "select",
            label: "text.select_bank",
            options: banks?.map((cat) => ({
              value: cat.id,
              label: cat.label,
            })),
          },
          {
            name: "bankAccount",
            type: "input",
            label: "text.acctno",
          },
          {
            name: "bankCcy",
            type: "select",
            label: "text.project_invoice.ccy",
            options: [
              { value: "USD", label: "USD" },
              { value: "EUR", label: "EUR" },
              { value: "GEL", label: "GEL" },
              { value: "CNY", label: "CNY" },
            ],
          },
        ],
      },
      {
        name: "logo[]",
        type: "file",
        label: "text.upload_logo",
      },
      {
        name: "ring[]",
        type: "file",
        label: "text.company_stamp",
      },
      {
        name: "defaultFlag",
        type: "checkbox",
        label: "text.select_as_main_section",
      },
    ],
  };
};

export default useCompanyConfigs;
