import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "../../common/Modal";
import Pagination from "../../common/Pagination";
import Spinner from "../../common/Spinner";
import MobileFilter from "../../tabelMobileFilters/MobileFilter";
import LargeTable from "../../tables/LargeTable";
import useProcessingParcelsConfig from "../../../tableConfigs/processingParcels";

const ProcessingParcelGroupsTable = ({
  formData,
  setFormData,
  handleEditBarcode,
  handleUploadImages,
  handleProcessing,
  handleDeleteConfirmation,
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [recordsNumber, setRecordsNumber] = useState("");
  const { isLoading, processingParcels } = useSelector(
    (state) => state.processingParcels
  );

  const tableConfig = useProcessingParcelsConfig(
    formData,
    setFormData,
    handleEditBarcode,
    handleUploadImages,
    handleProcessing,
    handleDeleteConfirmation
  );

  useEffect(() => {
    setRecordsNumber(processingParcels.recordsNumber);
  }, [processingParcels.recordsNumber]);

  const handlePageIncrement = () => {
    setFormData((prevData) => ({ ...prevData, page: formData.page + 1 }));
  };

  const handlePageDecrement = () => {
    if (formData.page > 1) {
      setFormData((prevData) => ({ ...prevData, page: formData.page - 1 }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setFormData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setFormData((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
          <Modal
            showmodal={showMobileFilters}
            setShowModal={setShowMobileFilters}
          >
            <MobileFilter
              columns={tableConfig.columns}
              setShowModal={setShowMobileFilters}
              formData={formData}
              setFormData={setFormData}
            />
          </Modal>
          <LargeTable
            config={tableConfig}
            data={processingParcels?.processingParcelGroups}
            formData={formData}
            setFormData={setFormData}
            handleShowFilters={() => setShowMobileFilters((prev) => !prev)}
          />
          {recordsNumber > 1 && (
            <Pagination
              currentPage={formData.page}
              onNextPage={handlePageIncrement}
              onPrevPage={handlePageDecrement}
              handlePerPage={handlePerPage}
              perPage={formData.perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ProcessingParcelGroupsTable;
