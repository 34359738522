import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCountries } from "../../features/groups/countries/CountriesSlice";
import { getProviderParcels } from "../../features/providerParcels/ProviderParcelsSlice";
import ProviderParcelsPropertiesBar from "../../components/table/providerParcels/ProviderParcelsPropertiesBar";
import ProviderParcelsTable from "../../components/table/providerParcels/ProviderParcelsTable";

const ProviderParcels = () => {
  const [formData, setFormData] = useState({
    warehouseCountryId: "",
    tdsCode: "",
    roomNumber: "",
    page: 1,
    perPage: 10,
  });
  const { warehouseCountryId, tdsCode, roomNumber, page, perPage } = formData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (warehouseCountryId) {
      dispatch(
        getProviderParcels({
          warehouseCountryId,
          tdsCode,
          roomNumber,
          page,
          perPage,
        })
      );
    }
  }, [dispatch, warehouseCountryId, tdsCode, roomNumber, page, perPage]);

  return (
    <div>
      <ProviderParcelsPropertiesBar
        setFormData={setFormData}
        formData={formData}
      />
      <ProviderParcelsTable formData={formData} setFormData={setFormData} />
    </div>
  );
};

export default ProviderParcels;
