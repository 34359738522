import Highcharts from "highcharts";
import accessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import NoDataComponent from "./NoDataComponent";

accessibility(Highcharts);

const RacesProgressChart = ({ data }) => {
  const { t } = useTranslation();

  if (
    !data ||
    !data.current ||
    data.current.length === 0 ||
    !data.past ||
    data.past.length === 0
  ) {
    return <NoDataComponent />;
  }

  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const countries = data?.current?.map((item) =>
    capitalize(item.currentCountry)
  );
  const currentCounts = data?.current?.map((item) => item.count);
  const currentWeights = data?.current?.map((item) => item.weight);
  const pastCounts = data?.past?.map((item) => item.count);
  const colors = [
    "rgba(252, 86, 182, 1)",
    "rgba(218, 93, 221, 1)",
    "rgba(56, 199, 200, 1)",
    "rgba(161, 131, 234, 1)",
    "rgba(120, 164, 219, 1)",
    "rgba(56, 199, 200, 1)",
    "rgba(161, 131, 234, 1)",
    "rgba(56, 199, 200, 1)",
    "rgba(195, 97, 249, 1)",
    "rgba(56, 199, 200, 1)",
  ];

  const series = [
    {
      name: "Parcels",
      data: currentCounts.map((count, index) => ({
        y: count,
        color: colors[index % colors.length],
      })),
    },
  ];

  const options = {
    chart: {
      type: "column",
      height: 240,
      title: "",
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: 8,
        pointWidth: 16,
        cursor: "pointer",
        events: {
          click: function (event) {
            const point = event.point;
            const country = countries[point.index];
            window.location.href = `/racesProgress/${country}`;
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineWidth: 0,
      categories: countries,
      min: 0,
      labels: {
        style: {
          colors: colors,
          fontWeight: "600",
          fontSize: "10px",
        },
        formatter: function () {
          return (
            '<span style="color:' +
            colors[this.pos] +
            '">' +
            this.value +
            "</span>"
          );
        },
      },
    },
    yAxis: {
      type: "logarithmic",
      min: 1,
      labels: {
        style: {
          color: "rgba(134, 144, 201, 1)",
          fontWeight: "500",
          fontSize: "10px",
        },
      },
      title: {
        text: "",
      },
      gridLineWidth: 1,
      gridLineColor: "rgba(134, 144, 201, 0.49)",
      gridLineDashStyle: "Dash",
    },
    tooltip: {
      backgroundColor: "rgba(245, 245, 245, 1)",
      borderRadius: 4,
      borderWidth: 0,
      style: {
        fontSize: "12px",
        fontWeight: "16px",
        color: "rgba(0, 0, 0, 0.6)",
      },
      formatter: function () {
        const point = this.point;
        const count = point.y;
        const weight = currentWeights[point.index];
        const pastCount = pastCounts[point.index];

        let progressContent = "";
        if (pastCount === 0) {
          progressContent = `0% ${t("text.progress")}`;
        } else {
          const progress = (((count - pastCount) / pastCount) * 100).toFixed(2);
          progressContent = progress + `% ${t("text.progress")}`;
        }

        return (
          '<div style="text-align: center">' +
          "<div>" +
          count +
          ` ${t("text.parcels")}</div>` +
          "<Br />" +
          "<div>" +
          weight +
          ` ${t("text.weight")}</div>` +
          "<Br />" +
          '<div style="font-weight: bold; color: rgba(40, 50, 70, 1);">' +
          progressContent +
          "</div>" +
          "</div>"
        );
      },
    },
    series: series,
  };

  return (
    <div style={{ width: "100%", marginTop: "24px" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default RacesProgressChart;
