import axios from "axios";

const getRoles = async (token, departmentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    departmentId: departmentId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/delegation/get_roles`,
    {
      ...config,
      data: data,
    }
  );

  return response.data;
};

const rolesService = {
  getRoles,
};

export default rolesService;
