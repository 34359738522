import { useTranslation } from "react-i18next";
import ModalsHeadline from "../modals/components/ModalsHeadline";
import ButtonDefault from "../buttons/ButtonDefault";

const MobileFilter = ({ setShowModal, columns, formData, setFormData }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-between rounded-lg h-full">
      <div>
        <ModalsHeadline
          text={t("text.select")}
          onClick={() => setShowModal(false)}
        />

        <div className="p-4 relative md:p-6">
          {columns
            .filter((col) => col.filterComponent)
            .map((col) => (
              <div
                key={col.key}
                className="flex items-center justify-between gap-2 w-full border-b border-gray-2 py-3"
              >
                <label className="text-sm font-medium">{col.header}</label>
                {col.filterComponent(formData, setFormData, col.key)}
              </div>
            ))}
        </div>
      </div>

      <div className="p-6">
        <ButtonDefault onClick={() => setShowModal(false)} type="button">
          {t("text.select")}
        </ButtonDefault>
      </div>
    </div>
  );
};

export default MobileFilter;
