import { useTranslation } from "react-i18next";
import bonusImage from "../../assets/bonus.svg";
import { useSelector } from "react-redux";

const UsersBonus = () => {
  const { t } = useTranslation();
  const { analytics } = useSelector((state) => state.referralAnalytics);
  return (
    <div className="flex flex-col items-center gap-2 rounded-xl border border-gray-100 shadow-xl p-4 md:gap-4">
      <h2 className="text-gray-1 text-sm font-semibold text-center mb-3 md:text-base">
        {t("text.bonus_from_users")}{" "}
        <span className=" font-bold text-pink-500">
          {analytics ? analytics.agentsBonusSum : "..."}
        </span>
      </h2>
      <img
        className="max-w-52 w-full object-contain h-auto md:max-w-60"
        src={bonusImage}
        alt="active users"
        height={300}
        width={300}
      />
    </div>
  );
};

export default UsersBonus;
