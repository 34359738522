import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import { useAppContext } from "../../libs/AppContext";
import { useTranslation } from "react-i18next";
import {
  deleteCategory,
  getCategories,
} from "../../features/categories/CategoriesSlice";

import Modal from "../common/Modal";
import EditCategory from "../../components/modals/categories/EditCategory";
import AddCategory from "../../components/modals/categories/AddCategory";
import DeleteConfirmation from "../common/DeleteConfirmation";
import AddButton from "../common/AddButton";
import TableHeadline from "../common/TableHeadline";
import CategoriesTable from "../table/categories/CategoriesTable";

const CategoriesLayout = () => {
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const { categories } = useSelector((state) => state.categories);
  const { setShowMainModal, showMainModal, handleShowMainModal } =
    useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleShowEditCategory = (id) => {
    setShowEditCategory(!showEditCategory);
    setCategoryId(id);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setCategoryId(id);
  };

  const handleDeleteCategory = async () => {
    const categoryData = {
      categoryId: categoryId,
    };

    const response = await dispatch(deleteCategory(categoryData));
    if (response.payload.type === "success") {
      dispatch(getCategories());
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }

    if (response.error.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          message={responseMessage}
        />
      )}
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddCategory
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowModal={setShowMainModal}
          closeModal={handleShowMainModal}
        />
      </Modal>
      <Modal showmodal={showEditCategory} setShowModal={setShowEditCategory}>
        <EditCategory
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          handleShowEditCategory={handleShowEditCategory}
          setShowEditCategory={setShowEditCategory}
          categoryID={categoryId}
          data={categories}
        />
      </Modal>
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteCategory}
          text={t("text.category")}
        />
      )}
      <TableHeadline text={t("text.delegationCategory")} />
      <CategoriesTable
        handleShowEditCategory={handleShowEditCategory}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
      <AddButton />
    </div>
  );
};

export default CategoriesLayout;
