import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getNotStatuses } from "../../../features/notifications/statuses/StatusesSlice";
import { getRecipientTypes } from "../../../features/notifications/recipientTypes/RecipientTypesSlice";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSvg2, DoneSvg2, EditSvg3, EyeSvg } from "../../icons";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Checkbox from "../../common/Checkbox";
import CheckAll from "../../common/CheckAll";
import Pagination from "../../common/Pagination";
import Spinner from "../../common/Spinner";
import Message from "../../common/Message";
import TableSearch from "../../common/TableSearch";
import StatusDropDown from "../../notifications/StatusDropDown";
import RecipientsDropDown from "../../notifications/RecipientsDropDown";

const NotificationsTable = ({
  formData,
  setFormData,
  handleDeleteConfirmation,
}) => {
  const [recordsNumber, setRecordsNumber] = useState("");
  const { isLoading, notifications } = useSelector(
    (state) => state.notificationsList
  );
  const { notStatuses } = useSelector((state) => state.notificationStatuses);
  const { recipientTypes } = useSelector((state) => state.recipientTypes);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setRecordsNumber(notifications.recordsNumber);
  }, [notifications.recordsNumber]);

  const columnkeymappings = {
    Title: "title",
    Recipients: "recipientTypes",
    Status: "statusDesk",
    Types: "notificationTypes",
  };

  useEffect(() => {
    dispatch(getNotStatuses());
    dispatch(getRecipientTypes());
  }, [dispatch]);

  const statusOptions =
    notStatuses?.map((status) => ({
      status: status.status,
      statusDictionaryKey: status.statusDictionaryKey,
    })) || [];

  const recipientOptions =
    recipientTypes?.map((recipient) => ({
      name: recipient.name,
      desc: recipient.desc,
    })) || [];

  const formatDate = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month}, ${hours}:${minutes}`;
  };

  const handlePageIncrement = () => {
    setFormData((prevData) => ({ ...prevData, page: formData.page + 1 }));
  };

  const handlePageDecrement = () => {
    if (formData.page > 1) {
      setFormData((prevData) => ({ ...prevData, page: formData.page - 1 }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setFormData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setFormData((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
          <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center">
                    <CheckAll />
                  </div>
                </th>
                {Object.keys(columnkeymappings).map((key) => (
                  <th
                    key={key}
                    className="max-w-5 text-left border-b font-medium min-w-26"
                  >
                    <div className="relative p-4 w-full border-r border-black/5">
                      {key === "Status" ? (
                        <div className="flex justify-between items-center relative">
                          <span className="truncate">{key}</span>
                          <div className="flex justify-end gap-2">
                            <StatusDropDown
                              options={statusOptions}
                              value={formData.status}
                              onChange={(selectedStatus) =>
                                setFormData((prev) => ({
                                  ...prev,
                                  status: selectedStatus.status,
                                }))
                              }
                            />
                          </div>
                        </div>
                      ) : key === "Recipients" ? (
                        <div className="flex justify-between items-center relative">
                          <span className="truncate">{key}</span>
                          <div className="flex justify-end gap-2">
                            <RecipientsDropDown
                              options={recipientOptions}
                              value={formData.recipientType}
                              onChange={(selectedRecipient) =>
                                setFormData((prev) => ({
                                  ...prev,
                                  recipientType: selectedRecipient.name,
                                }))
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <span className="block truncate pr-2"> {key}</span>
                          <TableSearch
                            formData={formData}
                            setFormData={setFormData}
                            searchKey={columnkeymappings[key]}
                          />
                        </>
                      )}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {notifications?.notifications &&
              notifications?.notifications?.length > 0 ? (
                notifications?.notifications?.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-black/5 border-b"
                  >
                    <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                      <Checkbox />
                    </td>
                    {Object.keys(columnkeymappings).map((key) => (
                      <td
                        key={key}
                        className="max-w-5 text-xs text-blue-4 font-medium"
                      >
                        <div className="py-2 px-3 w-full truncate border-r border-black/5">
                          {key === "Status" ? (
                            <div className="flex items-center justify-start gap-2">
                              {row.status === "S" && (
                                <span className="w-6 min-w-6 flex justify-center items-center">
                                  <DoneSvg2 fill="#2B63F4" />
                                </span>
                              )}
                              {row.status === "N" && (
                                <span className="w-6 min-w-6 flex justify-center items-center">
                                  <DoneSvg2 fill="#979797" />
                                </span>
                              )}
                              {row.status === "D" && (
                                <span className="w-6 min-w-6 flex justify-center items-center">
                                  <DoneSvg2 fill="#E51F1F" />
                                </span>
                              )}
                              {row[columnkeymappings[key]]}
                              {row.scheduleFlag === "Y" ? (
                                <Tooltip title={formatDate(row.scheduleDate)}>
                                  <span className="text-xs text-blue-4 font-medium truncate">
                                    {formatDate(row.scheduleDate)}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span className="text-xs text-blue-4 font-medium truncate">
                                  {row.sendDate}
                                </span>
                              )}
                            </div>
                          ) : (
                            row[columnkeymappings[key]]
                          )}
                        </div>
                      </td>
                    ))}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <div className="flex gap-2">
                        {row.actions?.includes("showDetails") && (
                          <Link
                            className="w-4 flex justify-center items-center"
                            to={`/notifications/details/${row.id}`}
                          >
                            <EyeSvg />
                          </Link>
                        )}
                        {row.actions?.includes("edit") && (
                          <Link
                            className="w-4 flex justify-center items-center"
                            to={`/notifications/edit/${row.id}`}
                          >
                            <EditSvg3 />
                          </Link>
                        )}
                        {row.actions?.includes("delete") && (
                          <button
                            onClick={() => handleDeleteConfirmation(row.id)}
                            className="w-4 flex justify-center items-center"
                          >
                            <DeleteSvg2 />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={Object.keys(columnkeymappings).length + 2}
                    className="border-none p-2"
                  >
                    <Message text={t("text.not_found")} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {recordsNumber > 1 && (
            <Pagination
              currentPage={formData.page}
              onNextPage={handlePageIncrement}
              onPrevPage={handlePageDecrement}
              handlePerPage={handlePerPage}
              perPage={formData.perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default NotificationsTable;
