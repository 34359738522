import axios from "axios";

const getDepartments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/delegation/get_departments`,
    config
  );

  return response.data;
};

const registerDepartment = async (departmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/register_department`,
    departmentData,
    config
  );

  return response.data;
};

const updateDepartment = async (updatedDepartmentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/change_department`,
    updatedDepartmentData,
    config
  );

  return response.data;
};

const deleteDepartment = async (departmentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/delete_department`,
    departmentId,
    config
  );

  return response.data;
};

const departmentsService = {
  getDepartments,
  registerDepartment,
  updateDepartment,
  deleteDepartment,
};

export default departmentsService;
