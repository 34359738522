import { useSelector } from "react-redux";
import LargeTable from "../../tables/LargeTable";
import useCompanyConfig from "../../../tableConfigs/company";
import Spinner from "../../common/Spinner";

const CompanyTable = ({ handleEditCompany, handleDeleteConfirmation }) => {
  const { isLoading, companies } = useSelector((state) => state.companies);

  const tableConfig = useCompanyConfig(
    handleEditCompany,
    handleDeleteConfirmation
  );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
          <LargeTable config={tableConfig} data={companies} />
          {/* {recordsNumber > 1 && (
            <Pagination
              currentPage={formData.page}
              onNextPage={handlePageIncrement}
              onPrevPage={handlePageDecrement}
              handlePerPage={handlePerPage}
              perPage={formData.perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default CompanyTable;
