import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ExportFileSvg2 } from "../../icons";
import { Tooltip } from "@mui/material";
import { exportProviderParcels } from "../../../features/providerParcels/ProviderParcelsSlice";
import Headline from "../../common/shortList/Headline";
import ListBoxSmall from "../../common/shortList/ListBoxSmall";
import useLinkToNavigate from "../../../libs/useLinkToNavigate";

const ProviderParcelsPropertiesBar = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const linkToNavigate = useLinkToNavigate();
  const { countries } = useSelector((state) => state.parcelCountries);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleExport = async (id) => {
    try {
      const action = await dispatch(
        exportProviderParcels({
          warehouseCountryId: formData.warehouseCountryId,
        })
      );
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData?.length);
      for (let i = 0; i < decodedData?.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  return (
    <Headline linkUrl={linkToNavigate} text={t("text.providerParcels")}>
      <div className="p-3 lg:p-0 flex items-center gap-4">
        {formData.warehouseCountryId && (
          <Tooltip text={t("text.excel")}>
            <button type="button" onClick={handleExport}>
              <ExportFileSvg2 />
            </button>
          </Tooltip>
        )}

        <div className="flex items-center justify-end bg-white rounded-lg w-44 md:w-60">
          <ListBoxSmall
            options={countries?.map((cat) => ({
              value: cat.countryId,
              label: t(cat.countryDictionaryKey),
            }))}
            text={t("text.calculator_select_country")}
            name="warehouseCountryId"
            value={formData.warehouseCountryId}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </Headline>
  );
};

export default ProviderParcelsPropertiesBar;
