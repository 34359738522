import { useDispatch } from "react-redux";
import { uploadFile } from "../../features/notifications/files/FilesSlice";
import upload from "../../assets/upload-picture.png";

const UploadFile = () => {
  const dispatch = useDispatch();

  const handleChnage = async (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const formData = new FormData();

      await Array.from(files).forEach((file) => {
        formData.append("file[]", file);
      });

      dispatch(uploadFile(formData));
    }
  };

  return (
    <div className="relative w-full p-1.5 flex justify-center items-center rounded-xl border border-dashed border-blue-300 cursor-pointer transition-all duration-300 hover:border-solid">
      <img className="max-w-20 w-full object-contain" src={upload} alt="" />
      <span className="text-sm font-medium text-blue-1">Upload Image</span>
      <input
        type="file"
        id="file"
        onChange={handleChnage}
        multiple
        className="absolute inset-0 opacity-0 cursor-pointer"
      />
    </div>
  );
};

export default UploadFile;
