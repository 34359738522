import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "../common/Spinner";

const TextArea = ({
  font,
  setFormData,
  language,
  setLanguage,
  formData,
  errors,
  setErrors,
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  // const [error, setError] = useState("");
  const textAreaRef = useRef(null);
  const { file, isLoading } = useSelector((state) => state.notificationFile);

  useEffect(() => {
    if (textAreaRef.current && file && file.length > 0) {
      let formattedText = textAreaRef.current.innerHTML.trim();

      file.forEach((fileObj) => {
        if (fileObj.url) {
          const imgTag = `<img src="${fileObj.url}" alt="${fileObj.name}" class="my-2 h-60 max-w-80 w-full object-contain" />`;
          formattedText += imgTag;
        }
      });

      setFormData((prevState) => ({
        ...prevState,
        notificationDetails: {
          ...prevState.notificationDetails,
          EN: {
            ...prevState.notificationDetails.EN,
            message: formattedText,
          },
          GE: {
            ...prevState.notificationDetails.GE,
            message: formattedText,
          },
        },
      }));

      textAreaRef.current.innerHTML = formattedText;
      setIsEmpty(formattedText === "");
    }
  }, [file, setFormData]);

  useEffect(() => {
    if (textAreaRef.current) {
      const isBothMessagesEmpty =
        !formData.notificationDetails.EN.message.trim() &&
        !formData.notificationDetails.GE.message.trim();

      if (isBothMessagesEmpty) {
        textAreaRef.current.innerHTML = "";
        setIsEmpty(true);
      } else {
        const formattedText = textAreaRef.current.innerHTML.trim();
        setIsEmpty(formattedText === "");
      }
    }
  }, [
    formData.notificationDetails.EN.message,
    formData.notificationDetails.GE.message,
  ]);

  useEffect(() => {
    const styleLinks = () => {
      if (textAreaRef.current) {
        const links = textAreaRef.current.querySelectorAll("a");
        links.forEach((link) => {
          link.style.color = "blue";
          link.style.cursor = "pointer";
          link.style.textDecoration = "underline";

          link.addEventListener("click", (e) => {
            e.preventDefault();
            window.open(link.href, "_blank");
          });
        });
      }
    };

    if (textAreaRef.current) {
      const observer = new MutationObserver(styleLinks);
      observer.observe(textAreaRef.current, { childList: true, subtree: true });
      styleLinks();

      return () => observer.disconnect();
    }
  }, [textAreaRef]);

  const handleBeforeInput = (e) => {
    const text = e.target.innerText.trim();
    setIsEmpty(text === "");

    // if (language === "EN") {
    //   const englishTextPattern = /^[A-Za-z0-9\s.,!?;:()&'-]*$/;

    //   if (!englishTextPattern.test(e.data)) {
    //     e.preventDefault();
    //     setError("Please write only in English.");
    //   } else {
    //     setError("");
    //   }
    // } else if (language === "GE") {
    //   const georgianTextPattern = /^[\u10A0-\u10FF0-9\s.,!?;:()&'-]*$/;

    //   if (!georgianTextPattern.test(e.data)) {
    //     e.preventDefault();
    //     setError("Please write only in Georgian.");
    //   } else {
    //     setError("");
    //   }
    // }
  };

  const handleSwitchLanguage = (lang) => {
    setLanguage(lang);

    if (textAreaRef.current) {
      const content = formData.notificationDetails[lang]?.message || "";
      textAreaRef.current.innerHTML = content;
    }

    setIsEmpty(!formData.notificationDetails[lang]?.message);
    // setError('')

    if (textAreaRef.current) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(textAreaRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleInputChange = () => {
    if (textAreaRef.current) {
      let formattedText = textAreaRef.current.innerHTML.trim();

      if (formattedText === "<br>" || formattedText === "") {
        formattedText = "";
      }

      setFormData((prevState) => ({
        ...prevState,
        notificationDetails: {
          ...prevState.notificationDetails,
          [language]: {
            ...prevState.notificationDetails[language],
            message: formattedText,
          },
        },
      }));

      setIsEmpty(formattedText === "");

      const sanitizedText = formattedText.replace(/<br>/g, "").trim();

      setErrors((prevErrors) => ({
        ...prevErrors,
        notificationDetails: {
          ...prevErrors.notificationDetails,
          [language]: {
            ...prevErrors.notificationDetails[language],
            message:
              sanitizedText === ""
                ? `Please fill in the ${
                    language === "EN" ? "English" : "Georgian"
                  } message.`
                : "",
          },
        },
      }));
    }
  };

  return (
    <div className="!w-full flex flex-col items-start relative">
      <div className="max-w-80 w-full mx-auto my-0 grid grid-cols-2 px-5">
        <button
          type="button"
          onClick={() => handleSwitchLanguage("GE")}
          className={`py-3 border-b-4 ${
            language === "GE" ? "border-blue-2" : "border-transparent"
          } text-sm 2xl:text-base`}
        >
          Georgian
        </button>
        <button
          type="button"
          onClick={() => handleSwitchLanguage("EN")}
          className={`py-3 border-b-4 ${
            language === "EN" ? "border-blue-2" : "border-transparent"
          } text-sm 2xl:text-base`}
        >
          English
        </button>
      </div>
      <div className="w-full relative">
        {isLoading && <Spinner />}
        <div
          ref={textAreaRef}
          className={`px-5 py-8 outline-none relative text-sm 2xl:text-base w-full ${
            isEmpty ? "text-sm 2xl:text-base" : ""
          }`}
          contentEditable
          suppressContentEditableWarning={true}
          style={{
            fontFamily: font,
          }}
          onBeforeInput={handleBeforeInput}
          onInput={handleInputChange}
        ></div>
        {/* {error && (
          <div className="text-red-500 text-sm mt-2 absolute left-5 bottom-0">
            {error}
          </div>
        )} */}

        <div className="text-red-500 text-sm mt-2 absolute left-5 -bottom-6">
          {errors.notificationDetails.EN.message && (
            <p>{errors.notificationDetails.EN.message}</p>
          )}
        </div>
        <div className="text-red-500 text-sm mt-2 absolute left-5 -bottom-10">
          {errors.notificationDetails.GE.message && (
            <p>{errors.notificationDetails.GE.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextArea;
