import React from "react";
import { DeleteSvg, DocumentSvg } from "../icons";

const UploadedFileComponent = ({ index, openFile, deleteFile, file }) => {
  return (
    <div
      key={index}
      className="flex items-center justify-between px-3 py-2.5 bg-gray-100 rounded-lg shadow cursor-pointer w-full"
    >
      <div className="flex items-center gap-3 w-full">
        <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
          <DocumentSvg />
        </div>
        <h3
          onClick={openFile}
          className="text-sm font-medium text-blue-900 truncate w-[calc(100%-40px)]"
        >
          {file.name || file.fileName}
        </h3>
      </div>
      <button
        type="button"
        onClick={deleteFile}
        className="p-2 text-red-500 hover:text-red-700 transition-colors duration-300"
      >
        <DeleteSvg />
      </button>
    </div>
  );
};

export default UploadedFileComponent;
