import axios from "axios";

const getTrackingDetails = async (token, tdsCode) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    tdsCode: tdsCode,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI_W}/get_tracking_parcel_details`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const detailsService = { getTrackingDetails };
export default detailsService;
