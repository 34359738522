import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updatePotentialUser } from "../../../features/potentialUsers/PotentialUsersSlice";
import ModalsHeadline from "../components/ModalsHeadline";
import ButtonDefault from "../../buttons/ButtonDefault";
import Input from "../../common/Input";

const EditPotentialUser = ({
  setRefetch,
  refetch,
  setShowResponseMessage,
  setMessage,
  setSeverity,
  potentialUserId,
  setShowEditPotentialUser,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    pinOrInn: "",
    address: "",
    mobile: "",
    email: "",
    additionalInformation: "",
  });
  const [errors, setErrors] = useState({
    pinOrInn: "",
    address: "",
    mobile: "",
  });
  const { t } = useTranslation();
  const { potentialUsers } = useSelector((state) => state.potentialUsers);
  const dispatch = useDispatch();
  const {
    firstName,
    lastName,
    pinOrInn,
    address,
    mobile,
    email,
    additionalInformation,
  } = formData;

  useEffect(() => {
    const user = potentialUsers?.potentialUsers?.find(
      (user) => user.id === potentialUserId
    );
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        firstName: user ? user.firstName : "",
        lastName: user ? user.lastName : "",
        pinOrInn: user ? user.pinOrInn : "",
        address: user ? user.address : "",
        mobile: user ? user.mobile : "",
        email: user ? user.email : "",
        additionalInformation: user ? user.additionalInformation : "",
      }));
    }
  }, [potentialUsers?.potentialUsers, potentialUserId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const englishOnlyRegex = /^[a-zA-Z0-9\s\p{P}\p{S}]*$/u;

    if (name === "firstName" || name === "lastName") {
      if (!englishOnlyRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: t("text.english_characters_only"),
        }));
        return;
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!pinOrInn || !address || !mobile) {
      setErrors({
        pinOrInn: pinOrInn ? "" : t("text.field_required"),
        address: address ? "" : t("text.field_required"),
        mobile: mobile ? "" : t("text.field_required"),
      });

      return;
    }

    const formData = {
      potentialUserId: potentialUserId,
      firstName,
      lastName,
      pinOrInn,
      address,
      mobile,
      email,
      additionalInformation,
    };

    try {
      const response = await dispatch(updatePotentialUser(formData));
      if (response.payload.type === "success") {
        setRefetch(!refetch);
        setFormData({
          firstName: "",
          lastName: "",
          pinOrInn: "",
          address: "",
          mobile: "",
          email: "",
          additionalInformation: "",
        });
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("success");
        setShowEditPotentialUser(false);
      }
      if (response?.error?.message === "Rejected") {
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.edit_potential_user")}
          onClick={() => setShowEditPotentialUser(false)}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.First_Name")}
              name="firstName"
              value={formData.firstName}
              onchange={handleInputChange}
              error={errors.firstName}
              errormark={errors.firstName ? "error-border" : ""}
            />
            {errors.firstName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.firstName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.Last_Name")}
              name="lastName"
              value={formData.lastName}
              onchange={handleInputChange}
              error={errors.lastName}
              errormark={errors.lastName ? "error-border" : ""}
            />
            {errors.lastName && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.lastName}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.inn")}
              name="pinOrInn"
              value={formData.pinOrInn}
              onchange={handleInputChange}
              error={errors.pinOrInn}
              errormark={errors.pinOrInn ? "error-border" : ""}
            />
            {errors.pinOrInn && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.pinOrInn}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.address")}
              name="address"
              value={formData.address}
              onchange={handleInputChange}
              error={errors.address}
              errormark={errors.address ? "error-border" : ""}
            />
            {errors.address && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.address}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.mobile")}
              name="mobile"
              value={formData.mobile}
              onchange={handleInputChange}
              error={errors.mobile}
              errormark={errors.mobile ? "error-border" : ""}
            />
            {errors.mobile && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.mobile}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.email")}
              name="email"
              value={formData.email}
              onchange={handleInputChange}
            />
          </div>
          <div className="relative w-full">
            <Input
              text={t("text.additional_information")}
              name="additionalInformation"
              value={formData.additionalInformation}
              onchange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="px-6 pb-24 pt-6">
        <ButtonDefault>{t("text.edit")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditPotentialUser;
