import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import users from "./UsersService";

const initialState = {
  users: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getUsers = createAsyncThunk(
  "/referral/get_referral_users",
  async (
    {
      firstName,
      lastName,
      pinOrInn,
      mobile,
      status,
      referrerUserName,
      page,
      perPage,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await users.getUsers(
        token,
        firstName,
        lastName,
        pinOrInn,
        mobile,
        status,
        referrerUserName,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const registerUser = createAsyncThunk(
  "referral/register_referral_user",
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await users.registerUser(userData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const changeUser = createAsyncThunk(
  "referral/change_referral_user",
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await users.changeUser(userData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteUser = createAsyncThunk(
  "referral/delete_referral_user",
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await users.deleteUser(userId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users?.potentialUsers.unshift(action.payload);
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(changeUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.users?.potentialUsers?.findIndex(
          (user) => user.id === action.payload.id
        );
        state.users.potentialUsers[index] = action.payload;
      })
      .addCase(changeUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = state.users?.potentialUsers?.filter(
          (user) => user.id !== action.payload
        );
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;
