// import { useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import Group from "./Group";
import OneUser from "./OneUser";
import ButtonPrimary from "../buttons/ButtonPrimary";
import InputSmall from "../common/InputSmall";
// import DatePickerComponent from "../common/DatePickerComponent";
import SingleDatePicker from "../common/SingleDatePicker";

const GroupUser = ({ formData, setFormData, language, errors, setErrors }) => {
  // const [error, setError] = useState(null);

  // const englishTextPattern = /^[A-Za-z0-9\s.,!?;:()&'-]*$/;
  // const georgianTextPattern = /^[\u10A0-\u10FF0-9\s.,!?;:()&'-]*$/;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      // let isValid = true;

      // if (language === "EN" && !englishTextPattern.test(value)) {
      //   isValid = false;
      //   setError("Please write only in English.");
      // } else if (language === "GE" && !georgianTextPattern.test(value)) {
      //   isValid = false;
      //   setError("Please write only in Georgian.");
      // } else {
      //   setError(null);
      // }

      // if (!isValid) {
      //   return;
      // }

      setErrors((prevErrors) => ({
        ...prevErrors,
        notificationDetails: {
          ...prevErrors.notificationDetails,
          [language]: {
            ...prevErrors.notificationDetails[language],
            title: "",
          },
        },
      }));

      setFormData((prev) => ({
        ...prev,
        notificationDetails: {
          ...prev.notificationDetails,
          [language]: {
            ...prev.notificationDetails[language],
            title: value,
          },
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (formattedDate) => {
    setFormData((prevData) => ({
      ...prevData,
      scheduleDate: formattedDate,
    }));
  };

  return (
    <TabGroup className="px-5 py-3 border-l border-black/10 flex flex-col justify-between">
      <div>
        <TabList className="grid grid-cols-[auto_auto]  mt-10 lg:mt-0">
          <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
            Group
          </Tab>
          <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
            One User
          </Tab>
        </TabList>
        <TabPanels>
          <div className="pt-6">
            <InputSmall
              name="title"
              value={formData.notificationDetails[language]?.title || ""}
              onchange={handleInputChange}
              text="Title"
            />
            {/* {error && <div className="text-red-500 text-xs mt-1">{error}</div>} */}
            {errors.notificationDetails.GE.title && (
              <p className="text-red-500 text-xs mt-1">
                {errors.notificationDetails.GE.title}
              </p>
            )}
            {errors.notificationDetails.EN.title && (
              <p className="text-red-500 text-xs mt-1">
                {errors.notificationDetails.EN.title}
              </p>
            )}
          </div>
          <TabPanel className="py-6">
            <Group
              errors={errors}
              setErrors={setErrors}
              formData={formData}
              setFormData={setFormData}
            />
          </TabPanel>
          <TabPanel className="py-6">
            <OneUser formData={formData} setFormData={setFormData} />
          </TabPanel>
          <div className="pb-6">
            <span className="text-xs text-gray-1">Scheduled Notification</span>
            <div className="relative flex justify-start items-center mt-2">
              <SingleDatePicker
                value={formData.scheduleDate || null}
                onChange={handleDateChange}
              />
            </div>
          </div>
        </TabPanels>
      </div>
      <ButtonPrimary type="submit">Sent</ButtonPrimary>
    </TabGroup>
  );
};

export default GroupUser;
