import axios from "axios";

const getRecipientTypes = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/notification/get_recipient_types`,
    config
  );

  return response.data;
};

const recipientTypesService = { getRecipientTypes };
export default recipientTypesService;
