import { useTranslation } from "react-i18next";
import { DeleteSvg2, EditSvg3 } from "../components/icons";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { MdOutlineDone } from "react-icons/md";

const useCompanyConfig = (handleEditCompany, handleDeleteConfirmation) => {
  const { t } = useTranslation();

  const getActions = (row) => {
    return actionConfig
      .filter((action) =>
        typeof action.condition === "function"
          ? action.condition(row)
          : action.condition
      )
      .map((action, index) => (
        <Tooltip title={action.action(row).tooltip} key={index}>
          {action.type === "link" ? (
            <Link
              to={action.action(row).to}
              onClick={action.action(row).onClick}
              target={action.action(row).target || undefined}
            >
              {action.action(row).icon}
            </Link>
          ) : (
            <button onClick={action.action(row).onClick}>
              {action.action(row).icon}
            </button>
          )}
        </Tooltip>
      ));
  };

  const actionConfig = [
    {
      type: "button",
      permission: "edit",
      action: (row) => ({
        onClick: () => handleEditCompany(row.id),
        icon: <EditSvg3 />,
        tooltip: t("text.edit"),
      }),
      condition: true,
    },
    {
      type: "button",
      permission: "delete",
      action: (row) => ({
        onClick: () => handleDeleteConfirmation(row.id),
        icon: <DeleteSvg2 />,
        tooltip: t("text.delete"),
      }),
      condition: true,
    },
  ];

  return {
    columns: [
      {
        key: "full_name",
        header: t("text.bonus_name"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "email",
        header: t("text.email"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "inn",
        header: t("text.inn"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "",
        header: t("menu.Home"),
        label: true,
        cell: (row) =>
          row.default_flag === "Y" && (
            <span className="text-xl text-green-600">
              <MdOutlineDone />
            </span>
          ),
        filterable: false,
        actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useCompanyConfig;
