import { useTranslation } from "react-i18next";
import { Snackbar, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBanks, getCompanies } from "../../features/company/CompanySlice";
import { useAppContext } from "../../libs/AppContext";
import Headline from "../../components/common/shortList/Headline";
import CompanyTable from "../../components/table/company/CompanyTable";
import useLinkToNavigate from "../../libs/useLinkToNavigate";
import AddButton from "../../components/common/AddButton";
// import Modal from "../../components/common/Modal";
import AddCompany from "../../components/modals/company/AddCompany";
import EditCompany from "../../components/modals/company/EditCompany";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import ModalLarge from "../../components/common/ModalLarge";
import { getCountries } from "../../features/countries/CountriesSlice";

const Companies = () => {
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [showEditCompany, setShowEditCompany] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const linkToNavigate = useLinkToNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showMainModal, setShowMainModal, refetch } = useAppContext();

  useEffect(() => {
    dispatch(getCompanies());
  }, [refetch, dispatch]);

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getBanks());
  }, [dispatch]);

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleEditCompany = (id) => {
    setCompanyId(id);
    setShowEditCompany(!showEditCompany);
  };
  const handleDeleteConfirmation = (id) => {
    setCompanyId(id);
    setDeleteConfirmation(!deleteConfirmation);
  };
  const handleDeleteCompany = async () => {};

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteCompany}
        />
      )}
      <ModalLarge showmodal={showMainModal} setshowmodal={setShowMainModal}>
        <AddCompany
          setShowResponseMessage={setShowResponseMessage}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      </ModalLarge>
      <ModalLarge showmodal={showEditCompany} setshowmodal={setShowEditCompany}>
        <EditCompany
          setShowResponseMessage={setShowResponseMessage}
          setMessage={setMessage}
          setSeverity={setSeverity}
          companyId={companyId}
        />
      </ModalLarge>
      <Headline
        linkUrl={linkToNavigate}
        text={t("text._navigation._companyData")}
      />

      <CompanyTable
        handleEditCompany={handleEditCompany}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
      <AddButton />
    </div>
  );
};

export default Companies;
