import { useEffect, useRef, useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
// import DatePickerFilter from "../../table/userPrices/DatePickerFilter";
import Datepicker from "react-tailwindcss-datepicker";

const TableDatePicker = ({ name, value, setFormData }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleDateSelect = (name, value) => {
    const formatDate = (date) => {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = String(newDate.getMonth() + 1).padStart(2, "0");
      const day = String(newDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setFormData((prevData) => ({
      ...prevData,
      [name]: value ? formatDate(value) : "",
    }));
    setShowDropdown(null);
  };

  const handleChange = (date) => {
    handleDateSelect(name, date.startDate || "");
  };

  return (
    <div className="" ref={dropdownRef}>
      <div
        className={`relative cursor-pointer mt-1 block w-full text-sm`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <IoCalendarOutline />
      </div>

      {showDropdown && (
        <div className="absolute z-10 mt-1 right-0 w-40 bg-white border border-gray-200 rounded-lg max-h-60">
          {/* <DatePickerFilter
            name={name}
            value={value || ""}
            onChange={(e) => handleDateSelect(name, e.target.value)}
          /> */}
          <Datepicker
            asSingle
            value={{ startDate: value, endDate: value }}
            onChange={handleChange}
            inputClassName={`w-full px-4 py-2.5 border rounded-lg placeholder-gray-1 text-sm text-sm focus:outline-none focus:ring focus:ring-blue-2/50`}
            // placeholder={text}
            useRange={false}
          />
        </div>
      )}
    </div>
  );
};

export default TableDatePicker;
