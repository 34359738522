import axios from "axios";

const getDepartmentLinks = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    id: id,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/delegation/get_user_department_links`,
    {
      ...config,
      data: data,
    }
  );

  return response.data;
};

const deleteDepartmentLink = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/delete_user_department_link`,
    id,
    config
  );

  return response.data;
};

const departmentLinksService = {
  getDepartmentLinks,
  deleteDepartmentLink,
};

export default departmentLinksService;
