// import i18next from "i18next";
// import { initReactI18next } from "react-i18next";

// i18next.use(initReactI18next).init({
//   resources: {
//     en: {
//       translation: require("./locales/en/translation.json"),
//     },
//     ge: {
//       translation: require("./locales/ge/translation.json"),
//     },
//   },
//   lng: "en",
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18next;

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
