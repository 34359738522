import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MobileFilter from "../../tabelMobileFilters/MobileFilter";
// import { Tooltip } from "@mui/material";
// import { useTranslation } from "react-i18next";
// import {
//   ArrowRightBlue,
//   EditSvgBlue,
//   PhotoSvg,
//   PrintBarcodeSvg,
// } from "../../icons";
// import { Link, useParams } from "react-router-dom";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
import ParcelPrint from "../../modals/groups/ParcelPrint";
// import Message from "../../common/Message";
// import TableSearch from "../../common/shortList/TableSearch";
import IndividualParcelPrint from "../../modals/groups/IndividualParcelPrint";
import { getCategoriesWithMaster } from "../../../features/groups/GroupsSlice";
// import Checkbox from "../../common/Checkbox";
// import CheckAll from "../../common/CheckAll";
import LargeTable from "../../tables/LargeTable";
import useParcelsConfig from "../../../tableConfigs/parcels";
import Modal from "../../common/Modal";

const ParcelsTable = ({
  handleShowPicturesList,
  parcelsdata,
  setparcelsdata,
  typeId,
  parcelIds,
  setParcelIds,
  selectedRows,
  setSelectedRows,
}) => {
  const [subCategories, setSubCategories] = useState([]);
  const [printData, setPrintData] = useState({});
  const [recordsNumber, setRecordsNumber] = useState("");
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const { categoriesWithMaster } = useSelector((state) => state.groups);
  const { parcels, isLoading, isSuccess } = useSelector(
    (state) => state.parcels
  );
  // const { t } = useTranslation();
  const printRef = useRef();
  // const { parcelGroupId } = useParams();
  const dispatch = useDispatch();

  const tableConfig = useParcelsConfig(
    parcels,
    parcelIds,
    setParcelIds,
    setSelectedRows,
    selectedRows,
    typeId,
    parcelsdata,
    setparcelsdata,
    setPrintData,
    printRef,
    handleShowPicturesList,
    subCategories
  );

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster]);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
  }, [dispatch]);

  // const getSubCategoryDescription = (categoryId) => {
  //   if (categoryId) {
  //     const subCategory = subCategories.find((sub) => sub.id === categoryId);
  //     return subCategory ? subCategory.description : t("text.not_found");
  //   }
  // };

  // const columns = [
  //   { header: t("text.firsname_lastname"), key: "fullName" },
  //   { header: t("text.TDS_code"), key: "tdsCode" },
  //   { header: t("text.room_number"), key: "roomNumber" },
  //   { header: t("text.category"), key: "warehouseCategoryId" },
  //   { header: t("text.total_weight"), key: "weight" },
  //   { header: t("text.total_volume_weight"), key: "totalVolumeWeight" },
  //   { header: t("text.CBM"), key: "cbm" },
  //   { header: t("text.expected_amount_in_gel"), key: "expectedAmountGel" },
  // ];

  // const handlePrint = async (id) => {
  //   const parcel = await parcels?.parcels?.find((item) => item.id === id);

  //   if (parcel) {
  //     await setPrintData(parcel);
  //   }

  //   const printContent = printRef.current.innerHTML;
  //   const newWindow = window.open("", "_blank");

  //   newWindow.document.write(`
  //     <html>
  //       <head>
  //         <title>Print Parcel</title>
  //         <style>
  //           body {
  //             font-family: Arial, sans-serif;
  //             margin: 0;
  //             padding: 0;
  //             height: 100vh;
  //             display: flex;
  //             flex-direction: column;
  //             align-items: center;
  //             justify-content: center;
  //           }
  //           .content {
  //             display: flex;
  //             flex-direction: column;
  //             align-items: center;
  //             text-align: center;
  //             max-width: 800px;
  //             width: 100%;
  //             padding: 20px;
  //             box-sizing: border-box;
  //           }
  //           img {
  //             display: block;
  //             max-width: 200px;
  //             height: auto;
  //             margin-bottom: 10px
  //           }
  //           h2 {
  //             margin: 5px 0
  //           }
  //           h3 {
  //             font-size: 14px
  //           }
  //           .barcode {
  //             margin-top: 20px;
  //           }
  //           p {
  //             max-width: 270px;
  //             width: 100%;
  //             font-size: 12px;
  //             text-align: center;
  //           }
  //           .grid {
  //             display: grid;
  //             grid-template-columns: repeat(2, 1fr);
  //             max-width: 270px;
  //             width: 100%;
  //           }
  //           .first {
  //             border-right: solid 1px #000;
  //             padding:5px;
  //             text-align: start;
  //             display: flex;
  //             flex-direction: column;
  //             justify-content: flex-end;
  //           }
  //           .second{
  //             padding:5px;
  //             text-align: end;
  //             display: flex;
  //             flex-direction: column;
  //             justify-content: flex-end;
  //           }
  //         </style>
  //       </head>
  //       <body>
  //         <div class="content">
  //           ${printContent}
  //         </div>
  //       </body>
  //     </html>
  //   `);

  //   newWindow.document.close();
  //   newWindow.focus();
  //   newWindow.print();
  //   // newWindow.onafterprint = () => newWindow.close();
  //   newWindow.onafterprint = () => {
  //     newWindow.close();
  //     window.focus();
  //   };
  // };

  useEffect(() => {
    setRecordsNumber(parcels?.recordsNumber);
  }, [parcels.recordsNumber]);

  useEffect(() => {
    setParcelIds(selectedRows);
  }, [selectedRows, setParcelIds]);

  const handlePageIncrement = () => {
    setparcelsdata((prevData) => ({ ...prevData, page: parcelsdata.page + 1 }));
  };

  const handlePageDecrement = () => {
    if (parcelsdata.page > 1) {
      setparcelsdata((prevData) => ({
        ...prevData,
        page: parcelsdata.page - 1,
      }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setparcelsdata((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setparcelsdata((prevData) => ({ ...prevData, perPage: e }));
  };

  // const handleSelectRows = (id) => {
  //   const isSelected = selectedRows.includes(id);
  //   const updatedSelectedRows = isSelected
  //     ? selectedRows.filter((selectedId) => selectedId !== id)
  //     : [...selectedRows, id];

  //   setSelectedRows(updatedSelectedRows);
  // };

  // const handleCheckAllRow = () => {
  //   if (selectedRows?.length > 0) {
  //     setSelectedRows([]);
  //     setParcelIds([]);
  //   } else {
  //     const allParcels = parcels?.parcels
  //       ? parcels?.parcels.map((row) => row.id)
  //       : [];

  //     setSelectedRows(allParcels);
  //   }
  // };

  // const CheckboxComponent =
  //   parcelIds?.length === parcels?.parcels?.length ? Checkbox : CheckAll;

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isSuccess && (
            <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
              {typeId === 2 ? (
                <IndividualParcelPrint printRef={printRef} data={printData} />
              ) : (
                <ParcelPrint printRef={printRef} data={printData} />
              )}
              <div>
                <Modal
                  showmodal={showMobileFilters}
                  setShowModal={setShowMobileFilters}
                >
                  <MobileFilter
                    columns={tableConfig.columns}
                    setShowModal={setShowMobileFilters}
                    formData={parcelsdata}
                    setFormData={setparcelsdata}
                  />
                </Modal>
                <LargeTable
                  config={tableConfig}
                  data={parcels?.parcels}
                  setFormData={setparcelsdata}
                  formData={parcelsdata}
                  handleShowFilters={() =>
                    setShowMobileFilters((prev) => !prev)
                  }
                />
                {/* <table className="w-full bg-white border-collapse">
                  <thead className="bg-gray-100 text-sm text-blue-4">
                    <tr>
                      <th className="w-12 p-4 text-left border-b border-r border-black/5 font-medium">
                        <div className="flex justify-center">
                          <CheckboxComponent
                            checked={parcelIds?.length > 0}
                            onChange={handleCheckAllRow}
                          />
                        </div>
                      </th>
                      <th className="w-12 p-4 text-left border-b border-r border-black/5 font-medium">
                        <Tooltip title={t("text.barcode")}>
                          <div className="w-12 truncate">
                            {t("text.barcode")}
                          </div>
                        </Tooltip>
                      </th>
                      {columns?.map((column) => (
                        <th
                          key={column.key}
                          className={`${
                            column.key === "roomNumber" ||
                            column.key === "tdsCode"
                              ? "min-w-8"
                              : ""
                          } max-w-5 text-left border-b font-medium`}
                        >
                          <div className="relative p-4 w-full truncate border-r border-black/5">
                            {(column.key === "roomNumber" ||
                              column.key === "tdsCode") && (
                              <>
                                <span className="block truncate pr-3">
                                  {column.header}
                                </span>
                                <TableSearch
                                  formData={parcelsdata}
                                  setFormData={setparcelsdata}
                                  searchKey={column.key}
                                  parcels="parcels"
                                />
                              </>
                            )}
                            {column.key !== "roomNumber" &&
                              column.key !== "tdsCode" && (
                                <Tooltip title={column.header}>
                                  <span>{column.header}</span>
                                </Tooltip>
                              )}
                          </div>
                        </th>
                      ))}
                      <th className="max-w-2 text-left border-b font-medium w-20">
                        <div className="p-4 w-full truncate">
                          {t("text.actions")}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {parcels &&
                    parcels?.parcels &&
                    parcels?.parcels.length > 0 ? (
                      <>
                        {parcels?.parcels?.map((row, index) => (
                          <tr
                            key={index}
                            className={`hover:bg-gray-50 border-black/5 border-b`}
                          >
                            <td className="border-r border-black/5">
                              <div className="py-2 px-3 flex justify-center">
                                <Checkbox
                                  checked={
                                    parcelIds?.length > 0 &&
                                    selectedRows.includes(row.id)
                                  }
                                  onChange={() => handleSelectRows(row.id)}
                                />
                              </div>
                            </td>
                            <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                              <span
                                className="cursor-pointer"
                                onClick={() => handlePrint(row.id)}
                              >
                                <PrintBarcodeSvg />
                              </span>
                            </td>
                            {columns?.map((column, colIndex) => (
                              <td
                                key={colIndex}
                                className="max-w-5 text-xs text-blue-4 font-medium"
                              >
                                <div className="py-2 px-3 w-full truncate border-r border-black/5">
                                  {column.key === "warehouseCategoryId" ? (
                                    <Tooltip
                                      title={getSubCategoryDescription(
                                        row[column.key]
                                      )}
                                    >
                                      <span>
                                        {getSubCategoryDescription(
                                          row[column.key]
                                        )}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={row[column.key]}>
                                      <span>{row[column.key]}</span>
                                    </Tooltip>
                                  )}
                                </div>
                              </td>
                            ))}
                            <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                              <div className="flex items-center justify-center">
                                {typeId === 2 ? (
                                  <></>
                                ) : (
                                  <Tooltip title="Standart Parcel Details">
                                    <Link
                                      to={`/ParcelGroupNew/${parcelGroupId}/Parcels/${row.id}/details`}
                                      className="w-6 h-6 min-w-6 flex justify-center items-center"
                                    >
                                      <ArrowRightBlue />
                                    </Link>
                                  </Tooltip>
                                )}
                                <Tooltip title={t("text.photo")}>
                                  <button
                                    className={`w-6 h-6 min-w-6 flex justify-center items-center relative ${
                                      row?.parcelFiles &&
                                      row?.parcelFiles?.length > 0 &&
                                      "after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-2 after:absolute after:-top-0.5 after:-right-0.5"
                                    }`}
                                    onClick={() =>
                                      handleShowPicturesList(row.id)
                                    }
                                  >
                                    <PhotoSvg />
                                  </button>
                                </Tooltip>
                                {typeId === 2 ? (
                                  <Tooltip title={t("text.edit")}>
                                    <Link
                                      to={`/ParcelGroupNew/${parcelGroupId}/Parcels/individual/${row.id}/edit`}
                                      className="w-6 h-6 min-w-6 flex justify-center items-center"
                                    >
                                      <EditSvgBlue />
                                    </Link>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Edit Standart Parcel">
                                    <Link
                                      to={`/ParcelGroupNew/${parcelGroupId}/Parcels/standart/${row.id}/edit`}
                                      className="w-6 h-6 min-w-6 flex justify-center items-center"
                                    >
                                      <EditSvgBlue />
                                    </Link>
                                  </Tooltip>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={Object.keys(columns).length + 2}
                          className="border-none p-2"
                        >
                          <Message text={t("text.not_found")} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table> */}
              </div>
              {recordsNumber > 1 && (
                <Pagination
                  recordsNumber={recordsNumber}
                  currentPage={parcelsdata.page}
                  perPage={parcelsdata.perPage}
                  onNextPage={handlePageIncrement}
                  onPrevPage={handlePageDecrement}
                  handlePerPage={handlePerPage}
                  handlePageClick={handlePageClick}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ParcelsTable;
