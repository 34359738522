import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeProcessingParcelGroup } from "../../../features/processingParcelGroups/ParcelGroupsSlice";
import { useAppContext } from "../../../libs/AppContext";
import {
  getParcelGroups,
  reset,
} from "../../../features/notifications/parcelGroups/ParcelGroupsSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";
import Input from "../../common/Input";
import Spinner from "../../common/Spinner";

const EditBarcode = ({
  setShowModal,
  groupId,
  setShowResponseMessage,
  setMessage,
  setSeverity,
}) => {
  const [formData, setFormData] = useState({
    processingParcelGroupIds: [],
    parcelGroupBarcode: "",
  });
  const [showGroups, setShowGroups] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { processingParcelGroupIds, parcelGroupBarcode } = formData;
  const { setRefetch } = useAppContext();
  const { processingParcels } = useSelector((state) => state.processingParcels);
  const { notificationParcelGroups, isLoading } = useSelector(
    (state) => state.notificationsParcelGroups
  );
  const options = notificationParcelGroups?.map((group) => group.barcode) || [];
  const debounceTimeout = useRef(null);

  useEffect(() => {
    const barcode = processingParcels?.processingParcelGroups?.find(
      (group) => group.id === groupId
    );

    if (barcode) {
      setFormData((prev) => ({
        ...prev,
        parcelGroupBarcode: barcode.parcelGroupBarcode || "",
      }));
    }
  }, [groupId, processingParcels?.processingParcelGroups]);

  useEffect(() => {
    if (groupId) {
      setFormData((prev) => ({
        ...prev,
        processingParcelGroupIds: [...prev.processingParcelGroupIds, groupId],
      }));
    }
  }, [groupId]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if (name === "parcelGroupBarcode" && value.length > 2) {
      await dispatch(reset());
      setShowGroups(true);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        dispatch(getParcelGroups(value));
      }, 500);
    } else {
      setShowGroups(false);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectOption = (selectedBarcode) => {
    setFormData((prev) => ({
      ...prev,
      parcelGroupBarcode: selectedBarcode,
    }));
    setShowGroups(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      processingParcelGroupIds: JSON.stringify(processingParcelGroupIds),
      parcelGroupBarcode: parcelGroupBarcode,
    };

    try {
      const response = await dispatch(changeProcessingParcelGroup(dataToSend));

      if (response.payload.type === "success") {
        setShowModal(false);
        setFormData((prev) => ({
          ...prev,
          parcelGroupBarcode: "",
          processingParcelGroupIds: [],
        }));
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("success");
        setRefetch((prev) => !prev);
      }
      if (response?.error?.message === "Rejected") {
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <ModalsHeadline
          text={t("text.edit_barcode")}
          onClick={() => setShowModal(false)}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.barcode")}
              name="parcelGroupBarcode"
              value={formData.parcelGroupBarcode}
              onchange={handleInputChange}
            />
            {showGroups && (
              <div
                className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-xl shadow-lg max-h-48 min-h-20 overflow-y-auto"
                id="barcodeOptions"
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <div className="flex flex-col items-start w-full">
                    {options.length > 0 ? (
                      <>
                        {options.map((option) => (
                          <span
                            className="px-2 py-2 text-xs w-full cursor-pointer"
                            key={option}
                            value={option}
                            onClick={() => handleSelectOption(option)}
                          >
                            {option}
                          </span>
                        ))}
                      </>
                    ) : (
                      <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                        {t("text.not_found")}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.add")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditBarcode;
