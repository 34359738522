import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesWithMaster } from "../../../features/groups/GroupsSlice";
import MobileFilter from "../../tabelMobileFilters/MobileFilter";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
import ParcelPrint from "../../modals/groups/ParcelPrint";
import IndividualParcelPrint from "../../modals/groups/IndividualParcelPrint";
import LargeTable from "../../tables/LargeTable";
import Modal from "../../common/Modal";
import useParcelsInfoConfig from "../../../tableConfigs/parcelsInfo";

const ParcelsTableInformative = ({ parcelsdata, setparcelsdata, typeId }) => {
  const [subCategories, setSubCategories] = useState([]);
  const [printData, setPrintData] = useState({});
  const [recordsNumber, setRecordsNumber] = useState("");
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const { categoriesWithMaster } = useSelector((state) => state.groups);
  const { parcels, isLoading, isSuccess } = useSelector(
    (state) => state.parcels
  );

  const printRef = useRef();
  const dispatch = useDispatch();

  const tableConfig = useParcelsInfoConfig(
    parcels,
    setPrintData,
    printRef,
    subCategories
  );

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster]);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
  }, [dispatch]);

  useEffect(() => {
    setRecordsNumber(parcels?.recordsNumber);
  }, [parcels.recordsNumber]);

  const handlePageIncrement = () => {
    setparcelsdata((prevData) => ({ ...prevData, page: parcelsdata.page + 1 }));
  };

  const handlePageDecrement = () => {
    if (parcelsdata.page > 1) {
      setparcelsdata((prevData) => ({
        ...prevData,
        page: parcelsdata.page - 1,
      }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setparcelsdata((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setparcelsdata((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isSuccess && (
            <div className="w-full flex flex-col justify-between gap-20">
              {typeId === 2 ? (
                <IndividualParcelPrint printRef={printRef} data={printData} />
              ) : (
                <ParcelPrint printRef={printRef} data={printData} />
              )}
              <div>
                <Modal
                  showmodal={showMobileFilters}
                  setShowModal={setShowMobileFilters}
                >
                  <MobileFilter
                    columns={tableConfig.columns}
                    setShowModal={setShowMobileFilters}
                    formData={parcelsdata}
                    setFormData={setparcelsdata}
                  />
                </Modal>
                <div className="test-table">
                  <LargeTable
                    config={tableConfig}
                    data={parcels?.parcels}
                    setFormData={setparcelsdata}
                    formData={parcelsdata}
                    handleShowFilters={() =>
                      setShowMobileFilters((prev) => !prev)
                    }
                  />
                </div>
              </div>
              {recordsNumber > 1 && (
                <Pagination
                  recordsNumber={recordsNumber}
                  currentPage={parcelsdata.page}
                  perPage={parcelsdata.perPage}
                  onNextPage={handlePageIncrement}
                  onPrevPage={handlePageDecrement}
                  handlePerPage={handlePerPage}
                  handlePageClick={handlePageClick}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ParcelsTableInformative;
