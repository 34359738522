import axios from "axios";

const getNotTypes = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/notification/get_notification_types`,
    config
  );

  return response.data;
};

const notTypesService = { getNotTypes };
export default notTypesService;
