import axios from "axios";

const getProcessingParcelGroupDetails = async (
  token,
  processingParcelGroupId,
  tdsCode,
  roomNumber,
  status,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    processingParcelGroupId: processingParcelGroupId,
    tdsCode: tdsCode,
    roomNumber: roomNumber,
    status: status,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/processing_parcel_group/get_processing_parcel_group_details`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const changeProcessingParcel = async (parcelData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/processing_parcel_group/change_parcel_group_detail`,
    parcelData,
    config
  );

  return response.data;
};

const deleteProcessingParcel = async (ProcessingParcelId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/processing_parcel_group/delete_parcel_group_detail`,
    ProcessingParcelId,
    config
  );

  return response.data;
};

const detailsService = {
  getProcessingParcelGroupDetails,
  changeProcessingParcel,
  deleteProcessingParcel,
};
export default detailsService;
