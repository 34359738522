import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { changeProcessingParcel } from "../../../features/processingParcelGroups/DetailsSlice";
import ModalsHeadline from "../components/ModalsHeadline";
import Input from "../../common/Input";
import ButtonDefault from "../../buttons/ButtonDefault";
import { useAppContext } from "../../../libs/AppContext";

const EditParcel = ({
  setShowModal,
  parcelId,
  setMessage,
  setSeverity,
  setShowResponseMessage,
}) => {
  const [formData, setFormData] = useState({
    id: "",
    tdsCode: "",
    roomNumber: "",
    weight: "",
    cbm: "",
    url: "",
    status: "",
  });
  const [errors, setErrors] = useState({
    id: "",
    tdsCode: "",
    roomNumber: "",
    weight: "",
    cbm: "",
  });
  const { processingParcelGroupDetails } = useSelector(
    (state) => state.processingDetails
  );
  const { statuses } = useSelector((state) => state.processingStatuses);
  const { setRefetch } = useAppContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const parcel = processingParcelGroupDetails?.parcels?.find(
      (item) => item.id === parcelId
    );

    if (parcel) {
      setFormData((prev) => ({
        ...prev,
        id: parcelId,
        tdsCode: parcel.tdsCode || "",
        roomNumber: parcel.roomNumber || "",
        weight: parcel.weight || "",
        cbm: parcel.cbm || "",
        url: parcel.imageUrl || "",
        status: parcel.status || "",
      }));
    }
  }, [parcelId, processingParcelGroupDetails?.parcels]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getStatusName = (statusSymbol, t, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? t(status.statusDictionaryKey) : "...";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.tdsCode ||
      !formData.roomNumber ||
      !formData.weight ||
      !formData.cbm
    ) {
      setErrors({
        tdsCode: formData.tdsCode ? "" : t("text.field_required"),
        roomNumber: formData.roomNumber ? "" : t("text.field_required"),
        weight: formData.weight ? "" : t("text.field_required"),
        cbm: formData.cbm ? "" : t("text.field_required"),
      });

      return;
    }

    const dataToSend = {
      id: parcelId,
      tdsCode: formData.tdsCode,
      roomNumber: formData.roomNumber,
      weight: formData.weight,
      cbm: formData.cbm,
    };

    try {
      const response = await dispatch(changeProcessingParcel(dataToSend));
      if (response.payload.type === "success") {
        setRefetch((prev) => !prev);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("success");
      }
      if (response.error.message === "Rejected") {
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {}
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.edit")}
          onClick={() => setShowModal(false)}
        />
        <div className="grid grid-cols-2">
          <div className="p-6 w-full flex flex-col gap-4">
            <div className="relative w-full">
              <Input
                text={t("text.TDS_code")}
                name="tdsCode"
                value={formData.tdsCode}
                onchange={handleInputChange}
                error={errors.tdsCode}
                errormark={errors.tdsCode ? "error-border" : ""}
              />
              {errors.tdsCode && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {errors.tdsCode}
                </p>
              )}
            </div>
            <div className="relative w-full">
              <Input
                text={t("text.room_number")}
                name="roomNumber"
                value={formData.roomNumber}
                onchange={handleInputChange}
                error={errors.roomNumber}
                errormark={errors.roomNumber ? "error-border" : ""}
              />
              {errors.roomNumber && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {errors.roomNumber}
                </p>
              )}
            </div>
            <div className="relative w-full">
              <Input
                text={t("text.weight")}
                name="weight"
                value={formData.weight}
                onchange={handleInputChange}
                error={errors.weight}
                errormark={errors.weight ? "error-border" : ""}
              />
              {errors.weight && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {errors.weight}
                </p>
              )}
            </div>
            <div className="relative w-full">
              <Input
                text={t("text.cbm")}
                name="cbm"
                value={formData.cbm}
                onchange={handleInputChange}
                error={errors.cbm}
                errormark={errors.cbm ? "error-border" : ""}
              />
              {errors.cbm && (
                <p className="text-[10px] font-normal text-red-500 bg-white absolute -bottom-2 left-4 md:text-xs">
                  {errors.cbm}
                </p>
              )}
            </div>
            <div className="p-4 border border-gray-2 rounded-md opacity-50 text-sm">
              {getStatusName(formData.status, t, statuses)}
            </div>
          </div>
          <div className="p-6">
            <TransformWrapper disablePadding="true">
              <TransformComponent>
                <img className="w-full" src={formData.url} alt="Selected" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.edit")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditParcel;
