import { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { Tooltip } from "@mui/material";
// import { DeleteSvg, EditSvg } from "../../icons";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
// import TableSearch from "../../common/TableSearch";
// import StatusSelect from "./StatusSelect";
import LargeTable from "../../tables/LargeTable";
import usePotentialUsersConfig from "../../../tableConfigs/potentialUsers";
import MobileFilter from "../../tabelMobileFilters/MobileFilter";
import Modal from "../../common/Modal";

const PotentialUsersTable = ({
  handleShowEditPotentialUser,
  handleDeleteConfirmation,
  formData,
  setFormData,
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [recordsNumber, setRecordsNumber] = useState("");
  // const { t } = useTranslation();

  const { potentialUsers, isLoading } = useSelector(
    (state) => state.potentialUsers
  );

  const tableConfig = usePotentialUsersConfig(
    formData,
    setFormData,
    handleShowEditPotentialUser,
    handleDeleteConfirmation
  );

  // const columnkeymappings = {
  //   [t("text.First_Name")]: "firstName",
  //   [t("text.Last_Name")]: "lastName",
  //   [t("text.inn")]: "pinOrInn",
  //   [t("text.address")]: "address",
  //   [t("text.mobile")]: "mobile",
  //   [t("text.email")]: "email",
  //   "Additional Information": "additionalInformation",
  //   [t("text.status")]: "statusDesc",
  // };

  useEffect(() => {
    setRecordsNumber(potentialUsers.recordsNumber);
  }, [potentialUsers.recordsNumber]);

  const handlePageIncrement = () => {
    setFormData((prevData) => ({ ...prevData, page: formData.page + 1 }));
  };

  const handlePageDecrement = () => {
    if (formData.page > 1) {
      setFormData((prevData) => ({ ...prevData, page: formData.page - 1 }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setFormData((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setFormData((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
          <Modal
            showmodal={showMobileFilters}
            setShowModal={setShowMobileFilters}
          >
            <MobileFilter
              columns={tableConfig.columns}
              setShowModal={setShowMobileFilters}
              formData={formData}
              setFormData={setFormData}
            />
          </Modal>
          <LargeTable
            config={tableConfig}
            data={potentialUsers?.potentialUsers}
            setFormData={setFormData}
            formData={formData}
            handleShowFilters={() => setShowMobileFilters((prev) => !prev)}
          />
          {/* <table className="w-full bg-white border-collapse">
            <thead className="bg-gray-100 text-sm text-blue-4">
              <tr>
                <th className="p-4 text-left border-b border-r border-black/5 font-medium w-12">
                  <div className="w-full flex justify-center"></div>
                </th>
                {Object.keys(columnkeymappings).map((key) => (
                  <th
                    key={key}
                    className="max-w-5 text-left border-b font-medium min-w-26"
                  >
                    <div className="relative p-4 w-full border-r border-black/5 flex justify-between items-center gap-1">
                      <span className="block truncate pr-2">{key}</span>
                      {key !== `${t("text.status")}` && (
                        <TableSearch
                          formData={formData}
                          setFormData={setFormData}
                          searchKey={columnkeymappings[key]}
                        />
                      )}
                      {key === `${t("text.status")}` && (
                        <StatusSelect
                          formData={formData}
                          setFormData={setFormData}
                          columnKey="status"
                        />
                      )}
                    </div>
                  </th>
                ))}
                <th className="max-w-2 text-left border-b font-medium w-20">
                  <div className="p-4 w-full truncate">{t("text.actions")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {potentialUsers?.potentialUsers &&
                potentialUsers?.potentialUsers?.map((row, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-black/5 border-b"
                  >
                    <td className="py-2 px-3 border-r border-black/5 flex justify-center"></td>
                    {Object.keys(columnkeymappings).map((key) => {
                      const dataKey = columnkeymappings[key];
                      return (
                        <td
                          key={key}
                          className="max-w-5 text-xs text-blue-4 font-medium"
                        >
                          <div className="py-2 px-3 w-full border-r border-black/5 truncate">
                            <Tooltip title={row[dataKey]}>
                              <span>{row[dataKey]}</span>
                            </Tooltip>
                          </div>
                        </td>
                      );
                    })}
                    <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                      <div className="flex gap-2">
                        {row.actions?.includes("edit") && (
                          <Tooltip title={t("text.edit")}>
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                handleShowEditPotentialUser(row.id)
                              }
                            >
                              <EditSvg />
                            </div>
                          </Tooltip>
                        )}
                        {row.actions?.includes("delete") && (
                          <Tooltip title={t("text.delete")}>
                            <div
                              className="cursor-pointer"
                              onClick={() => handleDeleteConfirmation(row.id)}
                            >
                              <DeleteSvg />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table> */}
          {recordsNumber > 1 && (
            <Pagination
              currentPage={formData.page}
              onNextPage={handlePageIncrement}
              onPrevPage={handlePageDecrement}
              handlePerPage={handlePerPage}
              perPage={formData.perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PotentialUsersTable;
