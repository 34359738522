import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../libs/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { addSystemPrice } from "../../../features/prices/systemPrice/SystemPriceSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import ModalsHeadline from "../components/ModalsHeadline";
import Input from "../../common/Input";
// import Select from "./Select";
import SelectToSetLarge from "../../common/shortList/SelectToSetLarge";

const AddSystemPrice = ({
  setMessage,
  setSeverity,
  setShowResponseMessage,
  setRefetch,
  refetch,
}) => {
  const [formData, setFormData] = useState({
    fromCountryId: "",
    toCountryId: "",
    userTypeId: "",
    price: "",
    transportationTypeId: "",
    parcelGroupTypeId: "",
  });
  const [errors, setErrors] = useState({
    fromCountryId: "",
    toCountryId: "",
    userTypeId: "",
    price: "",
    transportationTypeId: "",
    parcelGroupTypeId: "",
  });
  const {
    fromCountryId,
    toCountryId,
    userTypeId,
    price,
    transportationTypeId,
    parcelGroupTypeId,
  } = formData;
  const { t } = useTranslation();
  const { handleShowMainModal, setShowMainModal } = useAppContext();
  const { countries } = useSelector((state) => state.countriesForPrice);
  const { transportationTypes } = useSelector(
    (state) => state.transportationTypesForPrice
  );
  const { userTypes } = useSelector((state) => state.userTypesForPrice);
  const { parcelGroupTypes } = useSelector((state) => state.parcelGroupsTypes);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (
      !price ||
      !toCountryId ||
      !fromCountryId ||
      !userTypeId ||
      !transportationTypeId ||
      !parcelGroupTypeId
    ) {
      setErrors({
        price: price ? "" : t("text.field_required"),
        toCountryId: toCountryId ? "" : t("text.field_required"),
        fromCountryId: fromCountryId ? "" : t("text.field_required"),
        userTypeId: userTypeId ? "" : t("text.field_required"),
        transportationTypeId: transportationTypeId
          ? ""
          : t("text.field_required"),
        parcelGroupTypeId: parcelGroupTypeId ? "" : t("text.field_required"),
      });
      return;
    }

    const formData = {
      fromCountryId,
      toCountryId,
      userTypeId,
      price,
      transportationTypeId,
      parcelGroupTypeId,
    };

    try {
      const response = await dispatch(addSystemPrice(formData));
      if (response.payload.type === "success") {
        setRefetch(!refetch);
        setFormData({
          fromCountryId: "",
          toCountryId: "",
          userTypeId: "",
          price: "",
          transportationTypeId: "",
          parcelGroupTypeId: "",
        });
        setShowMainModal(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("success");
      }

      if (response?.error?.message === "Rejected") {
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.add_price")}
          onClick={handleShowMainModal}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <Input
              text={t("text.price_gel")}
              name="price"
              value={formData.price}
              onchange={handleInputChange}
              error={errors.price}
              errormark={errors.price ? "error-border" : ""}
            />
            {errors.price && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.price}
              </p>
            )}
          </div>
          <div className="relative">
            {/* <Select
              options={countries
                ?.filter((cat) => cat.sendAllowed === "Y")
                .map((cat) => ({
                  value: cat.countryId,
                  label: t(cat.countryDictionaryKey),
                }))}
              text={t("text.from_country")}
              name="fromCountryId"
              value={formData.fromCountryId}
              onChange={handleInputChange}
              error={errors.fromCountryId}
            /> */}
            <SelectToSetLarge
              options={countries
                ?.filter((cat) => cat.sendAllowed === "Y")
                .map((cat) => ({
                  value: cat.countryId,
                  label: t(cat.countryDictionaryKey),
                }))}
              text={t("text.from_country")}
              name="fromCountryId"
              value={formData.fromCountryId}
              onChange={handleInputChange}
              error={errors.flightId}
            />
            {errors.fromCountryId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.fromCountryId}
              </p>
            )}
          </div>
          <div className="relative">
            {/* <Select
              options={countries
                ?.filter((cat) => cat.receivedAllowed === "Y")
                .map((cat) => ({
                  value: cat.countryId,
                  label: t(cat.countryDictionaryKey),
                }))}
              text="To Country"
              name="toCountryId"
              value={formData.toCountryId}
              onChange={handleInputChange}
              error={errors.toCountryId}
            /> */}
            <SelectToSetLarge
              options={countries
                ?.filter((cat) => cat.receivedAllowed === "Y")
                .map((cat) => ({
                  value: cat.countryId,
                  label: t(cat.countryDictionaryKey),
                }))}
              text={t("text.to_country")}
              name="toCountryId"
              value={formData.toCountryId}
              onChange={handleInputChange}
              error={errors.flightId}
            />
            {errors.toCountryId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.toCountryId}
              </p>
            )}
          </div>
          <div className="relative">
            {/* <Select
              options={transportationTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="Transportation Type"
              name="transportationTypeId"
              value={formData.transportationTypeId}
              onChange={handleInputChange}
              error={errors.transportationTypeId}
            /> */}
            <SelectToSetLarge
              options={transportationTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text={t("text.transportation")}
              name="transportationTypeId"
              value={formData.transportationTypeId}
              onChange={handleInputChange}
              error={errors.flightId}
            />
            {errors.transportationTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.transportationTypeId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            {/* <Select
              options={parcelGroupTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="Parcel Group Type"
              name="parcelGroupTypeId"
              value={formData.parcelGroupTypeId}
              onChange={handleInputChange}
              error={errors.parcelGroupTypeId}
            /> */}
            <SelectToSetLarge
              options={parcelGroupTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text={t("text.parcel_group")}
              name="parcelGroupTypeId"
              value={formData.parcelGroupTypeId}
              onChange={handleInputChange}
              error={errors.flightId}
            />
            {errors.parcelGroupTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.parcelGroupTypeId}
              </p>
            )}
          </div>
          <div className="relative">
            {/* <Select
              options={userTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="User Type"
              name="userTypeId"
              value={formData.userTypeId}
              onChange={handleInputChange}
              error={errors.userTypeId}
            /> */}
            <SelectToSetLarge
              options={userTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text={t("text.type")}
              name="userTypeId"
              value={formData.userTypeId}
              onChange={handleInputChange}
              error={errors.flightId}
            />
            {errors.userTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.userTypeId}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="px-6 pb-24 pt-6">
        <ButtonDefault>{t("text.add")}</ButtonDefault>
      </div>
    </form>
  );
};

export default AddSystemPrice;
