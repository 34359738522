import { SlArrowRight, SlArrowLeft } from "react-icons/sl";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxVisiblePages = 3;

  const generateVisiblePages = () => {
    const pages = [];

    pages.push(1);

    const showEllipsisAtStart = currentPage > maxVisiblePages - 1;
    const showEllipsisAtEnd = currentPage <= totalPages - (maxVisiblePages - 1);

    if (showEllipsisAtStart) {
      pages.push("...");
    }

    let startPage, endPage;
    if (currentPage <= maxVisiblePages - 1) {
      startPage = 2;
      endPage = Math.min(maxVisiblePages + 1, totalPages - 1);
    } else if (currentPage >= totalPages - (maxVisiblePages - 1)) {
      startPage = Math.max(2, totalPages - maxVisiblePages);
      endPage = totalPages - 1;
    } else {
      startPage = currentPage - Math.floor(maxVisiblePages / 2);
      endPage = currentPage + Math.floor(maxVisiblePages / 2);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (showEllipsisAtEnd) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className="flex justify-center items-center gap-1">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="p-2 rounded-full flex items-center justify-center text-gray-600 disabled:cursor-not-allowed"
      >
        <SlArrowLeft />
      </button>

      {generateVisiblePages().map((page, index) => {
        if (typeof page === "number") {
          return (
            <button
              key={index}
              onClick={() => onPageChange(page)}
              disabled={page === currentPage}
              className={`w-9 h-9 min-w-9 p-2 rounded-full flex items-center justify-center text-gray-600 ${
                page === currentPage ? "bg-purple-1 text-white" : ""
              } disabled:cursor-not-allowed`}
            >
              {page}
            </button>
          );
        } else {
          return (
            <span key={index} className="text-gray-600 px-2">
              ...
            </span>
          );
        }
      })}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="p-2 rounded-full flex items-center justify-center text-gray-600 disabled:cursor-not-allowed"
      >
        <SlArrowRight />
      </button>
    </div>
  );
};

export default Pagination;
