// import { Tooltip } from "@mui/material";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CheckAll from "../components/common/CheckAll";
import Checkbox from "../components/common/Checkbox";
import TableSearch2 from "../components/common/shortList/TableSearch2";

const useProviderParcelsConfig = () => {
  const { t } = useTranslation();
  const CheckboxComponent = 2 === 3 ? Checkbox : CheckAll;

  //   const actionConfig = [];

  //   const getActions = (row) => {
  //     return actionConfig
  //       .filter((action) => action.condition(row))
  //       .map((action, index) => (
  //         <Tooltip title={action.action(row).tooltip} key={index}>
  //           {action.type === "link" ? (
  //             <Link
  //               to={action.action(row).to}
  //               onClick={action.action(row).onClick}
  //               target={action.action(row).target || undefined}
  //             >
  //               {action.action(row).icon}
  //             </Link>
  //           ) : (
  //             <button onClick={action.action(row).onClick}>
  //               {action.action(row).icon}
  //             </button>
  //           )}
  //         </Tooltip>
  //       ));
  //   };

  return {
    columns: [
      {
        key: "",
        header: <CheckboxComponent checked={true} onChange={() => true} />,
        checkbox: true,
        filterable: false,
        cell: (row) => <Checkbox checked={true} onChange={() => true} />,
        // actions: getActions,
        condition: true,
      },
      {
        key: "tdsCode",
        header: t("text.barcode"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        // actions: getActions,
        condition: true,
      },
      {
        key: "roomNumber",
        header: t("text.room_number"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        // actions: getActions,
        condition: true,
      },
      {
        key: "weight",
        header: t("text.weight"),
        filterable: false,
        // actions: getActions,
        condition: true,
      },
      {
        key: "cbm",
        header: t("text.CBM_NEW"),
        filterable: false,
        // actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useProviderParcelsConfig;
