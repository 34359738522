import axios from "axios";

const getProviderParcels = async (
  token,
  warehouseCountryId,
  tdsCode,
  roomNumber,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
    tdsCode: tdsCode,
    roomNumber: roomNumber,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/processing_parcel_group/get_provider_processing_parcels`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const exportProviderParcels = async (warehouseCountryId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/processing_parcel_group/export_provider_processing_parcels`,
      { warehouseCountryId },
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const providerParcelsService = { getProviderParcels, exportProviderParcels };

export default providerParcelsService;
