import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import agents from "./AgentsService";

const initialState = {
  agents: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAgents = createAsyncThunk(
  "/referral/get_referral_agents",
  async (
    { firstName, lastName, pinOrInn, mobile, status, page, perPage },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await agents.getAgents(
        token,
        firstName,
        lastName,
        pinOrInn,
        mobile,
        status,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const registerAgent = createAsyncThunk(
  "referral/register_referral_agent",
  async (agentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await agents.registerAgent(agentData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const changeAgent = createAsyncThunk(
  "referral/change_referral_agent",
  async (agentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await agents.changeAgent(agentData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteAgent = createAsyncThunk(
  "referral/delete_referral_user",
  async (agentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await agents.deleteAgent(agentId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.agents = action.payload;
      })
      .addCase(getAgents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.agents?.potentialUsers.unshift(action.payload);
      })
      .addCase(registerAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(changeAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.agents?.potentialUsers?.findIndex(
          (user) => user.id === action.payload.id
        );
        state.agents.potentialUsers[index] = action.payload;
      })
      .addCase(changeAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.agents = state.agents?.potentialUsers?.filter(
          (agent) => agent.id !== action.payload
        );
      })
      .addCase(deleteAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = agentsSlice.actions;
export default agentsSlice.reducer;
