import { useTranslation } from "react-i18next";

const Statuses = ({ statuses, handleScan, statusRef, message }) => {
  const { t } = useTranslation();
  return (
    <button
      ref={statusRef}
      className={`relative ${
        statuses.warning
          ? "bg-yellow-500/50"
          : statuses.success
            ? "bg-green-2/50"
            : statuses.error
              ? "bg-red-500/50"
              : "bg-blue-2/50"
      } 
  capitalize text-blue-1/90 font-bold w-full flex justify-center items-center text-sm tracking-wide h-14 outline-none cursor-default md:h-16 lg:h-24 md:text-base lg:text-lg`}
      onKeyDown={(e) => handleScan(e, 0)}
    >
      {statuses.warning && <span>{t("Parcel.Status_AN")}</span>}
      {statuses.success && <span>{t("Parcel.Status_SY")}</span>}
      {statuses.error && <span>{message}</span>}
    </button>
  );
};

export default Statuses;
