import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  deleteProcessingParcel,
  getProcessingParcelGroupDetails,
} from "../../features/processingParcelGroups/DetailsSlice";
import { useAppContext } from "../../libs/AppContext";
import { Snackbar, Alert } from "@mui/material";
import Headline from "../../components/common/shortList/Headline";
import ProcessingLayout from "./ProcessingLayout";
import ProcessingDetailsTabe from "../../components/table/processingParcelGroups/ProcessingDetailsTabe";
import EditParcel from "../../components/modals/processingGroups/EditParcel";
import ModalLarge from "../../components/common/ModalLarge";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";

const ProcessingDetails = () => {
  const [detailsData, setDetailsData] = useState({
    processingParcelGroupId: "",
    tdsCode: "",
    roomNumber: "",
    status: "",
    page: 1,
    perPage: 10,
  });
  const dispatch = useDispatch();
  const [parcelId, setParcelId] = useState("");
  const [showEditParcel, setShowEditParcel] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { processingGroupId } = useParams();
  const { refetch, setRefetch } = useAppContext();

  const {
    processingParcelGroupId,
    tdsCode,
    roomNumber,
    status,
    page,
    perPage,
  } = detailsData;

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const handleShowEditParcel = (id) => {
    setParcelId(id);
    setShowEditParcel(!showEditParcel);
  };

  useEffect(() => {
    setDetailsData((prev) => ({
      ...prev,
      processingParcelGroupId: processingGroupId,
    }));
  }, [processingGroupId]);

  useEffect(() => {
    if (processingParcelGroupId) {
      dispatch(
        getProcessingParcelGroupDetails({
          processingParcelGroupId,
          tdsCode,
          roomNumber,
          status,
          page,
          perPage,
        })
      );
    }
  }, [
    dispatch,
    processingParcelGroupId,
    tdsCode,
    roomNumber,
    status,
    refetch,
    page,
    perPage,
  ]);

  const { t } = useTranslation();

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setParcelId(id);
  };

  const handleDeleteGroup = async () => {
    const groupData = {
      id: JSON.stringify(parcelId),
    };

    const response = await dispatch(deleteProcessingParcel(groupData));
    if (response?.payload?.type === "success") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);

      setMessage(response.payload.message);
      setSeverity("success");
      setRefetch(!refetch);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setMessage(response.payload.message);
      setSeverity("error");
    }
  };

  return (
    <ProcessingLayout>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <Headline
        linkUrl="/processingParcelGroups"
        text={t("text.processing_parcel_group_details")}
      />
      {selectedImage && (
        <div className="fixed inset-0 z-50">
          <div
            className="w-full h-full bg-black/20"
            onClick={handleCloseImage}
          ></div>
          <div className="absolute max-w-6xxl w-full p-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
            <TransformWrapper disablePadding="true">
              <TransformComponent>
                <img className="w-full" src={selectedImage} alt="Selected" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteGroup}
        />
      )}
      <ModalLarge showmodal={showEditParcel} setShowModal={setShowEditParcel}>
        <EditParcel
          setShowModal={handleShowEditParcel}
          parcelId={parcelId}
          setMessage={setMessage}
          setSeverity={setSeverity}
          setShowResponseMessage={setShowResponseMessage}
        />
      </ModalLarge>
      <ProcessingDetailsTabe
        detailsData={detailsData}
        setDetailsData={setDetailsData}
        setSelectedImage={setSelectedImage}
        handleShowEditParcel={handleShowEditParcel}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
    </ProcessingLayout>
  );
};

export default ProcessingDetails;
