import axios from "axios";

const getUserTypes = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel/get_user_types`,
    config
  );

  return response.data;
};

const userTypesService = { getUserTypes };

export default userTypesService;
