import { MdOutlineDone } from "react-icons/md";

const Checkbox = ({ checked, onChange }) => {
  return (
    <label
      className={`relative flex h-4 w-4 min-w-4 rounded border border-gray-2 cursor-pointer ${
        checked ? "border-transparent bg-purple-2" : "bg-white"
      }`}
    >
      <input
        type="checkbox"
        className="absolute z-10 opacity-0 w-full h-full cursor-pointer"
        onChange={onChange}
      />
      {checked && (
        <span className="absolute text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3.5 h-3.5 flex justify-center items-center">
          <MdOutlineDone />
        </span>
      )}
    </label>
  );
};

export default Checkbox;
