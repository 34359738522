import { useTranslation } from "react-i18next";
import TableSearch2 from "../components/common/shortList/TableSearch2";
import SelectToFilter from "../components/common/shortList/SelectToFilter";
import ProcessingTableImage from "../components/common/ProcessingTableImage";

const useParcelTrackingConfig = (formData, setFormData, setSelectedImage) => {
  const { t } = useTranslation();

  const getFormattedDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return "";
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const declarationOptions = [
    { value: "Y", label: t("Parcel.Status_SY") },
    { value: "N", label: t("Parcel.Status_AN") },
  ];
  return {
    animation: true,
    columns: [
      {
        key: "tdsCode",
        header: t("text.TDS_code"),
        important: true,
        condition: true,
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
      },
      {
        key: "roomNumber",
        header: t("text.room_number"),
        condition: true,
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
      },
      {
        key: "inpDate",
        header: t("text.date"),
        cell: (row) => getFormattedDate(row.inpDate),
        filterable: false,
        condition: true,
      },
      {
        key: "declared",
        header: t("text.declared"),
        cell: (row) => {
          return row.declared === "Y" ? `${t("text.yes")}` : `${t("text.no")}`;
        },
        filterable: true,
        filterComponent: () => (
          <SelectToFilter
            options={declarationOptions?.map((cat) => ({
              value: cat.value,
              label: cat.label,
            }))}
            name="declared"
            value={formData.declared}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        condition: true,
      },
      {
        key: "weight",
        header: t("text.weight"),
        filterable: false,
        condition: true,
      },
      {
        key: "length",
        header: t("text.length"),
        filterable: false,
        condition: true,
      },
      {
        key: "width",
        header: t("text.width"),
        filterable: false,
        condition: true,
      },
      {
        key: "height",
        header: t("text.height"),
        filterable: false,
        condition: true,
      },
      {
        key: "imageUrl",
        header: t("text.image"),
        filterable: false,
        condition: true,
        label: true,
        cell: (row) => (
          <ProcessingTableImage
            url={row.imageUrl}
            setSelectedImage={setSelectedImage}
          />
        ),
      },
    ],
  };
};

export default useParcelTrackingConfig;
