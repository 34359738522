import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import systemPricesService from "./SystemPriceService";

const initialState = {
  systemPrices: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getSystemPrices = createAsyncThunk(
  "parcel/get_system_prices",
  async (
    {
      fromCountryId,
      toCountryId,
      userTypeId,
      price,
      transportationTypeId,
      parcelGroupTypeId,
      page,
      perPage,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await systemPricesService.getSystemPrices(
        token,
        fromCountryId,
        toCountryId,
        userTypeId,
        price,
        transportationTypeId,
        parcelGroupTypeId,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const addSystemPrice = createAsyncThunk(
  "parcel/add_system_price",
  async (systemPriceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await systemPricesService.addSystemPrice(systemPriceData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const editSystemPrice = createAsyncThunk(
  "parcel/edit_system_price",
  async (systemPriceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await systemPricesService.editSystemPrice(systemPriceData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteSystemPrice = createAsyncThunk(
  "parcel/delete_system_price",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await systemPricesService.deleteSystemPrice(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const systemPricesSlice = createSlice({
  name: "systemPrices",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSystemPrices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.systemPrices = action.payload;
      })
      .addCase(getSystemPrices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addSystemPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSystemPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.systemPrices?.systemPrices.unshift(action.payload);
      })
      .addCase(addSystemPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editSystemPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editSystemPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.systemPrices?.systemPrices?.findIndex(
          (price) => price.id === action.payload.id
        );
        if (index !== -1) {
          state.systemPrices.systemPrices[index] = {
            ...state.systemPrices[index],
            ...action.payload,
          };
        }
      })
      .addCase(editSystemPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteSystemPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSystemPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.systemPrices = state.systemPrices?.systemPrices?.filter(
          (price) => price.id !== action.payload.id
        );
      })
      .addCase(deleteSystemPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = systemPricesSlice.actions;
export default systemPricesSlice.reducer;
