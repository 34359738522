import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackSvg } from "../../components/icons";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { updateIndividualParcel } from "../../features/groups/parcels/ParcelsSlice";
import { reset as resetCalcs } from "../../features/groups/CalculationsSlice";
import { useAppContext } from "../../libs/AppContext";
import ParcelGroupsLayout from "./ParcelGroupsLayout";
import Modal from "../../components/common/Modal";
import UploadParcelPictures from "../../components/modals/groupsNew/UploadParcelPictures";
import EditIndividualParcelForm from "../../components/forms/parcelGroups/EditIndividualParcelForm";

const EditIndividualParcel = () => {
  const [formData, setFormData] = useState({
    parcelId: "",
    tdsCode: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
    senderPotentialUserId: "",
    receiverUserId: "",
    senderUserId: "",
    address: "",
    departmentId: "",
    price: "",
    cityId: "",
    addressComment: "",
    sender: {
      firstName: "",
      lastName: "",
      pinOrInn: "",
      address: "",
      mobile: "",
      email: "",
      additionalInformation: "",
    },
    receiver: {
      firstName: "",
      lastName: "",
      pinOrInn: "",
      address: "",
      mobile: "",
      email: "",
      additionalInformation: "",
    },
  });
  // const [shouldPrint, setShouldPrint] = useState(false);
  // const [printData, setPrintData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [parcel, setParcel] = useState({});
  const { setRefetch } = useAppContext();
  // const printRef = useRef();

  const { parcels } = useSelector((state) => state.parcels);
  const { categoriesWithMaster } = useSelector((state) => state.groups);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parcelGroupId, parcelId } = useParams();

  useEffect(() => {
    const parcel = parcels?.parcels?.find(
      (item) => parseInt(item.id) === parseInt(parcelId)
    );

    if (parcel) {
      setParcel(parcel);
      const parseAddress = (address) => {
        try {
          return address ? JSON.parse(address) : null;
        } catch (error) {
          console.error("Invalid JSON address:", error.message);
          return address || {};
        }
      };

      setFormData((prev) => ({
        ...prev,
        tdsCode: parcel.tdsCode || "",
        parcelDetails: parcel.parcelDetails.length
          ? parcel.parcelDetails.map((detail) => ({
              id: detail.id || "",
              quantity: detail.quantity || "",
              weight: detail.weight || "",
              length: detail.length || "",
              width: detail.width || "",
              height: detail.height || "",
            }))
          : [
              {
                id: "",
                quantity: "",
                weight: "",
                length: "",
                width: "",
                height: "",
              },
            ],
        warehouseComment: parcel.warehouseComment || "",
        warehouseCategoryId: parcel.warehouseCategoryId || "",
        "file[]": parcel.parcelFiles || [],
        senderPotentialUserId: parcel.participants?.senderPotentialUserId || "",
        receiverUserId: parcel.participants?.receiverUserId || "",
        senderUserId: parcel.participants?.senderUserId || "",
        address: parseAddress(parcel?.address),
        departmentId: !parcel.address ? parcel.departmentId : "",
        price: parcel.price || "",
        cityId: parcel.cityId || "",
        addressComment: parcel.addressComment || "",
        sender: {
          firstName: parcel.participants?.sender?.firstName || "",
          lastName: parcel.participants?.sender?.lastName || "",
          pinOrInn: parcel.participants?.sender?.pinOrInn || "",
          address: parseAddress(parcel.participants?.sender?.address || ""),
          mobile: parcel.participants?.sender?.mobile || "",
          email: parcel.participants?.sender?.email || "",
          additionalInformation:
            parcel.participants?.sender?.additionalInformation || "",
        },
        receiver: {
          firstName: parcel.participants?.receiver?.firstName || "",
          lastName: parcel.participants?.receiver?.lastName || "",
          pinOrInn: parcel.participants?.receiver?.pinOrInn || "",
          address: parseAddress(parcel.participants?.receiver?.address || ""),
          mobile: parcel.participants?.receiver?.mobile || "",
          email: parcel.participants?.receiver?.email || "",
          additionalInformation:
            parcel.participants?.receiver?.additionalInformation || "",
        },
      }));

      setSelectedSubCategory(parcel.warehouseCategoryId);

      const parentCategory = categoriesWithMaster.find((category) =>
        category.children.some(
          (sub) => sub.id === parseInt(parcel.warehouseCategoryId)
        )
      );
      if (parentCategory) {
        setSubCategories(parentCategory?.children || []);
        setSelectedMainCategory(parentCategory.id);
      }
    }
  }, [parcels, parcelId, categoriesWithMaster]);

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.departmentId) {
      setFormData((prev) => ({
        ...prev,
        address: "",
      }));
    }

    const formattedParcelDetails = formData.parcelDetails
      .map((detail) => {
        const formattedDetail = {
          quantity: parseFloat(detail.quantity) || 0,
          weight: parseFloat(detail.weight) || 0,
          length: parseFloat(detail.length) || 0,
          width: parseFloat(detail.width) || 0,
          height: parseFloat(detail.height) || 0,
        };

        if (detail.id) {
          formattedDetail.id = parseFloat(detail.id);
        }
        return formattedDetail;
      })
      .filter(
        (detail) =>
          detail.quantity !== 0 ||
          detail.weight !== 0 ||
          detail.length !== 0 ||
          detail.width !== 0 ||
          detail.height !== 0
      );

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "parcelDetails") {
        formDataToSend.append(key, JSON.stringify(formattedParcelDetails));
      } else if (key === "file[]") {
        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else if (
        key === "sender" ||
        key === "receiver" ||
        (key === "address" && value !== "")
      ) {
        formDataToSend.append(key, JSON.stringify(value));
      } else if (key === "tdsCode") {
        formDataToSend.append("tdsCode", value);
      } else if (key === "parcelId") {
        formDataToSend.append("parcelId", parcelId);
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(updateIndividualParcel(formDataToSend));
      if (response.payload && response.payload.type === "success") {
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        dispatch(resetCalcs());
        setRefetch((prev) => !prev);
      }

      if (response.error && response.error.message === "Rejected") {
        setSeverity("error");
        setShowResponseMessage(true);
        setMessage(response.payload.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ParcelGroupsLayout>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to={`/ParcelGroupNew/${parcelGroupId}/Parcels`}
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11 cursor-pointer"
          >
            <BackSvg />
          </Link>
          <div className="text-sm font-semibold text-gray-800 md:text-lg lg:text-xl">
            ინდივიდუალური ამანათის {t("text.edit")}
          </div>
        </div>
      </div>
      <Modal showmodal={showUploadModal} setShowModal={setShowUploadModal}>
        <UploadParcelPictures
          formData={formData}
          setFormData={setFormData}
          setShowUploadModal={setShowUploadModal}
        />
      </Modal>
      <EditIndividualParcelForm
        handleSubmit={handleSubmit}
        setFormData={setFormData}
        formData={formData}
        errors={errors}
        setErrors={setErrors}
        setSelectedMainCategory={setSelectedMainCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        selectedMainCategory={selectedMainCategory}
        selectedSubCategory={selectedSubCategory}
        setShowUploadModal={setShowUploadModal}
        subCategories={subCategories}
        setSubCategories={setSubCategories}
        parcel={parcel}
      />
    </ParcelGroupsLayout>
  );
};

export default EditIndividualParcel;
