import { useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { DashboardArrowDownSvg } from "../../icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InnerPagesBranches = ({ setFormData }) => {
  const [branch, setBranch] = useState({});
  const branches = useSelector((state) => state.branches?.branches);
  const { t } = useTranslation();

  const handleChange = (selectedBranch) => {
    setBranch(selectedBranch);

    setFormData((prevData) => ({
      ...prevData,
      departmentId: selectedBranch?.id ? selectedBranch.id : "",
    }));
  };

  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <div className="relative w-full md:w-max">
      <Listbox value={branch} onChange={handleChange}>
        {({ open }) => (
          <>
            <ListboxButton className="w-full flex items-center justify-between py-1 px-3 text-xs font-medium text-purple-5/70 bg-white border border-purple-5/30 rounded-lg cursor-pointer gap-4 sm:w-full">
              {branch.address
                ? capitalize(branch?.address)
                : t("text.all_branches")}
              <DashboardArrowDownSvg />
            </ListboxButton>
            {open && (
              <ListboxOptions className="absolute w-48 right-0 top-full mt-2 bg-white-1 border border-purple-5/30 rounded-lg shadow-md overflow-hidden z-50">
                <ListboxOption
                  value=""
                  className="text-xs font-medium text-purple-5/70 py-1 px-2 border-b border-black/5 hover:bg-purple-2 hover:bg-opacity-20 cursor-pointer"
                >
                  {t("text.all_branches")}
                </ListboxOption>
                {branches?.map((item, index) => (
                  <ListboxOption
                    key={index}
                    value={item}
                    className="text-xs font-medium text-purple-5/70 py-1 px-2 border-b border-black/5 hover:bg-purple-2 hover:bg-opacity-20 cursor-pointer"
                  >
                    {capitalize(item.address)}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

export default InnerPagesBranches;
