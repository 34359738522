import axios from "axios";

const getGroups = async (
  token,
  warehouseCountryId,
  flightNumber,
  status,
  barcode,
  parcelGroupTypeId,
  transportationTypeId,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
    flightNumber: flightNumber,
    status: status,
    barcode: barcode,
    parcelGroupTypeId: parcelGroupTypeId,
    transportationTypeId: transportationTypeId,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_parcel_groups`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const registerGroup = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_registration`,
    groupData,
    config
  );

  return response.data;
};

const transferGroupsExcel = async (excelData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_change_group_excel`,
    excelData,
    config
  );

  return response.data;
};

const updateGroup = async (updatedGroupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_change`,
    updatedGroupData,
    config
  );

  return response.data;
};

const deleteGroup = async (parcelGroupIds, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_delete`,
    parcelGroupIds,
    config
  );

  return response.data;
};

const exportExcelForLand = async (parcelGroupId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/export_land_parcels`,
      { parcelGroupId },
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const groupsService = {
  getGroups,
  registerGroup,
  updateGroup,
  deleteGroup,
  transferGroupsExcel,
  exportExcelForLand,
};

export default groupsService;
