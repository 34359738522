import { useState, useEffect, useRef } from "react";

const RecipientsDropDown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const modifiedOptions = [
    { name: "", desc: "Select All Recipients" },
    ...options,
  ];

  const handleSelect = (option) => {
    if (option.name === "") {
      onChange({ name: "" });
    } else {
      onChange(option);
    }
    setIsOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <div
        className="w-2.5 cursor-pointer flex justify-end items-center transition-all duration-300 hover:text-blue-2"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        ▼
      </div>

      {isOpen && (
        <div className="absolute right-0 z-10 w-32 border bg-white-1 border-white-2 rounded-md mt-1">
          {modifiedOptions.map((option) => (
            <div
              key={option.name}
              className={`flex items-center justify-between gap-2 text-sm p-1.5 w-full hover:bg-blue-2/10 cursor-pointer ${
                value === option.name ? "bg-blue-2/10" : ""
              }`}
              onClick={() => handleSelect(option)}
            >
              <span className="truncate">{option.desc}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecipientsDropDown;
