import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import activeUsersAgentsImage from "../../assets/active-users-agents.svg";

const ActiveUserAgents = () => {
  const { t } = useTranslation();
  const { analytics } = useSelector((state) => state.referralAnalytics);

  return (
    <div className="flex flex-wrap justify-center items-end rounded-xl border gap-2 border-gray-100 shadow-xl p-4 md:gap-4">
      <img
        className="max-w-36 w-full object-contain h-auto md:max-w-60"
        src={activeUsersAgentsImage}
        alt="actives"
        height={300}
        width={300}
      />
      <div>
        <div className="flex justify-start items-center gap-2">
          <span className="h-3 w-3 block rounded-full bg-pink-500"></span>
          <span className="text-xs text-blue-1">{t("text.active_users")}</span>
          <span className="text-pink-500">
            {analytics ? analytics.activeUsersCount : "..."}
          </span>
        </div>
        <div className="flex justify-start items-center gap-2">
          <span className="h-3 w-3 block rounded-full bg-green-1"></span>
          <span className="text-xs text-blue-1">{t("text.active_agents")}</span>
          <span className="text-green-1">
            {analytics ? analytics.activeAgentsCount : "..."}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ActiveUserAgents;
