import { useEffect, useRef, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackSvg } from "../../components/icons";
import { receiveParcelNew } from "../../features/groups/GroupsSlice";
import { useAppContext } from "../../libs/AppContext";
import { reset } from "../../features/parcelTracking/DetailsSlice";
import ParcelPrint from "../../components/modals/groups/ParcelPrint";
import ParcelForm from "../../components/forms/parcelGroups/ParcelForm";
import ParcelGroupsLayout from "./ParcelGroupsLayout";
import Modal from "../../components/common/Modal";
import UploadParcelPictures from "../../components/modals/groupsNew/UploadParcelPictures";
import ParcelsTableInformative from "../../components/table/groupsNew/ParcelsTableInformative";

const AddStandartParcel = () => {
  const [formData, setFormData] = useState({
    parcelGroupId: "",
    tds_code: "",
    roomNumber: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
  });
  const [shouldPrint, setShouldPrint] = useState(false);
  const [printData, setPrintData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [subCategories, setSubCategories] = useState([]);

  const printRef = useRef();
  const parcelCodeInputRef = useRef(null);

  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parcelGroupId } = useParams();
  const { setParcelsRefetch, parcelsRefetch, setHighlightedParcel } =
    useAppContext();

  const { trackingDetails } = useSelector((state) => state.trackingDetails);

  useEffect(() => {
    setFormData(
      (prev) => ({
        ...prev,
        parcelGroupId: parcelGroupId,
      }),
      []
    );
  }, [parcelGroupId]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.tds_code) {
      newErrors.tds_code = "TDS code is required";
    }
    if (!formData.roomNumber) {
      newErrors.roomNumber = "Room number is required";
    }

    if (formData.parcelDetails.length === 1) {
      const detail = formData.parcelDetails[0];

      if (!detail.weight) {
        newErrors[`parcelDetails_0`] = {
          ...newErrors[`parcelDetails_0`],
          weight: "Weight is required",
        };
      }
    } else {
      formData.parcelDetails.forEach((detail, index) => {
        const parcelErrors = {};

        const hasFilledField =
          detail.weight ||
          detail.quantity ||
          detail.length ||
          detail.width ||
          detail.height;

        if (hasFilledField) {
          if (!detail.weight) {
            parcelErrors.weight = "Weight is required";
          }

          if (Object.keys(parcelErrors).length > 0) {
            newErrors[`parcelDetails_${index}`] = parcelErrors;
          }
        }
      });
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formattedParcelDetails = formData.parcelDetails
      .map((detail) => ({
        quantity: parseFloat(detail.quantity) || 0,
        weight: parseFloat(detail.weight) || 0,
        length: parseFloat(detail.length) || 0,
        width: parseFloat(detail.width) || 0,
        height: parseFloat(detail.height) || 0,
      }))
      .filter(
        (detail) =>
          detail.quantity !== 0 ||
          detail.weight !== 0 ||
          detail.length !== 0 ||
          detail.width !== 0 ||
          detail.height !== 0
      );

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "parcelDetails") {
        formDataToSend.append(key, JSON.stringify(formattedParcelDetails));
      } else if (key === "file[]") {
        // value.forEach((file) => {
        //   formDataToSend.append("file[]", file);
        // });

        if (
          trackingDetails &&
          trackingDetails?.length > 0 &&
          trackingDetails[0]?.imageUrl
        ) {
          value = value.slice(1);
        }

        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else if (key === "tds_code") {
        formDataToSend.append("tdsCode", value);
      } else if (key === "parcelId") {
        formDataToSend.append("parcelGroupId", parcelGroupId);
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(receiveParcelNew(formDataToSend));
      if (response.payload && response.payload.type === "success") {
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        setFormData((prev) => ({
          ...prev,
          tds_code: "",
          roomNumber: "",
          parcelDetails: [
            {
              quantity: "",
              weight: "",
              length: "",
              width: "",
              height: "",
            },
          ],
          warehouseComment: "",
          warehouseCategoryId: "",
          "file[]": [],
        }));
        setShouldPrint(false);
        setSelectedSubCategory("");
        setSelectedMainCategory("");
        setPrintData(response.payload.data);
        setParcelsRefetch(!parcelsRefetch);
        dispatch(reset());
        parcelCodeInputRef.current.focus();

        if (shouldPrint && printData) {
          setTimeout(() => handlePrint(), 0);
        }

        setHighlightedParcel(response.payload.data.tdsCode);
      }

      if (response.error && response.error.message === "Rejected") {
        setSeverity("error");
        setShowResponseMessage(true);
        setMessage(response.payload.message);
      }
    } catch (error) {}
  };

  const handlePrint = () => {
    if (formData.tds_code !== "") {
      const printContent = printRef.current.innerHTML;
      const newWindow = window.open("", "_blank");

      newWindow.document.write(`
        <html>
          <head>
            <title>Print Parcel</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: calc(100% - 77px);
              }
              .barcode {
                text-align: center;
                margin: 10px 0;
              }
            </style>
          </head>
          <body>
            ${printContent}
          </body>
        </html>
      `);

      newWindow.document.close();
      newWindow.focus();
      newWindow.print();
      newWindow.onafterprint = () => {
        newWindow.close();
        window.focus();
      };
    }
  };

  return (
    <ParcelGroupsLayout>
      <div>
        {showResponseMessage && (
          <Snackbar
            open={showResponseMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        )}
        <ParcelPrint printRef={printRef} data={printData} formData={formData} />
        <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
          <div className="flex items-center gap-3">
            <Link
              to={`/ParcelGroupNew/${parcelGroupId}/Parcels`}
              className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11 cursor-pointer"
            >
              <BackSvg />
            </Link>
            <div className="text-xl font-semibold text-gray-800">
              {t("text.add_standart_parcel")}
            </div>
          </div>
        </div>
        <Modal showmodal={showUploadModal} setShowModal={setShowUploadModal}>
          <UploadParcelPictures
            formData={formData}
            setFormData={setFormData}
            setShowUploadModal={setShowUploadModal}
          />
        </Modal>
        <ParcelForm
          handleSubmit={handleSubmit}
          setFormData={setFormData}
          formData={formData}
          setShouldPrint={setShouldPrint}
          shouldPrint={shouldPrint}
          errors={errors}
          setErrors={setErrors}
          setSelectedMainCategory={setSelectedMainCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          selectedMainCategory={selectedMainCategory}
          selectedSubCategory={selectedSubCategory}
          setShowUploadModal={setShowUploadModal}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          parcelCodeInputRef={parcelCodeInputRef}
        />
      </div>
      <ParcelsTableInformative />
    </ParcelGroupsLayout>
  );
};

export default AddStandartParcel;
