import axios from "axios";

const getTrackingParcels = async (
  token,
  tdsCode,
  roomNumber,
  declared,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    tdsCode: tdsCode,
    roomNumber: roomNumber,
    declared: declared,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI_W}/get_tracking_parcels`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const trackingParcelsService = { getTrackingParcels };
export default trackingParcelsService;
