import { useEffect } from "react";
import { useAppContext } from "./libs/AppContext";

const WebSocketConnection = ({
  setParcels,
  setStatuses,
  setMessage,
  setLastParcels,
}) => {
  const { setHighlightedParcel } = useAppContext();
  useEffect(() => {
    let socket;
    let pingInterval;

    const connectWebSocket = () => {
      socket = new WebSocket(
        "wss://middleware.spacecargo.ge:6002/app/livepost_key"
      );

      socket.onopen = () => {
        const subscriptionMessage = {
          event: "pusher:subscribe",
          data: {
            auth: "",
            channel: "public.warehouse.prod_3",
          },
        };
        socket.send(JSON.stringify(subscriptionMessage));

        pingInterval = setInterval(() => {
          socket.send(JSON.stringify({ event: "ping" }));
        }, 30000);
      };

      socket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          if (parsedData?.data) {
            const data = JSON.parse(parsedData?.data);

            if (parsedData.event === "error") {
              setStatuses((prevStatuses) => ({
                ...prevStatuses,
                warning: false,
                success: false,
                error: true,
              }));
              const dataArray = JSON.parse(parsedData.data);
              const message = dataArray[0];
              setMessage(message);
            }

            if (data?.trackingParcels) {
              setParcels((prevParcels) => {
                const updatedParcels = [...prevParcels];
                const existingParcelIndex = prevParcels.findIndex(
                  (parcel) => parcel.tdsCode === data.trackingParcels.tdsCode
                );

                if (existingParcelIndex !== -1) {
                  updatedParcels.splice(existingParcelIndex, 1);
                }

                updatedParcels.unshift(data.trackingParcels);

                setLastParcels(updatedParcels.slice(0, 10));

                const lastParcel = updatedParcels[0];
                if (lastParcel?.declared === "Y") {
                  setStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    success: true,
                    warning: false,
                    error: false,
                  }));
                } else if (lastParcel?.declared === "N") {
                  setStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    warning: true,
                    success: false,
                    error: false,
                  }));
                }

                if (lastParcel) {
                  setHighlightedParcel(lastParcel.tdsCode);

                  setTimeout(() => {
                    setHighlightedParcel(null);
                  }, 10000);
                }

                return updatedParcels;
              });
            }
          }
        } catch (error) {
          console.error(event.data, error);
          setStatuses((prevStatuses) => ({
            ...prevStatuses,
            warning: false,
            success: false,
            error: true,
          }));
        }
      };

      socket.onerror = (error) => {
        setStatuses((prevStatuses) => ({
          ...prevStatuses,
          warning: false,
          success: false,
          error: true,
        }));
      };

      socket.onclose = () => {
        connectWebSocket();
      };
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
        clearInterval(pingInterval);
      }
    };
  }, [
    setParcels,
    setStatuses,
    setMessage,
    setLastParcels,
    setHighlightedParcel,
  ]);

  return null;
};

export default WebSocketConnection;
