import { useTranslation } from "react-i18next";

const Processing = ({ handleCancel, handleProcessing }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed top-0 left-0 h-screen w-screen bg-black/10 backdrop-blur-sm flex justify-center items-start z-[99999999] p-3">
      <div className="max-w-max w-full mt-24 bg-white rounded-lg flex flex-col items-center p-5 md:p-10">
        <p className="text-sm font-semibold text-gray-900 text-center mb-2 md:text-lg">
          {t("text.move_project_to_completed_status_text")}
        </p>
        <span className="text-xs w-full px-5 font-normal text-gray-500 text-center md:p-0 md:w-2/3">
          {t("text.confirm_desc")}
        </span>
        <div className="w-2/3 flex justify-center items-center gap-3 mt-6">
          <button
            className="max-w-48 w-full text-xs font-medium rounded-lg py-2.5 px-6 border transition-opacity ease-in-out duration-500 text-blue-2 bg-blue-2/10 border-blue-2/40 hover:opacity-50"
            onClick={handleCancel}
          >
            {t("text.no")}
          </button>
          <button
            className="max-w-48 w-full text-xs font-medium rounded-lg py-2.5 px-6 border border-transparent transition-opacity ease-in-out duration-500 text-white bg-blue-2 hover:opacity-50"
            onClick={handleProcessing}
          >
            {t("text.yes")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Processing;
