import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import detailsService from "./DetailsService";

const initialState = {
  processingParcelGroupDetails: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getProcessingParcelGroupDetails = createAsyncThunk(
  "processing_parcel_group/get_processing_parcel_group_details",
  async (
    { processingParcelGroupId, tdsCode, roomNumber, status, page, perPage },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await detailsService.getProcessingParcelGroupDetails(
        token,
        processingParcelGroupId,
        tdsCode,
        roomNumber,
        status,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const changeProcessingParcel = createAsyncThunk(
  "processing_parcel_group/change_parcel_group_detail",
  async (parcelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await detailsService.changeProcessingParcel(parcelData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteProcessingParcel = createAsyncThunk(
  "processing_parcel_group/delete_parcel_group_detail",
  async (ProcessingParcelId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await detailsService.deleteProcessingParcel(
        ProcessingParcelId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const processingParcelGroupDetailsSlice = createSlice({
  name: "processingParcelGroupDetails",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessingParcelGroupDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProcessingParcelGroupDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.processingParcelGroupDetails = action.payload;
      })
      .addCase(getProcessingParcelGroupDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(changeProcessingParcel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeProcessingParcel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.processingParcelGroupDetails?.parcels.findIndex(
          (parcel) => parcel.id === action.payload.id
        );
        state.processingParcelGroupDetails.parcels[index] = action.payload;
      })
      .addCase(changeProcessingParcel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteProcessingParcel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProcessingParcel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.processingParcelGroupDetails =
          state.processingParcelGroupDetails?.parcels?.filter(
            (parcel) => parcel.id !== action.payload
          );
      })
      .addCase(deleteProcessingParcel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = processingParcelGroupDetailsSlice.actions;
export default processingParcelGroupDetailsSlice.reducer;
