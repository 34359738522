import PhotoUpload from "./PhotoUpload";
import Input from "./Input";

const AccountSetting = () => {
  const handleInputChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <div className="w-full flex flex-col items-start gap-6 border-b-2 border-gray-200 pb-10">
        <h2 className="text-lg font-medium text-gray-600">
          Your Profile Picture
        </h2>
        <PhotoUpload />
      </div>
      <div className="flex flex-col items-start gap-6 mt-10">
        <h2 className="text-lg font-medium text-gray-600">About you</h2>
        <div className="flex items-center justify-start gap-12 w-full flex-col sm:flex-row">
          <Input text="First Name" type="text" onChange={handleInputChange} />
          <Input text="Last Name" type="text" onChange={handleInputChange} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-6 mt-10">
        <h2 className="text-lg font-medium text-gray-600">Contact Details</h2>
        <Input text="Phone" type="text" onChange={handleInputChange} />
      </div>
      <button
        type="submit"
        className="bg-purple-500 rounded-full text-white text-base font-normal py-3 px-4 my-10 cursor-pointer transition-all duration-500 ease-in-out hover:bg-purple-600"
      >
        Save changes
      </button>
    </form>
  );
};

export default AccountSetting;
