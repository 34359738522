import axios from "axios";

const getParcels = async (
  token,
  parcelGroupId,
  tdsCode,
  roomNumber,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    parcelGroupId: parcelGroupId,
    tdsCode: tdsCode,
    roomNumber: roomNumber,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_parcels_new`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const registerIndividualParcel = async (token, parcelData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/receive_parcel_new3`,
      parcelData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateIndividualParcel = async (token, parcelData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel_group/change_parcel_new3`,
      parcelData,
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const parcelsService = {
  getParcels,
  registerIndividualParcel,
  updateIndividualParcel,
};
export default parcelsService;
