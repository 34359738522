import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { uploadImages } from "../../../features/processingParcelGroups/ImagesSlice";
import ModalsHeadline from "../components/ModalsHeadline";
import DragAndDropUploader from "../../common/shortList/DragAndDropUploader";

const UploadImages = ({ setShowModal, groupId }) => {
  const [uploadStatuses, setUploadStatuses] = useState({
    uploading: 0,
    success: 0,
    failed: 0,
  });
  const [resetStatuses, setResetStatuses] = useState(false);
  const [fileSize, setFileSize] = useState("");
  const [formData, setFormData] = useState({
    processingParcelGroupId: "",
    "file[]": [],
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      "file[]": [],
    }));

    setUploadStatuses({
      uploading: 0,
      success: 0,
      failed: 0,
    });
  }, [resetStatuses]);

  useEffect(() => {
    setUploadStatuses((prev) => ({
      ...prev,
      uploading: fileSize,
    }));
  }, [fileSize, resetStatuses]);

  const uploadFiles = async (files) => {
    setResetStatuses(!resetStatuses);
    setFileSize(files.length);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      setUploadStatuses((prev) => ({
        ...prev,
        uploading: prev.uploading - 1,
      }));

      try {
        const dataToSend = new FormData();

        dataToSend.append("file[]", file);
        dataToSend.append("processingParcelGroupId", groupId);

        const response = await dispatch(uploadImages(dataToSend));

        if (response.error && response.error.message === "Rejected") {
          setUploadStatuses((prev) => ({
            ...prev,
            failed: prev.failed + 1,
          }));
        } else if (
          response.meta &&
          response.meta.requestStatus === "fulfilled"
        ) {
          setUploadStatuses((prev) => ({
            ...prev,
            success: prev.success + 1,
          }));
        }
      } catch (error) {
        // failedCount++;
        // setUploadStatuses((prev) => ({
        //   ...prev,
        //   failed: prev.failed + 1,
        // }));
      }
    }

    setUploadStatuses((prev) => ({
      ...prev,
      uploading: 0,
    }));
  };

  return (
    <div>
      <ModalsHeadline
        text={t("text.upload_images")}
        onClick={() => setShowModal(false)}
      />
      <div className="p-6 w-full flex flex-col gap-4">
        <div className="relative w-full">
          <DragAndDropUploader
            formData={formData}
            setFormData={setFormData}
            uploadImages={uploadFiles}
            uploadStatuses={uploadStatuses}
            setUploadStatuses={setUploadStatuses}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadImages;
