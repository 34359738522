import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../libs/AppContext";
import { Alert, Snackbar } from "@mui/material";
import { getFlights } from "../../features/groups/flights/FlightsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../features/groups/departments/DepartmenstSlice";
import { getStatuses } from "../../features/groups/statuses/StatusesSlice";
import { deleteGroup } from "../../features/groups/groups/GroupsSlice";
import GroupsPropertisBar from "../../components/table/groupsNew/GroupsPropertisBar";
import ParcelGroupsLayout from "./ParcelGroupsLayout";
import ParcelGroupsTable from "../../components/table/groupsNew/ParcelGroupsTable";
import Message from "../../components/common/Message";
import AddButton from "../../components/common/AddButton";
import Modal from "../../components/common/Modal";
import AddGroup from "../../components/modals/groupsNew/AddGroup";
import EditGroup from "../../components/modals/groupsNew/EditGroup";
import ChangeStatus from "../../components/modals/groupsNew/ChangeStatus";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import SendEmail from "../../components/modals/groupsNew/SendEmail";
import SendSms from "../../components/modals/groupsNew/SendSms";
import ChangeRate from "../../components/modals/groupsNew/ChangeRate";
import { getConfig } from "../../features/groups/ConfigSlice";
import TransferExcel from "../../components/modals/groupsNew/TransferExcel";

const ParcelGroupsNew = () => {
  const [country, setCountry] = useState("");
  const [groupsIds, setGroupsIds] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [transferExcelModal, setTransferExcelModal] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [parcelGroupId, setParcelGroupId] = useState("");
  const [allStatusIsSame, setAllStatusIsSame] = useState(false);
  const [isStatusSent, setIsStatusSent] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showChangeRate, setShowChangeRate] = useState(false);
  const [showSendSms, setShowSendSms] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [changeStatusForm, setChangeStatusForm] = useState({
    parcelGroupIds: [],
    status: "",
  });
  const dispatch = useDispatch();

  const { showMainModal, setShowMainModal, isPermission, setRefetch, refetch } =
    useAppContext();
  const { groups } = useSelector((state) => state.parcelGroups);
  const { t } = useTranslation();

  useEffect(() => {
    setChangeStatusForm((prev) => ({
      ...prev,
      parcelGroupIds: groupsIds,
    }));
  }, [groupsIds]);

  useEffect(() => {
    dispatch(getDepartments());
    dispatch(getStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (country) {
      dispatch(getFlights(country));
      dispatch(getConfig({ warehouseCountryId: country }));
    }
  }, [dispatch, country]);

  useEffect(() => {
    const selectedParcelGroups = selectedRows.map((id) =>
      groups?.parcelGroups.find((group) => group.parcelGroupId === id)
    );

    const uniqueStatuses = new Set(
      selectedParcelGroups?.map((row) => row?.status)
    );
    const currentStatus = uniqueStatuses.values().next().value;

    if (uniqueStatuses.size === 1 && currentStatus !== "R") {
      setAllStatusIsSame(true);
    } else {
      setAllStatusIsSame(false);
    }

    if (uniqueStatuses.size === 1 && currentStatus === "S") {
      setIsStatusSent(true);
    } else {
      setIsStatusSent(false);
    }
  }, [groups?.parcelGroups, selectedRows]);

  const handleShowEditGroup = (id) => {
    setShowEditGroup(!showEditGroup);
    setParcelGroupId(id);
  };

  const handleShowChangeRate = () => {
    setShowChangeRate(!showChangeRate);
  };

  const handleShowSendSms = () => {
    setShowSendSms(!showSendSms);
  };

  const handleShowSendEmail = () => {
    setShowSendEmail(!showSendEmail);
  };

  const handleTransferExcel = (id) => {
    setTransferExcelModal(!transferExcelModal);
    setParcelGroupId(id);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setParcelGroupId(id);
  };

  const handleShowChangeStatus = () => {
    if (selectedRows.length === 0) {
      return;
    }

    const selectedParcelGroups = selectedRows.map((id) =>
      groups?.parcelGroups.find((group) => group.parcelGroupId === id)
    );

    const uniqueStatuses = new Set(
      selectedParcelGroups?.map((row) => row.status)
    );

    if (uniqueStatuses.size !== 1) {
      return;
    }

    const currentStatus = uniqueStatuses.values().next().value;

    let targetStatus;
    switch (currentStatus) {
      case "A":
        targetStatus = "S";
        break;
      case "S":
        targetStatus = "P";
        break;
      case "P":
        targetStatus = "R";
        break;
      default:
        throw new Error("Unsupported status:", currentStatus);
    }
    const permissionAction = `parcelGroupChangeStatus${targetStatus}`;
    if (!isPermission[permissionAction]?.action === permissionAction) {
      return;
    }

    setChangeStatusForm((prev) => ({
      ...prev,
      status: targetStatus,
    }));

    setShowChangeStatus(true);
  };

  const handleDeleteGroup = async () => {
    const groupsData = {
      parcelGroupIds: groupsIds,
    };

    const response = await dispatch(deleteGroup(groupsData));
    if (response.payload.type === "success") {
      setRefetch(!refetch);
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setGroupsIds([]);
      setSelectedRows([]);
      setSeverity("success");
      setMessage(response.payload.message);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setGroupsIds([]);
      setSelectedRows([]);
      setSeverity("error");
      setMessage(response.payload.message);
    }
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  return (
    <ParcelGroupsLayout setCountry={setCountry}>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddGroup
          setMessage={setMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowModal={setShowMainModal}
          setSeverity={setSeverity}
        />
      </Modal>
      <Modal showmodal={showEditGroup} setShowModal={setShowEditGroup}>
        <EditGroup
          setShowEditGroup={setShowEditGroup}
          setMessage={setMessage}
          setShowResponseMessage={setShowResponseMessage}
          setSeverity={setSeverity}
          parcelGroupId={parcelGroupId}
        />
      </Modal>
      <Modal showmodal={showChangeRate} setShowModal={setShowChangeRate}>
        <ChangeRate
          setShowChangeRate={setShowChangeRate}
          closeModal={handleShowChangeRate}
          groupsIds={groupsIds}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
          setMessage={setMessage}
          setShowResponseMessage={setShowResponseMessage}
          setSeverity={setSeverity}
        />
      </Modal>
      <Modal showmodal={showSendSms} setShowModal={setShowSendSms}>
        <SendSms
          setShowSendSms={setShowSendSms}
          closeModal={handleShowSendSms}
          groupsIds={groupsIds}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
          setMessage={setMessage}
          setShowResponseMessage={setShowResponseMessage}
          setSeverity={setSeverity}
        />
      </Modal>
      <Modal showmodal={showSendEmail} setShowModal={setShowSendEmail}>
        <SendEmail
          setShowSendEmail={setShowSendEmail}
          closeModal={handleShowSendEmail}
          groupsIds={groupsIds}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
          setMessage={setMessage}
          setShowResponseMessage={setShowResponseMessage}
          setSeverity={setSeverity}
        />
      </Modal>
      <Modal
        showmodal={transferExcelModal}
        setShowModal={setTransferExcelModal}
      >
        <TransferExcel
          handleShowModal={() => setTransferExcelModal(false)}
          parcelGroupId={parcelGroupId}
          setTransferExcelModal={setTransferExcelModal}
          setParcelGroupId={setParcelGroupId}
          setShowResponseMessage={setShowResponseMessage}
          setSeverity={setSeverity}
          setMessage={setMessage}
        />
      </Modal>
      {showChangeStatus && (
        <ChangeStatus
          setShowChangeStatus={setShowChangeStatus}
          changeStatusForm={changeStatusForm}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
          setMessage={setMessage}
          setShowResponseMessage={setShowResponseMessage}
          setSeverity={setSeverity}
        />
      )}
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteGroup}
          text={t("text.groups")}
        />
      )}
      <GroupsPropertisBar
        groupsIds={groupsIds}
        handleShowChangeRate={handleShowChangeRate}
        handleShowSendSms={handleShowSendSms}
        handleShowSendEmail={handleShowSendEmail}
        handleShowChangeStatus={handleShowChangeStatus}
        handleDeleteConfirmation={handleDeleteConfirmation}
        allStatusIsSame={allStatusIsSame}
        isStatusSent={isStatusSent}
      />
      {country ? (
        <ParcelGroupsTable
          setGroupsIds={setGroupsIds}
          handleShowEditGroup={handleShowEditGroup}
          groupsIds={groupsIds}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          handleTransferExcel={handleTransferExcel}
        />
      ) : (
        <Message text={t("text.select_country")} />
      )}

      {country && <AddButton />}
    </ParcelGroupsLayout>
  );
};

export default ParcelGroupsNew;
