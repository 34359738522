import { useState } from "react";

const DragAndDropUploader = ({
  formData,
  setFormData,
  uploadImages,
  uploadStatuses,
}) => {
  const [isDraged, setIsDraged] = useState(false);

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = e.dataTransfer.files;
    const updatedFiles = [...formData["file[]"], ...Array.from(newFiles)];

    setFormData((prevFormData) => ({
      ...prevFormData,
      "file[]": updatedFiles,
    }));

    uploadImages(updatedFiles);
  };

  const handleEnter = () => {
    setIsDraged(true);
  };

  const handleLeave = () => {
    setIsDraged(false);
  };

  const handleUpload = (e) => {
    const newFiles = e.target.files;

    setFormData((prevFormData) => {
      const updatedFiles = [...prevFormData["file[]"], ...Array.from(newFiles)];

      uploadImages(updatedFiles);
      return {
        ...prevFormData,
        "file[]": updatedFiles,
      };
    });
  };

  return (
    <div>
      <div
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={handleEnter}
        onDragLeave={handleLeave}
        onDrop={handleDrop}
        className={`${isDraged ? "border-blue-1/20 shadow-md" : "border-blue-1/20 "} z-20 border flex justify-center items-center px-4 py-12 rounded-xl text-sm text-blue-1 transition-all duration-300 cursor-pointer`}
      >
        <input
          type="file"
          multiple
          accept="image/*"
          className="hidden"
          id="fileInput"
          onChange={handleUpload}
        />
        <label htmlFor="fileInput" className="cursor-pointer">
          Drag & Drop images or{" "}
          <span className="font-semibold pl-1">Browse</span>
        </label>
      </div>
      <div className="flex flex-col items-start p-4 uppercase">
        <p className="text-xs md:text-base text-blue-2">
          მიმდინარე ატვირთვა: <b>{uploadStatuses.uploading}</b>{" "}
        </p>
        <p className="text-xs md:text-base text-green-2">
          წარმატებით ატვირთული: <b>{uploadStatuses.success}</b>
        </p>
        <p className="text-xs md:text-base text-red-1">
          დახარვეზებული: <b>{uploadStatuses.failed}</b>
        </p>
      </div>
    </div>
  );
};

export default DragAndDropUploader;
