import { Link } from "react-router-dom";
import { BackSvg } from "../../components/icons";
import { useTranslation } from "react-i18next";
import ProfileLayout from "../../components/layouts/ProfileLayout";
import useLinkToNavigate from "../../libs/useLinkToNavigate";

const Profile = () => {
  const { t } = useTranslation();
  const linkToNavigate = useLinkToNavigate();

  return (
    <div className="w-full p-2 border-b border-black/5 bg-gray-100 md:p-4">
      <div className="flex items-center justify-start gap-3 w-full">
        <Link
          to={linkToNavigate}
          className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
        >
          <BackSvg />
        </Link>
        <div className="text-lg font-semibold text-gray-800 md:text-xl">
          {t("text.user")}
        </div>
      </div>
      <ProfileLayout />
    </div>
  );
};

export default Profile;
