import axios from "axios";

const getLinks = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/permissions/get_user_permissions`,
    config
  );

  return response.data;
};

const menuService = {
  getLinks,
};

export default menuService;
