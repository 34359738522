import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ButtonDefault from "../../buttons/ButtonDefault";
import grammerlySVG from "../../../assets/grammerly.svg";
import ModalsHeadline from "../components/ModalsHeadline";
import { sendSms } from "../../../features/groups/GroupsSlice";
import { useTranslation } from "react-i18next";

const SendSms = ({
  closeModal,
  setShowSendSms,
  groupsIds,
  setGroupsIds,
  setSelectedRows,
  setMessage,
  setShowResponseMessage,
  setSeverity,
}) => {
  const [formData, setFormData] = useState({
    parcelGroupIds: [],
    smsText: "",
  });
  const [errors, setErrors] = useState({
    smsText: "",
  });
  const [value, setValue] = useState("");
  const [rows, setRows] = useState(1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parcelGroupIds, smsText } = formData;

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      parcelGroupIds: groupsIds,
      smsText: value,
    }));
  }, [value, groupsIds]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const textareaLineHeight = 20;
    event.target.rows = 1;
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    event.target.rows = currentRows;
    setRows(currentRows);
    setValue(event.target.value);
  };

  const handleSendSms = async (e) => {
    e.preventDefault();
    if (!smsText) {
      setErrors({
        smsText: t("text.field_required"),
      });
      return;
    }

    const smsData = { parcelGroupIds, smsText };

    try {
      const response = await dispatch(sendSms(smsData));
      if (response.payload.type === "success") {
        setShowSendSms(false);
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        setGroupsIds([]);
        setSelectedRows([]);
        setFormData({
          smsText: "",
        });
      }

      if (response.error.message === "Rejected") {
        setShowSendSms(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors({
          smsText: error.response.data.smsText || "",
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleSendSms}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text={t("text.sendSms")} onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <div
              className={`relative w-full mt-4 border ${
                errors.smsText ? "border-red-500" : "border-gray-2"
              } rounded-lg p-3 bg-no-repeat bg-right-bottom`}
              style={{
                backgroundImage: `url(${grammerlySVG})`,
                paddingBottom: "45px",
              }}
            >
              <textarea
                placeholder={t("text.Message")}
                name="smsText"
                value={value}
                onChange={handleChange}
                rows={rows}
                className="w-full outline-none min-h-56 h-full text-base font-normal text-gray-6 resize-none border-none placeholder:text-gray-4 placeholder:font-normal"
              />
            </div>
            {errors.smsText && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.smsText}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.send")}</ButtonDefault>
      </div>
    </form>
  );
};

export default SendSms;
