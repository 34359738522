import axios from "axios";

const getCompanies = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/get_project_companies`,
    config
  );

  return response.data;
};

const registerCompany = async (companyData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/register_company`,
    companyData,
    config
  );

  return response.data;
};

const updateCompany = async (updatedCompanyData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/change_company`,
    updatedCompanyData,
    config
  );

  return response.data;
};

const deleteCompany = async (companyId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delete_company`,
    companyId,
    config
  );

  return response.data;
};

const getBanks = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/get_banks`,
    config
  );

  return response.data;
};

const companiesService = {
  getCompanies,
  registerCompany,
  updateCompany,
  deleteCompany,
  getBanks,
};

export default companiesService;
