import axios from "axios";

const uploadFile = async (token, file) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/notification/upload_file`,
    file,
    config
  );

  return response.data;
};

const fileService = { uploadFile };
export default fileService;
