import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import Message from "../common/Message";
import { FilterSvg } from "../icons";
import { useAppContext } from "../../libs/AppContext";

const LargeTable = ({
  config,
  data,
  setFormData,
  formData,
  handleShowFilters,
}) => {
  const { highlightedParcel } = useAppContext();
  const { t } = useTranslation();

  return (
    <div className="relative px-4 pt-12 xl:p-0">
      <button
        onClick={handleShowFilters}
        type="button"
        className="absolute right-4 top-4 xl:hidden"
      >
        <FilterSvg />
      </button>
      <table className="custom-table border-collapse bg-white w-full grid grid-cols-2 xl:bg-white xl:!inline-table">
        <thead className="text-sm text-blue-4">
          {data?.length > 0 ? (
            data?.map((item, indexP) => (
              <tr
                key={indexP}
                className="bg-gray-100 flex flex-col flex-nowrap border-gray-1/15 border-l border-t border-b xl:border-none xl:hidden first:xl:table-row rounded-l-lg xl:rounded-none mb-4 xl:mb-0"
              >
                {config.columns.map((column, index) => (
                  <>
                    {column.condition && (
                      <th
                        key={index}
                        className={`${
                          column.filterable && "min-w-20"
                        } min-w-3 relative py-3 px-2 font-medium h-10 border-gray-1/15 border-r border-b xl:h-auto ${
                          column.important ? "w-full xl:w-max" : "xl:max-w-20"
                        }`}
                      >
                        <div className="flex justify-between items-center">
                          {column.checkbox ? (
                            <span
                              className={`${
                                column.filterable && "max-w-[calc(100%-20px)]"
                              } text-start truncate text-xs absolute -top-8 ${
                                indexP === 0 ? "flex" : "hidden"
                              } justify-center items-center gap-2 xl:static`}
                            >
                              {column.header}
                              <span className="text-xs xl:hidden">
                                {t("text.select")} (All)
                              </span>
                            </span>
                          ) : (
                            <Tooltip title={`${column.header}`}>
                              <span
                                className={`${
                                  column.filterable && "max-w-[calc(100%-20px)]"
                                } text-start truncate text-xs`}
                              >
                                {column.total > 0 && `(${column.total})`}{" "}
                                {column.header}
                              </span>
                            </Tooltip>
                          )}

                          {column.filterable && (
                            <div className="hidden xl:flex">
                              {column.filterComponent(
                                formData,
                                setFormData,
                                column.key
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    )}
                  </>
                ))}
                {config.columns.find((col) => col.actions) && (
                  <th className="py-3 px-2 text-left text-xs font-medium h-10 border-r border-gray-1/15 xl:border-b xl:h-auto">
                    {t("text.actions")}
                  </th>
                )}
              </tr>
            ))
          ) : (
            <tr className="bg-gray-100 hidden border-red-300 border-l border-t border-b xl:border-none xl:hidden first:xl:table-row rounded-l-lg xl:rounded-none mb-4 xl:mb-0">
              {config.columns.map((column, index) => (
                <>
                  {column.condition && (
                    <th
                      key={index}
                      className={`${
                        column.filterable && "min-w-20"
                      } min-w-3 relative py-3 px-2 font-medium h-10 border-gray-1/15 border-r border-b xl:h-auto ${
                        column.important ? "w-full xl:w-max" : "xl:max-w-20"
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        {column.checkbox ? (
                          <span
                            className={`${
                              column.filterable && "max-w-[calc(100%-20px)]"
                            } text-start truncate text-xs absolute -top-8 justify-center items-center gap-2 xl:static`}
                          >
                            {column.header}
                            <span className="text-xs xl:hidden">
                              {t("text.select")} (All)
                            </span>
                          </span>
                        ) : (
                          <Tooltip title={`${column.header}`}>
                            <span
                              className={`${
                                column.filterable && "max-w-[calc(100%-20px)]"
                              } text-start truncate text-xs`}
                            >
                              {column.total > 0 && `(${column.total})`}{" "}
                              {column.header}
                            </span>
                          </Tooltip>
                        )}

                        {column.filterable && (
                          <div className="hidden xl:flex">
                            {column.filterComponent(
                              formData,
                              setFormData,
                              column.key
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  )}
                </>
              ))}
              {config.columns.find((col) => col.actions) && (
                <th className="py-3 px-2 text-left text-xs font-medium h-10 border-r border-gray-1/15 xl:border-b xl:h-auto">
                  {t("text.actions")}
                </th>
              )}
            </tr>
          )}
        </thead>
        <tbody className="flex-1 xl:flex-none">
          {data?.length > 0 ? (
            <>
              {data?.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`flex flex-col flex-nowrap border-gray-1/15 border-r border-t border-b rounded-r-lg xl:rounded-none xl:border-none xl:table-row mb-4 xl:mb-0 ${config.animation && row.tdsCode === highlightedParcel ? "blinking" : ""}`}
                >
                  {config.columns.map((column, colIndex) => (
                    <>
                      {column.condition && (
                        <td
                          key={colIndex}
                          className={`hover:bg-gray-100 py-3 px-2 text-xs h-10 border-gray-1/15 border-b  min-w-3 xl:border-r xl:h-auto ${
                            column.important ? "w-full xl:w-max" : "xl:max-w-5"
                          }`}
                        >
                          <div className="truncate">
                            {column.checkbox || column.label ? (
                              <span
                                className={`${
                                  column.hasClassName && column.className(row)
                                } w-full absolute left-4 xl:static`}
                              >
                                {column.cell?.(row) || row[column.key] || "..."}
                              </span>
                            ) : (
                              <Tooltip
                                title={
                                  column.cell?.(row) ||
                                  (column.translateable &&
                                    t(row[column.key])) ||
                                  row[column.key] ||
                                  "..."
                                }
                              >
                                <span
                                  className={`${
                                    column.hasClassName && column.className(row)
                                  } w-full`}
                                >
                                  {column.cell?.(row) ||
                                    (column.translateable &&
                                      t(row[column.key])) ||
                                    row[column.key] ||
                                    "..."}
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                      )}
                    </>
                  ))}
                  {config.columns.find((col) => col.actions) && (
                    <td className="py-3 px-2 text-gray-1 text-md hover:text-blue-1 transition-all duration-300 cursor-pointer h-10 xl:border-gray-1/15 xl:border-b xl:h-auto">
                      <div className="flex justify-between items-center w-full gap-2 xl:w-max">
                        {config.columns.find((col) => col.actions) &&
                          config.columns
                            .find((col) => col.actions)
                            ?.actions(row)}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr className="w-full h-full">
              <td
                className="border-none p-2 absolute left-1/2 transform -translate-x-1/2 xl:static xl:transform-none"
                colSpan={config.columns?.length + 2}
              >
                <Message text={t("text.not_found")} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default LargeTable;
