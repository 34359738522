import axios from "axios";

const getEmployees = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/projects/get_delegation_users`,
    config
  );

  return response.data;
};

const registerEmployee = async (employeeData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/register_user`,
    employeeData,
    config
  );

  return response.data;
};

const deleteEmployee = async (employeeId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/projects/delegation/delete_user_department_link`,
    employeeId,
    config
  );

  return response.data;
};

const employeesService = {
  getEmployees,
  registerEmployee,
  deleteEmployee,
};

export default employeesService;
