import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import potentialUsersService from "./PotentialUsersService";

const initialState = {
  potentialUsers: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getPotentialUsers = createAsyncThunk(
  "potential_user/get_potential_users",
  async (
    {
      firstName,
      lastName,
      pinOrInn,
      address,
      mobile,
      email,
      additionalInformation,
      status,
      page,
      perPage,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await potentialUsersService.getPotentialUsers(
        token,
        firstName,
        lastName,
        pinOrInn,
        address,
        mobile,
        email,
        additionalInformation,
        status,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deletePotentialUser = createAsyncThunk(
  "potential_user/delete_potential_user",
  async (potentialUserId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await potentialUsersService.deletePotentialUser(
        potentialUserId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const registerPotentialUser = createAsyncThunk(
  "potential_user/register_potential_user",
  async (potentialUserData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await potentialUsersService.registerPotentialUser(
        potentialUserData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const updatePotentialUser = createAsyncThunk(
  "potential_user/change_potential_user",
  async (updatedPotentialUserData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await potentialUsersService.updatePotentialUser(
        updatedPotentialUserData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const potentialUsersSlice = createSlice({
  name: "potentialUsers",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPotentialUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPotentialUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.potentialUsers = action.payload;
      })
      .addCase(getPotentialUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletePotentialUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePotentialUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.potentialUsers = state.potentialUsers?.users?.filter(
          (user) => user.id !== action.payload
        );
      })
      .addCase(deletePotentialUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerPotentialUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerPotentialUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.potentialUsers?.potentialUsers.unshift(action.payload);
      })
      .addCase(registerPotentialUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePotentialUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePotentialUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.potentialUsers?.potentialUsers.findIndex(
          (user) => user.id === action.payload.id
        );
        state.potentialUsers.potentialUsers[index] = action.payload;
      })
      .addCase(updatePotentialUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = potentialUsersSlice.actions;
export default potentialUsersSlice.reducer;
