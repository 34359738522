import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import {
  DeleteSvg2,
  DetailsPageSvg,
  DoneSvg,
  EditSvg3,
} from "../components/icons";
import { MdDownload } from "react-icons/md";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { exportProcessingGroups } from "../features/processingParcelGroups/ParcelGroupsSlice";
import { useDispatch, useSelector } from "react-redux";
import TableSearch2 from "../components/common/shortList/TableSearch2";
import Checkbox from "../components/common/Checkbox";
import CheckAll from "../components/common/CheckAll";
import SelectToFilter from "../components/common/shortList/SelectToFilter";

const useProcessingParcelsConfig = (
  formData,
  setFormData,
  handleEditBarcode,
  handleUploadImages,
  handleProcessing,
  handleDeleteConfirmation
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { statuses } = useSelector((state) => state.processingStatuses);

  const CheckboxComponent = 2 === 3 ? Checkbox : CheckAll;

  const handleExport = async (id) => {
    try {
      const action = await dispatch(exportProcessingGroups({ id: id }));
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData?.length);
      for (let i = 0; i < decodedData?.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const getStatusClassName = (statusSymbol, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    console.log(status);
    return status ? `${status?.status.toLowerCase()}-processing` : "";
  };

  const actionConfig = [
    {
      type: "button",
      permission: "edit",
      action: (row) => ({
        onClick: () => handleEditBarcode(row.id),
        icon: <EditSvg3 />,
        tooltip: t("text.edit"),
      }),
      condition: (row) => row.actions?.includes("changeParcelGroupBarcode"),
    },
    {
      type: "button",
      permission: "downloadExcel",
      action: (row) => ({
        onClick: () => handleExport(row.id),
        icon: <MdDownload />,
        tooltip: t("text.excel"),
      }),
      condition: (row) => row.actions?.includes("downloadExcel"),
    },
    {
      type: "link",
      permission: "showDetails",
      action: (row) => ({
        to: `/processingParcelGroups/${row?.id}`,
        icon: <DetailsPageSvg />,
        tooltip: t("text.details"),
      }),
      condition: (row) => row.actions?.includes("showDetails"),
    },
    {
      type: "button",
      permission: "uploadImages",
      action: (row) => ({
        onClick: () => handleUploadImages(row.id),
        icon: <IoCloudUploadOutline />,
        tooltip: t("text.upload_images"),
      }),
      condition: (row) => row.actions?.includes("uploadImages"),
    },
    {
      type: "button",
      permission: "processing",
      action: (row) => ({
        onClick: () => handleProcessing(row.id),
        icon: <DoneSvg />,
        tooltip: t("text.Processing"),
      }),
      condition: (row) => row.actions?.includes("processing"),
    },
    {
      type: "button",
      permission: "delete",
      action: (row) => ({
        onClick: () => handleDeleteConfirmation(row.id),
        icon: <DeleteSvg2 />,
        tooltip: t("text.delete"),
      }),
      condition: (row) => row.actions?.includes("delete"),
    },
    {
      type: "button",
      permission: "googleRecognition",
      action: (row) => ({
        //   onClick: () => handleEditReferralAgent(row.id),
        icon: <FcGoogle />,
        tooltip: t("text.recognize"),
      }),
      condition: (row) => row.actions?.includes("googleRecognition"),
    },
  ];

  const getActions = (row) => {
    return actionConfig
      .filter((action) => action.condition(row))
      .map((action, index) => (
        <Tooltip title={action.action(row).tooltip} key={index}>
          {action.type === "link" ? (
            <Link
              to={action.action(row).to}
              onClick={action.action(row).onClick}
              target={action.action(row).target || undefined}
            >
              {action.action(row).icon}
            </Link>
          ) : (
            <button onClick={action.action(row).onClick}>
              {action.action(row).icon}
            </button>
          )}
        </Tooltip>
      ));
  };

  return {
    columns: [
      {
        key: "",
        header: <CheckboxComponent checked={true} onChange={() => true} />,
        checkbox: true,
        filterable: false,
        cell: (row) => <Checkbox checked={true} onChange={() => true} />,
        actions: getActions,
        condition: true,
      },
      {
        key: "fileName",
        header: t("text.file_name"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "userName",
        header: t("text.member"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "parcelGroupBarcode",
        header: t("text.barcode"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "inpDate",
        header: t("text.date"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
      {
        key: "statusDesc",
        header: t("text.status"),
        translateable: true,
        actions: getActions,
        condition: true,
        filterable: true,
        hasClassName: true,
        filterComponent: () => (
          <SelectToFilter
            options={statuses?.map((cat) => ({
              value: cat.status,
              label: t(cat.statusDictionaryKey),
            }))}
            name="status"
            value={formData.status}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        className: (row) => getStatusClassName(row.status, statuses),
      },
      {
        key: "totalWeight",
        header: t("text.total_weight"),
        filterable: false,
        actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useProcessingParcelsConfig;
