const InputSmall = (props) => {
  const {
    isicon,
    icon,
    handleshowpassword,
    name,
    value,
    onchange,
    errormark,
    type,
    text,
    inputRef,
    onKeyDown,
  } = props;

  return (
    <div
      className={`relative w-full rounded-xl overflow-hidden bg-white ${
        errormark === "error-border" ? "border-red-500" : "border-gray-200"
      } border`}
    >
      {isicon === "isicon" && (
        <span
          onClick={handleshowpassword}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 flex justify-center items-center h-4 w-4 md:h-5 md:w-5"
        >
          {icon}
        </span>
      )}
      <input
        ref={inputRef}
        className={`${
          isicon === "isicon" ? "pr-4 pl-9" : "px-4"
        } input w-full transition-all duration-500 ease-in-out pt-4 pb-1 text-gray-1 text-sm font-normal placeholder-transparent focus:outline-none md:pt-4 md:pb-1`}
        type={type}
        placeholder=" "
        name={name}
        value={value}
        onChange={onchange}
        onKeyDown={onKeyDown}
        autoComplete="off"
      />
      <span
        className={`${
          isicon === "isicon" ? "left-9" : " left-4"
        } placeholder-span absolute top-1/2 transform -translate-y-1/2 text-gray-1 text-xs truncate font-normal pointer-events-none w-[calc(100%-45px)]`}
      >
        {text}
      </span>
    </div>
  );
};

export default InputSmall;
