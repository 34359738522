import axios from "axios";

const getFlights = async (token, warehouseCountryId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_flights`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const flightsService = { getFlights };
export default flightsService;
