import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import periodsService from "./PeriodsService";

const initialState = {
  periods: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getPeriods = createAsyncThunk(
  "report/get_periods",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await periodsService.getPeriods(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const periodsSlice = createSlice({
  name: "periods",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPeriods.pending, (state) => {
        state.periodsLoading = true;
      })
      .addCase(getPeriods.fulfilled, (state, action) => {
        state.periodsLoading = false;
        state.isSuccess = true;
        state.periods = action.payload;
        state.statusCode = 200;
      })
      .addCase(getPeriods.rejected, (state, action) => {
        state.periodsLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = periodsSlice.actions;
export default periodsSlice.reducer;
