import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import logo from "../../../assets/space-cargo-logo.jpg";

const BarcodeModal = ({ setShowModal, barcodeImageValue }) => {
  const { t } = useTranslation();
  const barcode = useSelector((state) => state.groups?.barcode);

  const printWindowRef = useRef(null);

  const getFormattedDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const handlePrint = (e) => {
    e.preventDefault();
    const newWindow = window.open("", "_blank", "width=1000, height=800");

    if (!newWindow) {
      console.error("Failed to open a new window");
      return;
    }

    printWindowRef.current = newWindow;

    const fullLogoUrl = window.location.origin + logo;
    const fullBarcodeImageUrl = barcode.barcodeImage;

    newWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
            html, body {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0;
              padding: 0;
              box-sizing: border-box;
            }
            .content {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .logo {
              max-width: 70px;
              width: 100%;
            }
            h1 {
              margin: 5px 0;
              font-size: 18px;
              font-weight: 600;
            }
            h2 {
              margin: 1px 0;
              font-size: 14px;
              font-weight: 600;
            }
            .barcode {
              max-width: 150px;
              height: 50px;
              margin-top: 10px;
            }
            .barcode img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
  
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
              @page {
                margin: 0;
              }
              .content {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          </style>
        </head>
        <body>
          <div class="content">
            <img class="logo mb-2" src="${fullLogoUrl}" alt="Barcode" width="100" height="43" />
            <h1 class="text-2xl text-blue-900 mb-2 font-bold">TBS</h1>
            <h2 class="text-base font-medium text-blue-900 mb-1">${getFormattedDate()}</h2>
            <h2 class="text-base font-medium text-blue-900 mb-1">${t("text.barcode")}</h2>
            <h2 class="text-base font-medium text-blue-900 mb-1">${barcodeImageValue.barcode}</h2>
            <div class="bg-gray-100 p-4 rounded-md mt-2 w-full sm:w-auto barcode">
              <img src="${fullBarcodeImageUrl}" alt="barcode" class="w-full object-contain" />
            </div>
          </div>
        </body>
      </html>
    `);

    newWindow.document.close();
    newWindow.focus();
    newWindow.print();

    newWindow.onafterprint = () => {
      newWindow.close();
      setShowModal(false);
    };

    newWindow.onbeforeunload = () => {
      setShowModal(false);
    };
  };

  const handleCloseModal = () => {
    if (printWindowRef.current) {
      printWindowRef.current.close();
    }
    setShowModal(false);
  };

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-black bg-opacity-10 backdrop-blur-sm z-[9999999] flex justify-center items-center p-6">
      <div className="relative max-w-md w-full bg-white p-6 flex flex-col items-center justify-center rounded-lg">
        <div className="flex flex-col items-center">
          <img
            className="logo mb-2"
            src={logo}
            alt="Barcode"
            width={100}
            height={43}
          />
          <h1 className="text-2xl text-blue-900 mb-2 font-bold">TBS</h1>
          <h2 className="text-base font-medium text-blue-900 mb-1">
            {getFormattedDate()}
          </h2>
          <h2 className="text-base font-medium text-blue-900 mb-1">
            {t("text.barcode")}
          </h2>
          <h2 className="text-base font-medium text-blue-900 mb-1">
            {barcodeImageValue.barcode}
          </h2>
          <div className="bg-gray-100 p-4 rounded-md mt-2 w-full sm:w-auto barcode">
            <img
              src={barcode.barcodeImage}
              alt="barcode"
              className="w-full object-contain"
            />
          </div>
        </div>

        <div className="flex justify-center items-center gap-4 mt-7 no-print">
          <button
            className="text-blue-500 text-sm bg-gray-200 px-7 py-2 rounded-md hover:bg-gray-300 transition-colors"
            onClick={handleCloseModal}
          >
            {t("text.close")}
          </button>
          <button
            className="text-white bg-blue-600 text-sm px-7 py-2 rounded-md hover:bg-blue-700 transition-colors"
            onClick={handlePrint}
          >
            {t("text.print")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BarcodeModal;
