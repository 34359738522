import axios from "axios";

const getAgents = async (
  token,
  firstName,
  lastName,
  pinOrInn,
  mobile,
  status,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    firstName: firstName,
    lastName: lastName,
    pinOrInn: pinOrInn,
    mobile: mobile,
    status: status,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/referral/get_referral_agents`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const registerAgent = async (agentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/referral/register_referral_agent`,
    agentData,
    config
  );

  return response.data;
};

const changeAgent = async (agentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/referral/change_referral_user`,
    agentData,
    config
  );

  return response.data;
};

const deleteAgent = async (agentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/referral/delete_referral_user`,
    agentId,
    config
  );

  return response.data;
};

const agents = { getAgents, registerAgent, changeAgent, deleteAgent };

export default agents;
