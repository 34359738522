import axios from "axios";

const genetareTrackingCode = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_URI}/parcel/generate_tracking_code`,
      {},
      config
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

const trackingCodeService = { genetareTrackingCode };

export default trackingCodeService;
