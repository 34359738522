import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import calculationsService from "./CalculationsService";

const initialState = {
  price: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const calculatePrice = createAsyncThunk(
  "parcel_group/calculate_price",
  async (parcelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await calculationsService.calculatePrice(token, parcelData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const calculationsSlice = createSlice({
  name: "calculations",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculatePrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(calculatePrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.price = action.payload;
      })
      .addCase(calculatePrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = calculationsSlice.actions;
export default calculationsSlice.reducer;
