import axios from "axios";

const getRacesProgress = async (
  token,
  reportType,
  groupPeriod,
  departmentId
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const getRacesProgressDetails = async (
  token,
  reportType,
  groupPeriod,
  departmentId,
  countryCode,
  page,
  perPage,
  userName,
  roomNumber,
  sendStartDate,
  sendEndDate,
  warehouseStartDate,
  warehouseEndDate,
  tdsCode,
  arrivedStartDate,
  arrivedEndDate
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
    countryCode: countryCode,
    page: page,
    perPage: perPage,
    userName: userName,
    roomNumber: roomNumber,
    sendStartDate: sendStartDate,
    sendEndDate: sendEndDate,
    warehouseStartDate: warehouseStartDate,
    warehouseEndDate: warehouseEndDate,
    tdsCode: tdsCode,
    arrivedStartDate: arrivedStartDate,
    arrivedEndDate: arrivedEndDate,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report_details`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const exportExcel = async (
  token,
  reportType,
  groupPeriod,
  departmentId,
  countryCode,
  page,
  perPage,
  userName,
  roomNumber,
  sendStartDate,
  sendEndDate,
  warehouseStartDate,
  warehouseEndDate,
  tdsCode,
  arrivedStartDate,
  arrivedEndDate
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
    groupPeriod: groupPeriod,
    departmentId: departmentId,
    countryCode: countryCode,
    page: page,
    perPage: perPage,
    userName: userName,
    roomNumber: roomNumber,
    sendStartDate: sendStartDate,
    sendEndDate: sendEndDate,
    warehouseStartDate: warehouseStartDate,
    warehouseEndDate: warehouseEndDate,
    tdsCode: tdsCode,
    arrivedStartDate: arrivedStartDate,
    arrivedEndDate: arrivedEndDate,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report_details_excel`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const getCountries = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_countries`,
    config
  );

  return response.data;
};

const racesProgressService = {
  getRacesProgress,
  getRacesProgressDetails,
  exportExcel,
  getCountries,
};

export default racesProgressService;
