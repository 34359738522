import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowRightSvg } from "../icons";

const CustomLink = ({ url, text, image }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-blue-2/10 rounded-xl flex flex-col items-center p-4 md:px-10 md:py-6">
      <h2 className="text-blue-2 text-sm font-bold text-center mb-3 md:text-base">
        {text}
      </h2>
      <img
        className="max-w-24 w-full object-contain h-auto md:max-w-60"
        src={image}
        alt=""
        height={300}
        width={300}
      />
      <Link
        to={url}
        className="flex justify-center items-center w-max rounded-xl transition-all duration-300 md:hover:shadow-xl md:mt-4  md:py-2 md:px-4"
      >
        <span className="text-[10px] font-bold text-blue-2 md:text-xs">
          {t("text.view_details")}
        </span>
        <ArrowRightSvg fill="#416ff4" />
      </Link>
    </div>
  );
};

export default CustomLink;
