import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import countriesService from "./GetCountriesService";

const initialState = {
  countries: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCountries = createAsyncThunk(
  "parcel/get_countries",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await countriesService.getCountries(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = countriesSlice.actions;
export default countriesSlice.reducer;
