import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import groupsService from "./GroupsService";

const initialState = {
  groups: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getGroups = createAsyncThunk(
  "/parcel_group/get_parcel_groups",
  async (
    {
      warehouseCountryId,
      flightNumber,
      status,
      barcode,
      parcelGroupTypeId,
      transportationTypeId,
      page,
      perPage,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.getGroups(
        token,
        warehouseCountryId,
        flightNumber,
        status,
        barcode,
        parcelGroupTypeId,
        transportationTypeId,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const registerGroup = createAsyncThunk(
  "parcel_group/parcel_group_registration",
  async (groupData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.registerGroup(groupData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const transferGroupsExcel = createAsyncThunk(
  "parcel_group/parcel_change_group_excel",
  async (excelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.transferGroupsExcel(excelData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const updateGroup = createAsyncThunk(
  "parcel_group/parcel_group_change",
  async (updatedGroupData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.updateGroup(updatedGroupData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "parcel_group/parcel_group_delete",
  async (parcelGroupIds, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.deleteGroup(parcelGroupIds, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const exportExcelForLand = createAsyncThunk(
  "parcel_group/export_land_parcels",
  async ({ parcelGroupId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await groupsService.exportExcelForLand(parcelGroupId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groups = action.payload;
      })
      .addCase(getGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groups?.parcelGroups.unshift(action.payload);
      })
      .addCase(registerGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.groups?.parcelGroups.findIndex(
          (group) => group.id === action.payload.id
        );
        state.groups[index] = action.payload;
      })
      .addCase(updateGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const groupIdsToDelete = action.payload?.parcelGroupIds || [];

        if (state.groups && state.groups?.parcelGroups) {
          state.groups = {
            ...state.groups,
            parcelGroups: state.groups?.parcelGroups?.map((group) => {
              return {
                ...group,
                groups: (group?.groups?.parcelGroups || []).filter(
                  (subGroup) =>
                    !groupIdsToDelete.includes(subGroup.parcelGroupId)
                ),
              };
            }),
          };
        }
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = groupsSlice.actions;
export default groupsSlice.reducer;
