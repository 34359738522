import { useState, useMemo, useEffect, useCallback } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import Spinner from "../common/Spinner";
import Input from "../common/Input";
import { useTranslation } from "react-i18next";

const libraries = ["places"];
const apiKey = "AIzaSyDjnyMgqkEvbIBGAT1Qw_jNtOFOJJnssMI";

const containerStyle = {
  width: "100%",
  height: "550px",
  borderRadius: "10px",
};

const defaultCenter = { lat: 41.7151, lng: 44.8271 };

const GoogleMapWithAutocomplete = ({
  setFormData,
  formData,
  handleInputChange,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const { t } = useTranslation();

  const userLanguage = navigator.language || "ka";

  const loaderOptions = useMemo(
    () => ({
      googleMapsApiKey: apiKey,
      libraries,
      language: userLanguage,
    }),
    [userLanguage]
  );

  const { isLoaded } = useJsApiLoader(loaderOptions);

  useEffect(() => {
    if (formData?.address?.formatted_address) {
      setInputValue(formData.address.formatted_address);
      if (formData.address.geometry) {
        const { location } = formData.address.geometry;
        const { lat, lng } = location;
        setMapCenter({ lat, lng });
        setMarkerPosition({ lat, lng });
      }
    }
  }, [formData]);

  const onLoad = (autocompleteInstance) =>
    setAutocomplete(autocompleteInstance);

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const formattedAddress = place.formatted_address || "";
        const { lat, lng } = place.geometry.location;
        const newPosition = { lat: lat(), lng: lng() };

        setInputValue(formattedAddress);
        setMapCenter(newPosition);
        setMarkerPosition(newPosition);

        setFormData((prev) => ({
          ...prev,
          address: place,
        }));
      } else {
        console.error("No details available for input: ", place);
      }
    }
  };

  const handleAddressChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (!value.trim()) {
      setFormData((prev) => ({
        ...prev,
        address: "",
      }));
    }
  };

  const handleMapClick = useCallback(
    (event) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
      setMapCenter(newPosition);

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          const selectedAddress = results[0];
          setInputValue(selectedAddress.formatted_address);
          setFormData((prev) => ({
            ...prev,
            address: selectedAddress,
          }));
        } else {
          console.error("Geocoder failed due to: ", status);
        }
      });
    },
    [setFormData]
  );

  if (!isLoaded) return <Spinner />;

  return (
    <div>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder={t("text.address")}
          value={inputValue}
          onChange={handleAddressChange}
          className="relative cursor-pointer mt-1 w-full px-4 py-4 border flex items-center justify-between bg-white rounded-lg text-sm border-gray-200 placeholder:text-gray-1 outline-none"
        />
      </Autocomplete>
      <div className="py-4">
        <Input
          value={formData.addressComment}
          name="addressComment"
          onchange={handleInputChange}
          text={t("text.Comment")}
        />
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={14}
        onClick={handleMapClick}
      >
        <Marker position={markerPosition} />
      </GoogleMap>
    </div>
  );
};

export default GoogleMapWithAutocomplete;
