import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesWithMaster } from "../../features/groups/GroupsSlice";
import { useTranslation } from "react-i18next";
import { BackSvg, DocumentSvg, PrintBarcodeSvg } from "../../components/icons";
import ParcelGroupsLayout from "./ParcelGroupsLayout";
import Checkbox from "../../components/common/Checkbox";
import ParcelPrint from "../../components/modals/groups/ParcelPrint";

const ParcelDetailsNew = () => {
  const [parcel, setParcel] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const { parcelId, parcelGroupId } = useParams();
  const { parcels } = useSelector((state) => state.parcels);
  const { categoriesWithMaster } = useSelector((state) => state.groups);
  const printRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const item = parcels?.parcels?.find(
      (parcel) => parseInt(parcel.id) === parseInt(parcelId)
    );
    setParcel(item);
  }, [parcelId, parcels?.parcels]);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
  }, [dispatch]);

  useEffect(() => {
    if (categoriesWithMaster?.length > 0) {
      const allSubcategories = categoriesWithMaster?.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster]);

  useEffect(() => {
    if (
      parcel &&
      parcel?.warehouseCategoryId &&
      categoriesWithMaster?.length > 0
    ) {
      const subCategory = subCategories?.find(
        (sub) => sub.id === parcel?.warehouseCategoryId
      );

      if (subCategory) {
        const parentCategory = categoriesWithMaster.find((category) =>
          category.children?.some((child) => child.id === subCategory.id)
        );
        setParentCategory(parentCategory?.description);
      }
    } else {
      setParentCategory(t("text.not_found"));
    }
  }, [parcel, subCategories, categoriesWithMaster, t]);

  const getSubCategoryDescription = (categoryId) => {
    const subCategory = subCategories.find((sub) => sub.id === categoryId);
    return subCategory ? subCategory.description : t("text.not_found");
  };

  const handleOpenFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const calculateTotals = () => {
    const totalQuantity = parcel?.parcelDetails?.reduce(
      (total, detail) => total + (parseFloat(detail.quantity) || 0),
      0
    );
    const totalWeight = parcel?.parcelDetails?.reduce(
      (total, detail) => total + (parseFloat(detail.weight) || 0),
      0
    );

    const totalVolumeWeight = parcel?.parcelDetails?.reduce(
      (total, detail) => total + (parseFloat(detail.volumeWeight) || 0),
      0
    );

    return { totalQuantity, totalWeight, totalVolumeWeight };
  };

  const { totalQuantity, totalWeight, totalVolumeWeight } = calculateTotals();

  const displayedWeight =
    totalVolumeWeight > totalWeight ? totalVolumeWeight : totalWeight;

  const handlePrint = (index) => {
    const printContent = document.getElementById(
      `print-content-${index}`
    ).innerHTML;
    const newWindow = window.open("", "_blank");

    newWindow.document.write(`
      <html>
        <head>
          <title>Print Parcel</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              height: 100vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              max-width: 800px;
              width: 100%;
              padding: 20px;
              box-sizing: border-box;
            }
            img {
              max-width: 100%;
              height: auto;
              margin-bottom: 10px
            }
            h2 {
              margin: 5px 0
            }
            .barcode {
              margin-top: 20px;
            }
            p {
              max-width: 270px;
              width: 100%;
              font-size: 12px;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="content">
            ${printContent}
          </div>
        </body>
      </html>
    `);

    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    newWindow.onafterprint = () => newWindow.close();
  };

  return (
    <ParcelGroupsLayout>
      <div>
        <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
          <div className="flex items-center gap-3">
            <Link
              to={`/ParcelGroupNew/${parcelGroupId}/Parcels`}
              className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
            >
              <BackSvg />
            </Link>
            {parcel && (
              <div className="text-2xl font-semibold text-gray-800">
                <span className="font-normal">{parcel?.fullName}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-start gap-6 p-6">
          <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 break-all">
              {parcel?.tdsCode}
            </div>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 break-all">
              {parcel?.roomNumber}
            </div>
          </div>

          {parcel?.parcelDetails?.map((detail, index) => (
            <div
              key={index}
              className="w-full flex justify-start items-center gap-4 bg-gray-100 border border-gray-200 rounded-xl px-6"
            >
              <button type="button" onClick={() => handlePrint(index)}>
                <PrintBarcodeSvg />
              </button>
              <ParcelPrint
                length={parcel?.parcelDetails?.length}
                index={index}
                printRef={printRef}
                data={parcel}
                details="details"
              />
              <div className="w-full grid grid-cols-1 py-3 gap-4 sm:grid-cols-6">
                <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
                  {detail.weight}
                </div>
                <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
                  {detail.quantity}
                </div>
                <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
                  {detail.length}
                </div>
                <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
                  {detail.width}
                </div>
                <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
                  {detail.height}
                </div>
                <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
                  {detail.volumeWeight}
                </div>
              </div>
            </div>
          ))}

          <div className="w-full grid grid-cols-1 gap-4 rounded-xl lg:grid-cols-2">
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-4 px-3 leading-5 text-sm text-black/50 ">
              {parentCategory}
            </div>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-4 px-3 leading-5 text-sm text-black/60 ">
              {getSubCategoryDescription(parcel?.warehouseCategoryId)}
            </div>
          </div>

          <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              ჯამური რაოდენობა {totalQuantity}
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              ჯამური წონა {displayedWeight}
            </div>
          </div>

          {parcel?.parcelServices && (
            <div className="w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
              {parcel?.parcelServices.map((service, index) => (
                <div
                  key={index}
                  className="flex justify-start items-center gap-2 lg:justify-center"
                >
                  <Checkbox
                    checked={service.serviceStatusFlag === "Y" ? true : false}
                  />
                  <span className="text-xs text-gray-400">
                    {service.serviceDesc}
                  </span>
                </div>
              ))}
            </div>
          )}

          {parcel?.parcelFiles?.length > 0 && (
            <div className="w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
              {parcel?.parcelFiles?.map((parcelFile, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 w-full bg-white rounded-xl py-2 px-4 cursor-pointer"
                >
                  <div className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full">
                    <DocumentSvg />
                  </div>
                  <h3
                    onClick={() => handleOpenFile(parcelFile.fileUrl)}
                    className="text-sm font-medium text-blue-900 truncate w-[calc(100%-40px)]"
                  >
                    {parcelFile.fileName}
                  </h3>
                </div>
              ))}
            </div>
          )}
          {parcel?.warehouseComment !== null && (
            <div className="w-full py-3 px-6 bg-gray-100 border border-gray-200 rounded-xl">
              <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-black/50 ">
                {parcel?.warehouseComment}
              </div>
            </div>
          )}
        </div>
      </div>
    </ParcelGroupsLayout>
  );
};

export default ParcelDetailsNew;
