import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companiesService from "./CompanyService";

const initialState = {
  companies: [],
  banks: {
    banks: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCompanies = createAsyncThunk(
  "projects/get_project_companies",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await companiesService.getCompanies(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const getBanks = createAsyncThunk(
  "projects/get_banks",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await companiesService.getBanks(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const registerCompany = createAsyncThunk(
  "projects/register_company",
  async (companyData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await companiesService.registerCompany(companyData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const updateCompany = createAsyncThunk(
  "projects/change_company",
  async (updatedCompanyData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await companiesService.updateCompany(updatedCompanyData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "projects/delete_company",
  async (companyId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await companiesService.deleteCompany(companyId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.companies = action.payload;
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.companies.unshift(action.payload);
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // const index = state.companies.findIndex(
        //   (department) => department.id === action.payload.id
        // );
        // state.companies[index] = action.payload;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.companies = state.companies?.filter(
        //   (department) => department.id !== action.payload
        // );
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBanks.pending, (state) => {
        state.banks.isLoading = true;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        state.banks.isLoading = false;
        state.banks.isSuccess = true;
        state.banks.banks = action.payload;
      })
      .addCase(getBanks.rejected, (state, action) => {
        state.banks.isLoading = false;
        state.banks.isError = true;
        state.banks.message = action.payload;
      });
  },
});

export const { reset } = companiesSlice.actions;
export default companiesSlice.reducer;
