import { Link } from "react-router-dom";
import { BackSvg } from "../../components/icons";
import { useTranslation } from "react-i18next";
import createNotification from "../../assets/create-notification.svg";
import NotificationsTable from "../../components/table/notifications/NotificationsTable";
import NotificationsLayout from "./NotificationsLayout";
import { useState } from "react";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import { useDispatch } from "react-redux";
import { deleteNotification } from "../../features/notifications/notifications/NotificationsSlice";
import { Alert, Snackbar } from "@mui/material";
import useLinkToNavigate from "../../libs/useLinkToNavigate";

const Notifications = ({ formData, setFormData }) => {
  const [notificationId, setNotificationId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [refetch, setRefetch] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const linkToNavigate = useLinkToNavigate();

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setNotificationId(id);
  };

  const handleDeleteNotification = async () => {
    const notificationData = {
      notificationId: notificationId,
    };

    const response = await dispatch(deleteNotification(notificationData));
    if (response?.payload?.type === "success") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setNotificationId("");
      setMessage(response.payload.message);
      setSeverity("success");
      setRefetch(!refetch);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setNotificationId("");
      setMessage(response.error.message);
      setSeverity("error");
    }
  };

  return (
    <NotificationsLayout refetch={refetch}>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteNotification}
        />
      )}
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to={linkToNavigate}
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          <div className="text-xl font-semibold text-gray-800">
            {t("text.notifications")}
          </div>
        </div>
        <Link
          className="bg-blue-2/15 py-px px-8 rounded-lg flex justify-center items-center gap-1.5 whitespace-nowrap text-xs font-medium text-blue-2 cursor-pointer transition-all duration-300 hover:bg-blue-2/50"
          to="/notifications/create"
        >
          <img
            className="w-8 h-9 object-contain"
            src={createNotification}
            alt="add"
          />
          Create Notification
        </Link>
      </div>
      <NotificationsTable
        handleDeleteConfirmation={handleDeleteConfirmation}
        formData={formData}
        setFormData={setFormData}
      />
    </NotificationsLayout>
  );
};

export default Notifications;
