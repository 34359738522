import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoWarning } from "react-icons/io5";
import { getDepartments } from "../../../features/groups/departments/DepartmenstSlice";
import { useTranslation } from "react-i18next";
import {
  getCategoriesWithMaster,
  getNewParcelInfo,
  getParcelServices,
} from "../../../features/groups/GroupsSlice";
import {
  CloseModalSvg,
  LengthSvg,
  LocationSvg,
  PrintBarcodeSvg,
  QuantitySvg,
  WeightSvg,
  WidthSvg,
} from "../../icons";
import {
  calculatePrice,
  reset as resetCalcs,
} from "../../../features/groups/CalculationsSlice";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import InputSmall from "../../common/InputSmall";
import Checkbox from "../../common/Checkbox";
import SelectSmall from "../../common/SelectSmall";
import Input from "../../common/Input";
import upload from "../../../assets/upload-picture.png";
import clear from "../../../assets/clear.png";
import Modal from "../../common/Modal";
import SelectToSetLarge from "../../common/shortList/SelectToSetLarge";
import AddressSearchMap from "../../googleApiSearch/AddressSearchMap";
import IndividualParcelPrint from "../../modals/groups/IndividualParcelPrint";

const EditIndividualParcelForm = ({
  handleSubmit,
  setFormData,
  formData,
  setSelectedMainCategory,
  setSelectedSubCategory,
  selectedMainCategory,
  selectedSubCategory,
  setShowUploadModal,
  subCategories,
  setSubCategories,
  parcel,
}) => {
  const [isDivVisible, setIsDivVisible] = useState(true);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const weightInputRef = useRef(null);
  const debounceTimeout2 = useRef(null);
  const printRef = useRef();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newParcelInfo, categoriesWithMaster, parcelServices } = useSelector(
    (state) => state.groups
  );

  const { trackingCode } = useSelector((state) => state.trackingCode);
  const { departments } = useSelector((state) => state.parcelDepartments);
  const { price } = useSelector((state) => state.calculatedPrice);

  useEffect(() => {
    const formattedParcelDetails = formData.parcelDetails
      .map((detail) => ({
        quantity: parseFloat(detail.quantity) || 0,
        weight: parseFloat(detail.weight) || 0,
        length: parseFloat(detail.length) || 0,
        width: parseFloat(detail.width) || 0,
        height: parseFloat(detail.height) || 0,
      }))
      .filter(
        (detail) =>
          detail.quantity !== 0 ||
          detail.weight !== 0 ||
          detail.length !== 0 ||
          detail.width !== 0 ||
          detail.height !== 0
      );

    if (
      formData.parcelGroupId &&
      formData.parcelDetails &&
      (formData.receiverPotentialUserId ||
        formData.receiverUserId ||
        formData.receiver) &&
      (formData.departmentId || formData.adddress)
    ) {
      if (debounceTimeout2.current) clearTimeout(debounceTimeout2.current);
      debounceTimeout2.current = setTimeout(() => {
        dispatch(
          calculatePrice({
            parcelGroupId: formData.parcelGroupId || "",
            parcelDetails: JSON.stringify(formattedParcelDetails),
            adddress: formData.adddress || "",
            departmentId: formData.departmentId || "",
            receiver: JSON.stringify(formData.receiver) || "",
            receiverUserId: formData.receiverUserId || "",
            senderUserId: formData.senderUserId || "",
            senderPotentialUserId: formData.senderPotentialUserId || "",
            receiverPotentialUserId: formData.receiverPotentialUserId || "",
          })
        );
      }, 500);
    } else {
      dispatch(resetCalcs());
    }
  }, [
    formData.parcelGroupId,
    formData.parcelDetails,
    formData.adddress,
    formData.departmentId,
    formData.receiver,
    formData.receiverUserId,
    formData.senderUserId,
    formData.senderPotentialUserId,
    formData.receiverPotentialUserId,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
    dispatch(getParcelServices());
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (newParcelInfo && newParcelInfo.length > 0) {
      setFormData((prev) => ({
        ...prev,
        roomNumber: newParcelInfo[0]?.roomNumber,
      }));
    }
  }, [newParcelInfo, setFormData]);

  useEffect(() => {
    if (trackingCode?.message) {
      setFormData((prev) => ({
        ...prev,
        tdsCode: trackingCode.message,
      }));
    }
  }, [trackingCode, setFormData]);

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster, setSubCategories]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      warehouseCategoryId: selectedSubCategory,
    }));
  }, [selectedSubCategory, setFormData]);

  useEffect(() => {
    if (formData.departmentId) {
      setFormData((prev) => ({
        ...prev,
        cityId: "",
        address: "",
        addressComment: "",
      }));
    }
  }, [formData.departmentId, setFormData]);

  const calculateTotals = () => {
    const totalQuantity = formData.parcelDetails.reduce(
      (total, detail) => total + (parseFloat(detail.quantity) || 0),
      0
    );

    const totalWeight = formData.parcelDetails.reduce((total, detail) => {
      const weight = parseFloat(detail.weight) || 0;
      const length = parseFloat(detail.length) || 0;
      const width = parseFloat(detail.width) || 0;
      const height = parseFloat(detail.height) || 0;

      const volumetricWeight = (length * width * height) / 6000;

      const effectiveWeight = Math.max(weight, volumetricWeight);

      return total + effectiveWeight;
    }, 0);

    return {
      totalQuantity: parseFloat(totalQuantity.toFixed(2)),
      totalWeight: parseFloat(totalWeight.toFixed(2)),
    };
  };

  const { totalQuantity, totalWeight } = calculateTotals();

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const [fieldGroup] = name.split(".");
    const numericFields = ["quantity", "weight", "length", "width", "height"];
    const isNumericField = numericFields.includes(name);

    if (isNumericField && !/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (
      name === "quantity" ||
      name === "weight" ||
      name === "length" ||
      name === "width" ||
      name === "height"
    ) {
      const updatedParcelDetails = [...formData.parcelDetails];
      updatedParcelDetails[index] = {
        ...updatedParcelDetails[index],
        [name]: value,
      };

      const length = parseFloat(updatedParcelDetails[index].length || 0);
      const width = parseFloat(updatedParcelDetails[index].width || 0);
      const height = parseFloat(updatedParcelDetails[index].height || 0);
      const VOLUMETRIC_FACTOR = 6000;

      if (length && width && height) {
        updatedParcelDetails[index].volumetricWeight =
          (length * width * height) / VOLUMETRIC_FACTOR;
      } else {
        updatedParcelDetails[index].volumetricWeight = undefined;
      }

      const isRowEmpty = Object.values(updatedParcelDetails[index]).every(
        (field) => field === "" || field === undefined
      );

      if (isRowEmpty && updatedParcelDetails.length > 1) {
        updatedParcelDetails.splice(index, 1);
      }

      const lastBlock = updatedParcelDetails[updatedParcelDetails.length - 1];
      if (
        index === updatedParcelDetails.length - 1 &&
        (lastBlock.quantity ||
          lastBlock.length ||
          lastBlock.width ||
          lastBlock.height)
      ) {
        updatedParcelDetails.push({
          quantity: "",
          weight: "",
          length: "",
          width: "",
          height: "",
        });
      }

      setFormData({
        ...formData,
        parcelDetails: updatedParcelDetails,
      });

      return;
    }

    if (
      fieldGroup !== "sender" &&
      fieldGroup !== "receiver" &&
      name !== "quantity" &&
      name !== "weight" &&
      name !== "length" &&
      name !== "width" &&
      name !== "height"
    ) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleKeyDown = async (e) => {
    const { name } = e.target;

    if (e.key === "Enter") {
      e.preventDefault();

      if (name === "tdsCode" && formData.tdsCode !== "") {
        const response = await dispatch(getNewParcelInfo(formData.tdsCode));
        if (
          response.payload &&
          response.payload.length > 0 &&
          response.payload[0].roomNumber
        ) {
          weightInputRef.current.focus();
        }
      } else {
        const formElements = Array.from(e.target.form.elements);
        const currentElementIndex = formElements.indexOf(e.target);
        const nextElement = formElements[currentElementIndex + 1];

        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  };

  const handleMainCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;

    setSelectedMainCategory(selectedCategoryId);
    setSelectedSubCategory("");

    const parentCategory = categoriesWithMaster.find(
      (category) => category.id === parseInt(selectedCategoryId)
    );
    setSubCategories(parentCategory?.children || []);
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;

    setSelectedSubCategory(selectedSubCategoryId);
    const parentCategory = categoriesWithMaster.find((category) =>
      category.children.some(
        (sub) => sub.id === parseInt(selectedSubCategoryId)
      )
    );
    if (parentCategory) {
      setSelectedMainCategory(parentCategory.id);
    }
  };

  const handleClearForm = () => {
    dispatch(resetCalcs());
    setFormData((prev) => ({
      ...prev,
      tdsCode: "",
      parcelDetails: [
        {
          quantity: "",
          weight: "",
          length: "",
          width: "",
          height: "",
        },
      ],
      warehouseComment: "",
      warehouseCategoryId: "",
      "file[]": [],
      senderPotentialUserId: "",
      receiverUserId: "",
      senderUserId: "",
      address: "",
      departmentId: "",
      price: "",
      cityId: "",
      addressComment: "",
    }));
    setSelectedSubCategory("");
    setSelectedMainCategory("");
  };

  const handleCloseDiv = () => {
    setIsDivVisible(false);
  };

  const handleShowAddressModal = () => {
    setShowAddressModal(!showAddressModal);
  };

  const handlePrint = async (index) => {
    const printContent = document.getElementById(
      `print-content-${index}`
    ).innerHTML;
    const newWindow = window.open("", "_blank");

    newWindow.document.write(`
      <html>
        <head>
          <title>Print Parcel</title>
           <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              height: 100vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              max-width: 800px;
              width: 100%;
              padding: 20px;
              box-sizing: border-box;
            }
            img {
              display: block;
              max-width: 200px;
              margin-bottom: 10px;
              max-height: 30px;
              height: 100%;
            }
            h2 {
              margin: 5px 0
            }
            h3 {
              font-size: 14px
            }
            .barcode {
              margin-top: 20px;
            }
            p {
              max-width: 270px;
              width: 100%;
              font-size: 12px;
              text-align: center;
            }
            .grid {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              max-width: 270px;
              width: 100%;
            }
            .first {
              border-right: solid 1px #000;
              padding:5px;
              text-align: start;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
            .second{
              padding:5px;
              text-align: end;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
          </style>
        </head>
        <body>
          <div class="content">
            ${printContent}
          </div>
        </body>
      </html>
    `);

    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    newWindow.onafterprint = () => newWindow.close();
  };

  return (
    <>
      <Modal showmodal={showAddressModal} setShowModal={setShowAddressModal}>
        <AddressSearchMap
          setShowModal={setShowAddressModal}
          handleInputChange={handleInputChange}
          formData={formData}
          setFormData={setFormData}
        />
      </Modal>
      <form
        onSubmit={handleSubmit}
        className="grid gap-4 xl:grid-cols-[auto_319px] px-6 py-10 transition-all duration-500"
      >
        <div className="flex flex-col items-start gap-6">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="gap-4 flex flex-col items-start">
              <h2 className="text-base text-blue-4/80 font-medium">
                {t("text.sender")}
              </h2>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.sender.firstName || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.sender.lastName || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.sender.pinOrInn || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.sender.address?.formatted_address || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.sender.mobile || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.sender.email || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.sender.additionalInformation || ""}
              </span>
            </div>
            <div className="gap-4 flex flex-col items-start">
              <h2 className="text-base text-blue-4/80 font-medium">
                {t("text.receiver")}
              </h2>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.receiver.firstName || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.receiver.lastName || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.receiver.pinOrInn || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.receiver.address?.formatted_address || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.receiver.mobile || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.receiver.email || ""}
              </span>
              <span className="opacity-80 w-full h-10 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.receiver.additionalInformation || ""}
              </span>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
            <div className="relative flex">
              <span className="opacity-80 w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                {formData.tdsCode || ""}
              </span>
            </div>

            <InputSmall
              value={formData.price}
              name="price"
              onchange={handleInputChange}
              text={t("text.cost")}
            />
          </div>

          {formData.parcelDetails.map((detail, index) => (
            <div
              key={index}
              className="w-full flex justify-start items-center gap-4 bg-gray-100 border border-gray-200 rounded-xl py-3 px-6"
            >
              <button type="button" onClick={() => handlePrint(index)}>
                <PrintBarcodeSvg />
              </button>
              <IndividualParcelPrint
                length={formData.parcelDetails?.length}
                index={index}
                printRef={printRef}
                data={parcel}
                details="details"
              />
              <div className="w-full grid grid-cols-1 gap-4 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
                <div className="relative">
                  <InputSmall
                    inputRef={index === 0 ? weightInputRef : null}
                    text={t("text.weight")}
                    name="weight"
                    onchange={(e) => handleInputChange(e, index)}
                    value={detail.weight}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    isicon="isicon"
                    icon={<WeightSvg />}
                  />
                </div>

                <div className="relative">
                  <InputSmall
                    text={t("text.quantity")}
                    name="quantity"
                    onchange={(e) => handleInputChange(e, index)}
                    value={detail.quantity}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    isicon="isicon"
                    icon={<QuantitySvg />}
                  />
                </div>

                <InputSmall
                  text={`${t("text.length")} (${t("text.cm")}) `}
                  name="length"
                  onchange={(e) => handleInputChange(e, index)}
                  value={detail.length}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  isicon="isicon"
                  icon={<LengthSvg />}
                />

                <InputSmall
                  text={`${t("text.width")} (${t("text.cm")}) `}
                  name="width"
                  onchange={(e) => handleInputChange(e, index)}
                  value={detail.width}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  isicon="isicon"
                  icon={<WidthSvg />}
                />

                <InputSmall
                  text={`${t("text.height")} (${t("text.cm")}) `}
                  name="height"
                  onchange={(e) => handleInputChange(e, index)}
                  value={detail.height}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  isicon="isicon"
                  icon={<LengthSvg />}
                />

                <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex flex-row items-center justify-start rounded-lg gap-2 py-1.5 px-3 h-10">
                  <span className="truncate w-max">
                    {t("text.total_volume_weight")}
                  </span>
                  <span>
                    {detail.volumetricWeight
                      ? detail.volumetricWeight.toFixed(2)
                      : "0"}
                  </span>
                </div>
              </div>
            </div>
          ))}

          <div className="w-full grid grid-cols-1 gap-4 rounded-xl lg:grid-cols-2">
            <SelectSmall
              options={categoriesWithMaster.map((cat) => ({
                value: cat.id,
                label: cat.description,
              }))}
              text={t("text.goods_type")}
              name="warehouseCategoryId"
              value={selectedMainCategory}
              onchange={handleMainCategoryChange}
            />
            <SelectSmall
              options={subCategories.map((sub) => ({
                value: sub.id,
                label: sub.description,
              }))}
              text={t("text.sub_type")}
              name="warehouseSubCategory"
              value={selectedSubCategory}
              onchange={handleSubCategoryChange}
            />
          </div>

          <div className="w-full grid grid-cols-1 gap-4 rounded-xl lg:grid-cols-2">
            <SelectToSetLarge
              text={t("text.department")}
              options={departments.map((cat) => ({
                value: cat.id,
                label: cat.address,
              }))}
              name="departmentId"
              value={formData.departmentId}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className={`${
                formData.departmentId ? "opacity-45" : "opacity-1"
              } flex justify-start items-center gap-2 w-full top-full rounded-lg overflow-hidden bg-white border border-gray-200 py-4 px-3 leading-5 text-xs md:text-sm text-gray-1`}
              disabled={formData.departmentId}
              onClick={handleShowAddressModal}
            >
              <LocationSvg />
              {formData.address
                ? formData.address?.name || formData.address?.formatted_address
                : t("text.address")}
            </button>
          </div>
          <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-3">
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              <span className="truncate">{t("text.total_quantity")}:</span>{" "}
              {totalQuantity}
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              <span className="truncate">{t("text.total_weight")}: </span>
              {totalWeight}
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              <span className="truncate">{t("text.courier_cost")}: </span>{" "}
              {price?.courierPrice}$
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              <span className="truncate">
                {t("text.amount_received_from_customer")}:
              </span>{" "}
              {price?.totalPrice}$
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              <span className="truncate">
                {t("text.amount_payable_for_space_cargo")}:{" "}
              </span>
              {price?.spaceCargoAmount}$
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              <span className="truncate">{t("text.profit")}:</span>
              {price?.companyProfit}$
            </div>
          </div>
          {newParcelInfo[0]?.parcelServices ? (
            <div className="relative w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
              {newParcelInfo[0]?.parcelServices.map((service, index) => (
                <div
                  key={index}
                  className="flex justify-start items-center gap-2 lg:justify-center"
                >
                  <Checkbox
                    checked={service.serviceStatusFlag === "Y" ? true : false}
                  />
                  <span className="text-xs text-gray-400">
                    {service.serviceDesc}
                  </span>
                </div>
              ))}
              {newParcelInfo[0]?.parcelServices.some(
                (service) => service.serviceStatusFlag === "Y"
              ) &&
                isDivVisible && (
                  <div className="absolute right-2 bottom-2 max-w-64 w-full bg-gray-200 shadow-md py-5 px-6 rounded-xl text-sm text-blue-1 border-gray-200 flex flex-col gap-2 items-center">
                    <div
                      className="absolute top-2 right-2 cursor-pointer w-5 h-5 flex justify-center items-center opacity-50 hover:opacity-100 transition-all duration-300"
                      onClick={handleCloseDiv}
                    >
                      <CloseModalSvg />
                    </div>
                    <IoWarning className="text-yellow-400 text-4xl" />
                    <p className="text-xs text-gray-6 text-center">
                      {t("text.user_request")}{" "}
                      {newParcelInfo[0]?.parcelServices
                        .filter((service) => service.serviceStatusFlag === "Y")
                        .map((service) => service.serviceDesc)
                        .join(", ")}
                    </p>
                  </div>
                )}
            </div>
          ) : (
            <div className="relative w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
              {parcelServices?.map((service) => (
                <div
                  key={service.id}
                  className="flex justify-start items-center gap-2 lg:justify-center"
                >
                  <Checkbox checked={false} />
                  <span className="text-xs text-gray-400">
                    {service.serviceDesc}
                  </span>
                </div>
              ))}
            </div>
          )}

          <div className="w-full">
            <Input
              text={t("text.Comment")}
              name="warehouseComment"
              onchange={handleInputChange}
              value={formData.warehouseComment}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 xl:px-9">
          <div className="flex flex-col items-center w-full gap-5">
            <div
              onClick={handleClearForm}
              className="w-full py-3 px-8 flex items-center gap-4 rounded-xl border border-red-300 cursor-pointer transition-all duration-300 hover:border-red-500 justify-between md:px-1.5 md:justify-center"
            >
              <img className="max-w-10 md:max-w-20" src={clear} alt="clear" />
              <span className="text-sm font-medium text-blue-1 md:text-base">
                {t("text.clear")}
              </span>
            </div>
            <div
              onClick={() => setShowUploadModal(true)}
              className="w-full py-3 px-8 flex justify-between items-center rounded-xl border border-dashed border-blue-300 cursor-pointer transition-all duration-300 hover:border-solid md:px-1.5 md:justify-center"
            >
              <img className="max-w-10 md:max-w-14" src={upload} alt="" />
              <span className="text-sm font-medium text-blue-1 text-center md:text-base">
                {t("text.upload_images")}
              </span>
            </div>
          </div>
          <ButtonPrimary type="submit">{t("button.save")}</ButtonPrimary>
        </div>
      </form>
    </>
  );
};

export default EditIndividualParcelForm;
