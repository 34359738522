import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Checkbox from "../components/common/Checkbox";
import CheckAll from "../components/common/CheckAll";
import TableSearch2 from "../components/common/shortList/TableSearch2";
import { useSelector } from "react-redux";
import SelectToFilter from "../components/common/shortList/SelectToFilter";
import { DeleteSvg2, EditSvg3 } from "../components/icons";

const useReferralAgents = (
  formData,
  setFormData,
  handleEditReferralAgent,
  handleDeleteConfirmation
) => {
  const { t } = useTranslation();
  const { statuses } = useSelector((state) => state.referralStatuses);
  const CheckboxComponent = 2 === 3 ? Checkbox : CheckAll;

  const actionConfig = [
    {
      type: "button",
      permission: "edit",
      action: (row) => ({
        onClick: () => handleEditReferralAgent(row.id),
        icon: <EditSvg3 />,
        tooltip: t("text.edit"),
      }),
      condition: (row) => row.actions?.includes("edit"),
    },
    {
      type: "button",
      permission: "delete",
      action: (row) => ({
        onClick: () => handleDeleteConfirmation(row.id),
        icon: <DeleteSvg2 />,
        tooltip: t("text.delete"),
      }),
      condition: (row) => row.actions?.includes("delete"),
    },
  ];

  const getActions = (row) => {
    return actionConfig
      .filter((action) => action.condition(row))
      .map((action, index) => (
        <Tooltip title={action.action(row).tooltip} key={index}>
          {action.type === "link" ? (
            <Link
              to={action.action(row).to}
              onClick={action.action(row).onClick}
              target={action.action(row).target || undefined}
            >
              {action.action(row).icon}
            </Link>
          ) : (
            <button onClick={action.action(row).onClick}>
              {action.action(row).icon}
            </button>
          )}
        </Tooltip>
      ));
  };

  return {
    columns: [
      {
        key: "",
        header: <CheckboxComponent checked={true} onChange={() => true} />,
        checkbox: true,
        filterable: false,
        cell: (row) => <Checkbox checked={true} onChange={() => true} />,
        actions: getActions,
        condition: true,
      },
      {
        key: "firstName",
        header: t("text.First_Name"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "lastName",
        header: t("text.Last_Name"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "mobile",
        header: t("text.mobile"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "pinOrInn",
        header: t("text.inn"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "statusDesc",
        translateable: true,
        header: t("text.status"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <SelectToFilter
            options={statuses?.map((cat) => ({
              value: cat.status,
              label: t(cat.statusDictionaryKey),
            }))}
            name="status"
            value={formData.status}
            setFormData={setFormData}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: e.target.value,
              }))
            }
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "totalAgents",
        header: t("text.totalAgents"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
      {
        key: "totalUsers",
        header: t("text.totalUsers"),
        filterable: true,
        filterComponent: (formData, setFormData, searchKey) => (
          <TableSearch2
            formData={formData}
            setFormData={setFormData}
            searchKey={searchKey}
          />
        ),
        actions: getActions,
        condition: true,
      },
    ],
  };
};

export default useReferralAgents;
