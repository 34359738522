import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CategoriesLayout from "../../components/layouts/CategoriesLayout";
import {
  getCategories,
  reset,
} from "../../features/categories/CategoriesSlice";

const Categories = () => {
  const { message, isError } = useSelector((state) => state.categories);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getCategories());
    }

    return () => {
      dispatch(reset());
    };
  }, [message, isError, dispatch, user]);

  return <CategoriesLayout />;
};

export default Categories;
