import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import deliveryTimeService from "./DeliveryTimeService";

const initialState = {
  deliveryTime: [],
  deliveryTimeDetails: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getDeliveryTime = createAsyncThunk(
  "deliveryTimeReport/get_report",
  async ({ reportType, groupPeriod, departmentId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await deliveryTimeService.getDeliveryTime(
        token,
        reportType,
        groupPeriod,
        departmentId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const getDeliveryTimeDetails = createAsyncThunk(
  "report/avgTerminalWithdrawTime",
  async ({ reportType, groupPeriod, departmentId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await deliveryTimeService.getDeliveryTimeDetails(
        token,
        reportType,
        groupPeriod,
        departmentId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const deliveryTimeSlice = createSlice({
  name: "deliveryTime",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryTime.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeliveryTime.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deliveryTime = action.payload;
        state.statusCode = 200;
      })
      .addCase(getDeliveryTime.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(getDeliveryTimeDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeliveryTimeDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.deliveryTimeDetails = action.payload;
        state.statusCode = 200;
      })
      .addCase(getDeliveryTimeDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      });
  },
});

export const { reset } = deliveryTimeSlice.actions;
export default deliveryTimeSlice.reducer;
