import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLinks, reset } from "../../features/menu/MenuSlice";
import { logout } from "../../features/auth/authSlice";
import { CloseSvg } from "../icons";
import { useAppContext } from "../../libs/AppContext";
import { routes } from "../../routes";
import NavLinks from "./NavLinks";
import Logout from "./Logout";
import spaceCargoLogo from "../../assets/space-cargo-logo.svg";
import SearchDark from "../common/SearchDark";

const Sidebar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [linkToNavigate, setLinkToNavigate] = useState("");
  const { handleMobileMenu, menuOpen } = useAppContext();
  const { links, isError } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const matchedObjects = routes[1].routes.filter((route) => {
      const routePathWithoutSlash = route.path.replace("/", "");

      return links?.permissions?.some((permission) => {
        if (!permission.children || permission.children.length === 0) {
          return permission.pageUrl === routePathWithoutSlash;
        } else {
          return permission.children.some(
            (child) => child.pageUrl === routePathWithoutSlash
          );
        }
      });
    });

    if (matchedObjects) {
      // navigate(matchedObjects[0]?.path);
      setLinkToNavigate(matchedObjects[0]?.path);
    }
  }, [links?.permissions]);

  useEffect(() => {
    if (user) {
      dispatch(getLinks());
    }

    if (isError) {
      dispatch(logout());
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, user, isError]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredItems(links.permissions || []);
      return;
    }

    const allLinks = links.permissions.flatMap((link) =>
      link.children && link.children.length > 0 ? link.children : [link]
    );

    const filtered = allLinks.filter((item) => {
      const translatedName = t(item.pageDictionaryKey);
      return translatedName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredItems(filtered);
  }, [links.permissions, searchTerm, t]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const message = links?.message?.map((item) => item?.detail);

  return (
    <div
      className={`w-full h-full flex flex-col justify-between z-50 bg-gray-100 border-r border-gray-300 duration-500 fixed top-0 transform overflow-y-auto lg:r-0 lg:transform-none lg:w-64 xl:w-80 ${
        menuOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex flex-col items-start w-full px-4">
        <div className="w-full flex justify-between items-center py-6">
          <Link
            className="flex justify-center items-center"
            to={linkToNavigate}
          >
            <img
              className="h-9 w-20 object-contain"
              src={spaceCargoLogo}
              alt="Space Cargo"
            />
          </Link>
          <div
            onClick={handleMobileMenu}
            className="lg:hidden relative flex items-center justify-center w-10 h-10 border border-gray-200 rounded-md p-1 cursor-pointer transition-all duration-300 hover:bg-gray-200/30"
          >
            <CloseSvg />
          </div>
        </div>
        {links?.permissions?.length === 0 ? (
          <p className="text-red-500 text-center mt-8 px-5">{message}</p>
        ) : (
          <>
            <SearchDark searchTerm={searchTerm} handleSearch={handleSearch} />
            <NavLinks links={filteredItems} />
          </>
        )}
      </div>
      <Logout />
    </div>
  );
};

export default Sidebar;
