import { MdOutlineImageNotSupported } from "react-icons/md";

const ProcessingTableImage = ({ url, setSelectedImage }) => {
  return (
    <div className="">
      {url ? (
        <div className="h-5 w-5 flex justify-start items-center xl:h-16 xl:w-16">
          <img
            onClick={() => setSelectedImage(url)}
            className="w-full h-full object-contain cursor-pointer"
            src={url}
            alt="details"
          />
        </div>
      ) : (
        <div className="h-5 w-5 flex justify-start items-center text-xl">
          <MdOutlineImageNotSupported />
        </div>
      )}
    </div>
  );
};

export default ProcessingTableImage;
