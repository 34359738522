import { useAppContext } from "../../../libs/AppContext";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  DeleteSvg,
  DollarSvg,
  EmailSvg,
  SmsSvg,
  RoleChangeSvg,
} from "../../icons";
import Tooltip from "@mui/material/Tooltip";
import ListBoxSmall from "../../common/shortList/ListBoxSmall";
import Headline from "../../common/shortList/Headline";
import useLinkToNavigate from "../../../libs/useLinkToNavigate";

const GroupsPropertisBar = ({
  parcelgroupsdata,
  setparcelgroupsdata,
  groupsIds,
  handleShowChangeRate,
  handleDeleteConfirmation,
  handleShowSendSms,
  handleShowSendEmail,
  handleShowChangeStatus,
  allStatusIsSame,
  isStatusSent,
}) => {
  const { t } = useTranslation();
  const { countries } = useSelector((state) => state.parcelCountries);
  const { isPermission } = useAppContext();
  const linkToNavigate = useLinkToNavigate();

  useEffect(() => {
    const savedCountry = localStorage.getItem("warehouseCountryId");
    if (savedCountry) {
      setparcelgroupsdata((prevData) => ({
        ...prevData,
        warehouseCountryId: savedCountry,
      }));
    }
  }, [setparcelgroupsdata]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "warehouseCountryId") {
      localStorage.setItem("warehouseCountryId", value);
    }

    setparcelgroupsdata({
      ...parcelgroupsdata,
      [name]: value,
    });
  };

  return (
    <>
      <Headline
        linkUrl={linkToNavigate}
        text={t("text._navigation.parcelGroups")}
      >
        <div className="p-3 lg:p-0">
          <div className="flex items-center justify-end bg-white rounded-lg w-44 md:w-60">
            <ListBoxSmall
              options={countries?.map((cat) => ({
                value: cat.countryId,
                label: t(cat.countryDictionaryKey),
              }))}
              text={t("text.calculator_select_country")}
              name="warehouseCountryId"
              value={parcelgroupsdata.warehouseCountryId}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </Headline>
      {parcelgroupsdata.warehouseCountryId && groupsIds.length > 0 && (
        <div className="flex items-center justify-start gap-2 bg-gray-100 p-4 lg:border-b lg:border-gray-300 lg:w-full">
          {isPermission?.parcelGroupDelete?.action === "parcelGroupDelete" && (
            <Tooltip title={t("text.delete")}>
              <button
                className="p-1 bg-white border border-gray-300 rounded-md hover:bg-gray-200 lg:p-2"
                onClick={handleDeleteConfirmation}
              >
                <DeleteSvg />
              </button>
            </Tooltip>
          )}
          {isPermission?.sendSms?.action === "sendSms" && (
            <Tooltip title={t("text.sendSms")}>
              <button
                className="p-1 bg-white border border-gray-300 rounded-md hover:bg-gray-200 lg:p-2"
                onClick={handleShowSendSms}
              >
                <SmsSvg />
              </button>
            </Tooltip>
          )}
          {isPermission?.sendEmail?.action === "sendEmail" && (
            <Tooltip title={t("text.sendEmail")}>
              <button
                className="p-1 bg-white border border-gray-300 rounded-md hover:bg-gray-200 lg:p-2"
                onClick={handleShowSendEmail}
              >
                <EmailSvg />
              </button>
            </Tooltip>
          )}
          {isPermission?.parcelGroupChangeCcyRate?.action ===
            "parcelGroupChangeCcyRate" &&
            isStatusSent && (
              <Tooltip title={t("text.change_ccy_rate")}>
                <button
                  className="p-1 bg-white border border-gray-300 rounded-md hover:bg-gray-200 lg:p-2"
                  onClick={handleShowChangeRate}
                >
                  <DollarSvg />
                </button>
              </Tooltip>
            )}
          {(isPermission?.parcelGroupChangeStatusS?.action ===
            "parcelGroupChangeStatusS" ||
            isPermission?.parcelGroupChangeStatusP?.action ===
              "parcelGroupChangeStatusP" ||
            isPermission?.parcelGroupChangeStatusR?.action ===
              "parcelGroupChangeStatusR") &&
            allStatusIsSame && (
              <Tooltip title={t("text.change_status")}>
                <button
                  className="p-1 bg-white border border-gray-300 rounded-md hover:bg-gray-200 lg:p-2"
                  onClick={handleShowChangeStatus}
                >
                  <RoleChangeSvg />
                </button>
              </Tooltip>
            )}
        </div>
      )}
    </>
  );
};

export default GroupsPropertisBar;
