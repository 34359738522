import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../features/auth/authSlice";
import { Link } from "react-router-dom";
import { LogoutSvg } from "../icons";

const Logout = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
  };

  return (
    <>
      {user && (
        <div className="bg-gray-9 shadow-md shadow-gray-10 py-8 px-5 flex items-center justify-between w-full mt-5">
          <div className="flex items-center gap-2.5 overflow-hidden">
            <img
              src="https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aW1hZ2V8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
              alt="Profile"
              className="h-10 w-10 rounded-full object-cover"
            />
            <div>
              <Link
                to="/profile"
                className="text-gray-1 text-sm font-medium uppercase transition-all duration-300 hover:text-blue-1"
              >
                TBS-{user.message.user.roomNumber}
              </Link>
              <h3 className="text-gray-500 text-xs font-normal leading-4">
                {user.message.user.userName}
              </h3>
            </div>
          </div>
          <button
            onClick={onLogout}
            className="ml-2 cursor-pointer opacity-50 duration-500 hover:opacity-100"
          >
            <LogoutSvg />
          </button>
        </div>
      )}
    </>
  );
};

export default Logout;
