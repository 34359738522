import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import processingParcelsService from "./ParcelGroupsService";

const initialState = {
  processingParcels: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getProcessingParcels = createAsyncThunk(
  "processing_parcel_group/get_processing_parcel_groups",
  async (
    {
      warehouseCountryId,
      fileName,
      userName,
      parcelGroupBarcode,
      tdsCode,
      page,
      perPage,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await processingParcelsService.getProcessingParcels(
        token,
        warehouseCountryId,
        fileName,
        userName,
        parcelGroupBarcode,
        tdsCode,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const uploadProcessingGroupFile = createAsyncThunk(
  "processing_parcel_group/file_upload",
  async (fileData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await processingParcelsService.uploadProcessingGroupFile(
        fileData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const changeProcessingParcelGroup = createAsyncThunk(
  "processing_parcel_group/change_parcel_group",
  async (groupData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await processingParcelsService.changeProcessingParcelGroup(
        groupData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const exportProcessingGroups = createAsyncThunk(
  "processing_parcel_group/export_parcels",
  async ({ id }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await processingParcelsService.exportProcessingGroups(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteProcessingGroup = createAsyncThunk(
  "processing_parcel_group/delete",
  async (groupData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await processingParcelsService.deleteProcessingGroup(
        groupData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const completeProcessingGroup = createAsyncThunk(
  "processing_parcel_group/complete",
  async (groupData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await processingParcelsService.completeProcessingGroup(
        groupData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

// export const uploadImages = createAsyncThunk(
//   "processing_parcel_group/upload_images",
//   async (imagesData, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.message.token;
//       return await processingParcelsService.uploadImages(imagesData, token);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue({
//         message,
//       });
//     }
//   }
// );

export const processingParcelsSlice = createSlice({
  name: "processingParcels",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcessingParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProcessingParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.processingParcels = action.payload;
      })
      .addCase(getProcessingParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(changeProcessingParcelGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeProcessingParcelGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        const index = state.processingParcels?.processingParcelGroups.findIndex(
          (group) => group.id === action.payload.id
        );

        if (index !== -1) {
          state.processingParcels.processingParcelGroups[index] = {
            ...state.processingParcels.processingParcelGroups[index],
            parcelGroupBarcode: action.payload.parcelGroupBarcode,
          };
        }
      })

      .addCase(changeProcessingParcelGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(uploadProcessingGroupFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadProcessingGroupFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.processingParcels?.processingParcelGroups?.unshift(
          action.payload
        );
      })
      .addCase(uploadProcessingGroupFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteProcessingGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProcessingGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.processingParcels =
          state.processingParcels?.processingParcelGroups?.filter(
            (group) => group.id !== action.payload
          );
      })
      .addCase(deleteProcessingGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
    // .addCase(uploadImages.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(uploadImages.fulfilled, (state) => {
    //   state.isLoading = false;
    // });
  },
});

export const { reset } = processingParcelsSlice.actions;
export default processingParcelsSlice.reducer;
