import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@mui/material";
import { deleteClient, getClients } from "../../features/clients/ClientsSlice";
import { useAppContext } from "../../libs/AppContext";
import ClientsTable from "../table/clients/ClientsTable";
import Modal from "../common/Modal";
import ClientInformation from "../../components/modals/clients/ClientInformation";
import ClientFiles from "../../components/modals/clients/ClientFiles";
import DeleteConfirmation from "../common/DeleteConfirmation";
import EditClient from "../../components/modals/clients/EditClient";
import AddClient from "../../components/modals/clients/AddClient";
import AddButton from "../common/AddButton";
import TableHeadline from "../common/TableHeadline";

const ClientsLayout = ({ data }) => {
  const [showFiles, setShowFiles] = useState(false);
  const [showClientInfo, setShowClientInfo] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showEditClient, setShowEditClient] = useState(false);
  const [clientId, setClientId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const { setShowMainModal, showMainModal, handleShowMainModal } =
    useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleShowFiles = (id) => {
    setShowFiles(!showFiles);
    setClientId(id);
  };

  const handleShowClientInfo = (id) => {
    setShowClientInfo(!showClientInfo);
    setClientId(id);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setClientId(id);
  };

  const handleDeleteClient = async () => {
    const clientData = {
      id: clientId,
    };

    const response = await dispatch(deleteClient(clientData));
    if (response.payload.type === "success") {
      dispatch(getClients());
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }

    if (response.error.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setResponseMessage(response.payload.message);
    }
  };

  const handleShowEditClient = (id) => {
    setShowEditClient(!showEditClient);
    setClientId(id);
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          message={responseMessage}
        />
      )}
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddClient
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowModal={setShowMainModal}
          closeModal={handleShowMainModal}
        />
      </Modal>
      <Modal showmodal={showEditClient} setShowModal={setShowEditClient}>
        <EditClient
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          handleShowEditClient={handleShowEditClient}
          setShowModal={setShowEditClient}
          closeModal={handleShowEditClient}
          clientId={clientId}
          data={data}
        />
      </Modal>
      <Modal showmodal={showClientInfo} setShowModal={setShowClientInfo}>
        <ClientInformation
          handleShowClientInfo={handleShowClientInfo}
          clientId={clientId}
          data={data}
        />
      </Modal>
      <Modal showmodal={showFiles} setShowModal={setShowFiles}>
        <ClientFiles
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowModal={setShowFiles}
          closeModal={handleShowFiles}
          clientId={clientId}
          data={data}
        />
      </Modal>
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteClient}
          text={t("text.client")}
        />
      )}
      <TableHeadline text={t("text._navigation._clients")} />
      <ClientsTable
        handleShowFiles={handleShowFiles}
        handleShowClientInfo={handleShowClientInfo}
        handleShowEditClient={handleShowEditClient}
        handleDeleteConfirmation={handleDeleteConfirmation}
        data={data}
      />
      <AddButton />
    </div>
  );
};

export default ClientsLayout;
