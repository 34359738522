import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../libs/AppContext";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  deleteUser,
  getUsers,
} from "../../features/acquisitions/users/UsersSlice";
import UserResgitration from "../../components/modals/acquisitions/UserResgitration";
import Headline from "../../components/common/shortList/Headline";
import AddButton from "../../components/common/AddButton";
import Modal from "../../components/common/Modal";
import UsersTable from "../../components/table/acquisitions/UsersTable";
import EditUser from "../../components/modals/acquisitions/EditUser";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";

const Users = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    pinOrInn: "",
    mobile: "",
    status: "",
    referrerUserName: "",
    page: 1,
    perPage: 10,
  });
  const {
    firstName,
    lastName,
    pinOrInn,
    mobile,
    status,
    referrerUserName,
    page,
    perPage,
  } = formData;
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [showEditReferralUser, setShowEditReferralUser] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [referralUserId, setReferralUserId] = useState("");
  const { showMainModal, setShowMainModal, refetch, setRefetch, isPermission } =
    useAppContext();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getUsers({
        firstName,
        lastName,
        pinOrInn,
        mobile,
        status,
        referrerUserName,
        page,
        perPage,
      })
    );
  }, [
    dispatch,
    firstName,
    lastName,
    pinOrInn,
    mobile,
    status,
    referrerUserName,
    page,
    perPage,
    refetch,
  ]);

  const handleEditReferralUser = (id) => {
    setShowEditReferralUser(!showEditReferralUser);
    setReferralUserId(id);
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setReferralUserId(id);
  };

  const handleDeleteReferralUser = async () => {
    const dataToSend = {
      referralUserId: referralUserId,
    };

    const response = await dispatch(deleteUser(dataToSend));
    if (response?.payload?.type === "success") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setReferralUserId("");
      setMessage(response.payload.message);
      setSeverity("success");
      setRefetch(!refetch);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setReferralUserId("");
      setMessage(response.payload.message);
      setSeverity("error");
    }
  };

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteReferralUser}
        />
      )}
      <Headline text={t("text.users")} linkUrl="/acquisitions" />
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <UserResgitration
          setShowModal={setShowMainModal}
          setShowResponseMessage={setShowResponseMessage}
          setMessage={setMessage}
          setSeverity={setSeverity}
        />
      </Modal>
      <Modal
        showmodal={showEditReferralUser}
        setShowModal={setShowEditReferralUser}
      >
        <EditUser
          setMessage={setMessage}
          setSeverity={setSeverity}
          setShowResponseMessage={setShowResponseMessage}
          setShowEditReferralUser={setShowEditReferralUser}
          referralUserId={referralUserId}
        />
      </Modal>
      <UsersTable
        formData={formData}
        setFormData={setFormData}
        handleEditReferralUser={handleEditReferralUser}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />

      {isPermission.registerUser?.action === "registerUser" && <AddButton />}
    </div>
  );
};

export default Users;
