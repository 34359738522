import axios from "axios";

const getPotentialUsers = async (
  token,
  firstName,
  lastName,
  pinOrInn,
  address,
  mobile,
  email,
  additionalInformation,
  status,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    firstName: firstName,
    lastName: lastName,
    pinOrInn: pinOrInn,
    address: address,
    mobile: mobile,
    email: email,
    additionalInformation: additionalInformation,
    status: status,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/potential_user/get_potential_users`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const deletePotentialUser = async (potentialUserId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/potential_user/delete_potential_user`,
    potentialUserId,
    config
  );

  return response.data;
};

const registerPotentialUser = async (potentialUserData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/potential_user/register_potential_user`,
    potentialUserData,
    config
  );

  return response.data;
};

const updatePotentialUser = async (updatedPotentialUserData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/potential_user/change_potential_user`,
    updatedPotentialUserData,
    config
  );

  return response.data;
};

const potentialUsersService = {
  getPotentialUsers,
  deletePotentialUser,
  registerPotentialUser,
  updatePotentialUser,
};

export default potentialUsersService;
